import { removeStorageData } from "framework/src/Utilities";

export const logoutSellerNavigation = async () => {
  const keys = [
    "auth-token",
    "selectedItem",
    "store-id",
    "user_data",
    "user_id",
    "userRole",
    "bussinessInformationId",
    "productOverviewId",
    "navigationGender",
    "user-email",
    "sms-token",
    "trackID",
    "selectOrderId",
    "bid-request-id",
    "tapId",
    "addToCart",
    "orderNumber",
    "discountId",
    "selectedIndices",
  ];

  keys.forEach(async (value) => {
    await removeStorageData(value);
  });
};
