import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import ReactCountryFlag from "react-country-flag";
import { addressback, view1, googlepay, applepay, locationn, button_arrow } from "./assets";
import { createTheme, styled } from "@material-ui/core/styles";
import Footer from "../../../components/src/Footer";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StylistAccountActiveProfileController, {
  Props,
  CountrySuccess
} from "./StylistAccountActiveProfileController.web";
import CustomizedSteppers from "../../../components/src/Stylish/StylistStepper";
import SellerHeaderActive from "../../../components/src/Seller/SellerHeaderActive";
import i18n from "../../../components/src/i18next/i18n"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End


export default class StylistContactPayment extends StylistAccountActiveProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleInputChange = this.handleInputChange.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  errors = () => {
    return this.state.emailError ? "inputFeild2Error2" : "inputFeild2"
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { errors } = this.state;
    return (
      <>
        <SellerHeaderActive navigation={this.props.navigation} />
        <MainGrid dir={i18n.dir()} data-testid="mainGridContainer" container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
            <Box className="sideImageBox">
              <img src={addressback} alt="" className="sideImage" />
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
            <Box className={"formBox"} data-testid="paymentDate">
              <form data-testid="formSubmitted" style={{ width: "90%" }}>
                <Box width={"100%"}>
                  <Box style={{ display: "flex", alignItems: "center", gap: "25px", marginBottom: "30px" }}>
                    <img src={i18n.dir()=== 'rtl' ? button_arrow : view1} alt="" className="view" data-testid="getNavigationProfile" onClick={() => this.getNavigationProfile()} />
                    <Typography className={"welcomeText"}>{this.transStylist('Create Profile')} </Typography>
                  </Box>
                  <Box dir={i18n.dir()} style={{ color: "#375280", fontFamily: "Lato" }}>
                    <CustomizedSteppers
                      activeStep={1}
                      steps={[`${this.transStylist('Profile Setup')}`, `${this.transStylist('Contact & Payment')}`, `${this.transStylist('Portfolio')}`]}
                      stepCount={3}
                      dir={i18n.dir()}
                    />
                  </Box>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Address')}*</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="Address"
                      type="text"
                      inputProps={{ 'data-testid': 'Address', maxLength: 420 } as React.InputHTMLAttributes<HTMLInputElement> & {
                        'aria-label'?: string;
                        'data-testid'?: string;
                      }}
                      value={this.state.formData.Address}
                      onChange={this.handleInputChange}
                      className={this.errors()}
                      data-testid="emailTextInput"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {<img src={locationn} alt="location" />}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.Address && <span style={webStyle.allError}>{errors.Address}</span>}
                  </Box>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Area')}</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="Area"
                      value={this.state.formData.Area}
                      inputProps={{ maxLength: 120, }}
                      onChange={this.handleInputChange}
                      className={this.errors()}
                      data-testid="emailTextInput"
                    />
                  </Box>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Block')}</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="Block"
                      value={this.state.formData.Block}
                      inputProps={{ maxLength: 6, }}
                      onChange={this.handleInputChange}
                      className={this.errors()}
                      data-testid="emailTextInput"
                    />
                  </Box>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Mall Name (Optional)')}</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="mallName"
                      value={this.state.formData.mallName}
                      onChange={this.handleInputChange}
                      inputProps={{ maxLength: 120, }}
                      className={this.errors()}
                      data-testid="emailTextInput"
                    />
                  </Box>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Floor  (Optional)')}</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="Floor"
                      value={this.state.formData.Floor}
                      inputProps={{ maxLength: 6, }}
                      onChange={this.handleInputChange}
                      className={this.errors()}
                      data-testid="emailTextInput"
                    />
                  </Box>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Unit Number  (Optional)')}</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="unitNumber"
                      value={this.state.formData.unitNumber}
                      inputProps={{ maxLength: 6, }}
                      onChange={this.handleInputChange}
                      className={this.errors()}
                      data-testid="emailTextInput"
                    />
                  </Box>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('City')}</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="City"
                      value={this.state.formData.City}
                      onChange={this.handleInputChange}
                      inputProps={{ maxLength: 120, }}
                      className={this.errors()}
                      data-testid="emailTextInput"
                    />
                  </Box>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Zip Code')}</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="zipCode"
                      type="text"
                      value={this.state.formData.zipCode}
                      inputProps={{ maxLength: 6, }}
                      onChange={this.handleInputChange}
                      className={this.errors()}
                      data-testid="emailTextInput"
                    />
                  </Box>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Instructions for driver to reach store')}</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="Instructions"
                      value={this.state.formData.Instructions}
                      inputProps={{ maxLength: 240, }}
                      onChange={this.handleInputChange}
                      className={this.errors()}
                      data-testid="emailTextInput"
                    />
                  </Box>
                  <MainGridContainer container>
                    <Grid item style={{ width: "100%" }}>
                      <Box>
                        <Typography data-testid="countryId" className={"inputHeading"} id="phoneNumber">
                          {this.transStylist('Phone Number')}
                          <span style={{ color: '#375280' }}>*</span>
                        </Typography>
                        <Box sx={{position:'relative'}} className={"phoneInput"} style={{ width: "100%" }}>
                          <CustomDropDownBox>
                            <DropDownHeaderMenu data-testid="toggleDropdown1" onClick={this.toggleDropdown1} style={{ borderRadius: "2px" }}
                            >
                              {this.state.selectedCountry ? (
                                <SelectedCountryList>
                                  <Typography data-testid="testdata">
                                    <ReactCountryFlag data-testid="flagData"
                                      countryCode={this.state.selectedCountry.country_code} svg />
                                    {this.state.selectedCountry.numeric_code}
                                  </Typography>
                                </SelectedCountryList>
                              ) : (
                                <Typography
                                  data-testid="Flagdata"
                                  className={"countryFlag"}
                                  style={{ width: "max-content", display: "flex", alignItems: "center", gap: 5 }}
                                >
                                  <ReactCountryFlag countryCode="KW" svg />
                                  {this.state.isCountryData}
                                </Typography>
                              )}
                              <span>
                                {this.state.dropdownOpen1 ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                              </span>
                            </DropDownHeaderMenu>
                          {this.state.dropdownOpen1 && (
                            
                             <DropDownOptionsList dir={i18n.dir()} data-testid="Dropdata">
                              {this.state.countrySuccess.map(
                                (country: CountrySuccess) => (
                                  <OptionsValueList
                                    data-testid="countrySelectId"
                                    key={country.country_code}
                                    onClick={() => this.handleCountrySelect1(country, country.numeric_code)}
                                  >
                                    <Typography data-testid="listData" className={"listData"}>
                                      <ReactCountryFlag countryCode={country.country_code} svg />
                                      {country.country_full_name} (
                                      {country.numeric_code})
                                    </Typography>
                                  </OptionsValueList>
                                )
                              )}
                             </DropDownOptionsList>
                          )}
                          </CustomDropDownBox>
                          <TextField
                            data-testid="phoneNumberTextFiled"
                            variant="outlined"
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="Enter Contact Number"
                            inputProps={{ 'data-testid': 'company_contact_number', maxLength: 10 } as React.InputHTMLAttributes<HTMLInputElement> & {
                              'aria-label'?: string;
                              'data-testid'?: string;
                            }}
                            className={this.errors()}
                            name="company_contact_number"
                            value={this.state.formData.company_contact_number}
                            onChange={this.handleInputChange}
                            InputProps={{ style: { gap: "4px", border: "0px", borderRadius: "2px", width: "100%" } }}
                          />
                        </Box>
                        {errors.company_contact_number && <span style={webStyle.allError}>{errors.company_contact_number}</span>}
                      </Box>
                    </Grid>
                  </MainGridContainer>
                  <Typography style={webStyle.bankName}>{this.transStylist('Bank Account')}</Typography>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Account Name')}*</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="accountName"
                      value={this.state.formData.accountName}
                      onChange={this.handleInputChange}
                      className={this.errors()}
                      data-testid="emailTextInput"
                      type="text"
                      inputProps={{ 'data-testid': 'accountName', maxLength: 120, } as React.InputHTMLAttributes<HTMLInputElement> & {
                        'aria-label'?: string;
                        'data-testid'?: string;
                      }}
                    />
                  </Box>
                  {errors.accountName && <span style={webStyle.allError}>{errors.accountName}</span>}
                  <Box>
                    <Typography id="IBANNumber" className={"inputHeading"}>{this.transStylist('IBAN Number')}*</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="IBANNumber"
                      value={this.state.formData.IBANNumber}
                      onChange={this.handleInputChange}
                      className={this.errors()}
                      data-testid="emailTextInput"
                      type="text"
                      inputProps={{ 'data-testid': 'IBANNumber', maxLength: 36, } as React.InputHTMLAttributes<HTMLInputElement> & {
                        'aria-label'?: string;
                        'data-testid'?: string;
                      }}
                    />
                  </Box>
                  {errors.IBANNumber && <span style={webStyle.allError}>{errors.IBANNumber}</span>}

                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Account Number')}*</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="accountNumber"
                      value={this.state.formData.accountNumber}
                      onChange={this.handleInputChange}
                      className={this.errors()}
                      data-testid="emailTextInput"
                      type="text"
                      inputProps={{ 'data-testid': 'accountNumber', maxLength: 16, } as React.InputHTMLAttributes<HTMLInputElement> & {
                        'aria-label'?: string;
                        'data-testid'?: string;
                      }}
                    />
                  </Box>
                  {errors.accountNumber && <span style={webStyle.allError}>{errors.accountNumber}</span>}
                  <Box >
                    <Typography className={"inputHeading"}>{this.transStylist('Google Pay')}</Typography>
                    <Box style={webStyle.googlepayText}>
                      <Box style={{ display: 'flex', gap:'5px' }}>
                        <img src={googlepay} width="24px" />
                        <Typography style={webStyle.applyPay}>{this.transStylist('Google pay address')}</Typography>
                      </Box>
                      <Box style={webStyle.verifayText}>{this.transStylist('Verify')}</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography className={"inputHeading"}>{this.transStylist('Apple Pay')}</Typography>
                    <Box style={webStyle.applypayText}>
                      <Box style={{ display: 'flex', gap:'5px' }}>
                        <img src={applepay} width="24px" />
                        <Typography style={webStyle.applyPay}>{this.transStylist('Apple Pay ID')}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={webStyle.twoButton}>
                    <Button
                      variant="contained"
                      className={"backButton"}
                      disableElevation
                      type="submit"
                      data-testid="getNavigationProfile1"
                      onClick={() => this.getNavigationProfile()}
                    >{this.transStylist('Back')}
                    </Button>
                    <Button
                      variant="contained"
                      className={"nextButton"}
                      data-testid="getNavigationDocument"
                      onClick={(event) => this.secondPagehandleSubmit(event)}
                    >
                      {this.transStylist('Next')}
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
            <Snackbar
              open={this.state.isAlert}
              autoHideDuration={3000}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClose={this.oncloseAlert}
              data-testid="alertTestingId"
            >
              <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
            </Snackbar>
          </Grid>
        </MainGrid>
        <Footer navigation={this.props.navigation} />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
  twoButton: { display: "flex", alignItems: "center", gap: "16px", marginTop: '24px' },
  verifayText: { color: '#94A3B8', fontSize: '18px', fontWeight: 500, fontFamily: 'Lato' },
  applypayText: { backgroundColor: '#FFFFFF', boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 8px 2px", padding: '24px 48px' },
  applyPay: { color: '#375280', fontSize: '16px', fontWeight: 500, fontFamily: 'Lato', marginLeft: '12px' },
  googlepayText: { backgroundColor: '#FFFFFF', boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 8px 2px", padding: '24px 48px', display: 'flex', justifyContent: 'space-between' },
  bankName: { color: '#375280', fontSize: '24px', fontWeight: 800, fontFamily: 'Lato', marginTop: '40px' },
  allError: { color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif" }
}
const OptionsValueList = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "10px",
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});
const DropDownOptionsList = styled(Box)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  backgroundColor: "#fff",
  color: "#375280",
  overflowX: "hidden",
  borderTop: "none",
  borderRadius: "0 0 4px 4px",
  height: "60vh",
  zIndex: 20,
  position: "absolute" as "absolute",
  border: "1px solid #ccc",
  left: dir === 'ltr' ? "59%" : "0%",
  width: 240

}));
const CustomDropDownBox = styled(Box)({
  width: "150px",
  cursor: "pointer",
  position: "relative",
});
const MainGrid = styled(Grid)({
  justifyContent: "center",
  display: "flex",
  "& .sideImageBox": {
    height: "100%",
    width: "100%",
  },

  "& .imageGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .sideImage": {
    objectFit: 'cover',
    height: "100%",
    width: "100%",
  },
  "& .welcomeSubText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    alignSelf: "flex-start",
    lineHeight: "26px",
    fontWeight: 200,
    margin: "10px 0px 0px 0px",
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: "Avenir, sans-serif",
  },
  "& .inputFeild": {
    borderRadius: "0px",
    border: "none",
    gap: "4px",
  },
  "& .phoneInput": { display: "flex", width: "520px" },
  "& .formGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "7%",
  },
  "& .formBox": {
    marginTop: "30px",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
  },
  "& .welcomeText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    alignSelf: "flex-start",
    lineHeight: "26px",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "24px",
    fontFamily: 'Lato , sans-serif',

  },

  "& .inputHeading": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "18px",
    fontFamily: "Lato, sans-serif",
    lineHeight: "26px",
    textOverflow: "ellipsis",
    margin: "20px 10px 10px 0px",
  },
  "& .forgotPassword": {
    fontWeight: 200,
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: "Lato, sans-serif",
    lineHeight: "26px",
    cursor: "pointer",
    textOverflow: "ellipsis",
    margin: "10px",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
  },
  "& .backButton": {
    gap: "8px",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    borderRadius: "0px !important",
    border: "1px solid #CCBEB1",
    backgroundColor: "white",
    width: "50% !important",
    textTransform: "capitalize",
    height: "56px !important",
    "& .MuiButton-label": {
      fontSize: "16px",
      fontWeight: 600,
    }
  },
  "& .nextButton": {
    width: "50% !important",
    textTransform: "capitalize",
    color: "white !important",
    borderRadius: "0px !important",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    height: "56px !important",
    gap: "8px",
    "& .MuiButton-label": {
      fontWeight: 600,
      fontSize: "16px"
    }
  },
  "& .terms": {
    width: "520px !important",
    margin: "10px 10px 10px 10px !important",
    fontWeight: 400,
    color: "#979797 !important",
    lineHeight: "24px !important",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontFamily: "Lato ,sans-serif",
  },
  "& .condition": {
    color: "var(--Neutrals-Cool-gray-500, #375280)",
    cursor: "pointer",
  },
  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      border: "none"
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },

  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },

  },
  "& .Register": {
    justifyContent: "center",
    padding: "10px",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  "& .socialLogin": {
    justifyContent: "center",
    alignItems: "center",
    gap: "35px",
    display: "flex",
    width: "100%",
    padding: "20px",
  },
  "& .registerButton": {
    cursor: "pointer",
    color:
      "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    margin: "10px 10px 0 10px",
    lineHeight: "24px !important",
    fontWeight: 700,
    fontStyle: "normal !important",
    fontSize: "16px !important",
    fontFamily: "Lato, sans-serif",
  },
  "& .bottomText": {
    lineHeight: "24px !important",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontSize: "16px !important",
    fontFamily: "Lato, sans-serif",
    color: "#375280 !important",
  },
  "& .continueText": {
    fontWeight: 400,
    fontStyle: "normal !important",
    lineHeight: "24px !important",
    fontSize: "16px !important",
    display: "flex",
    marginTop: "15px",
    fontFamily: "Lato, sans-serif",
    color: "#375280 !important",
    flexDirection: "row",
    width: "100%",
    "&:before, &:after": {
      content: "''",
      borderBottom: "1px solid",
      flex: "1 1",
      margin: "auto",
    },
    "&:before": {
      marginRight: "25px"
    },
    "&:after": {
      marginLeft: "25px"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .formBox": { width: "400px", flexWrap: "wrap" },
    "& .Register": {
      width: "350px !important",
    },
    "& .socialLogin": {
      width: "350px !important",
    },
    "& .terms": {
      width: "350px !important",
    },
    "& .backButton": {
      width: "100% !important",
      "& .MuiButton-label": {
        fontWeight: 600,
        fontSize: "16px"
      }
    },
    "& .phoneInput": { width: "370px" },
    "& .continueText": {
      lineHeight: "24px !important",
      fontWeight: 400,
      fontStyle: "normal !important",
      fontSize: "16px !important",
      display: "flex",
      fontFamily: "Lato, sans-serif",
      color: "#375280 !important",
      width: "100%",
      marginTop: "15px",
      flexDirection: "row",
      "&:before, &:after": {
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto",
      },
      "&:before": {
        marginRight: "25px"
      },
      "&:after": {
        marginLeft: "25px"
      }
    },
  },
});
const MainGridContainer = styled(Grid)({
  justifyContent: "center",
  display: "flex",
  "& .mainCotainer": {
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "26px",
    fontFamily: "Avenir,sans-serif",
    alignSelf: "flex-start",
    textOverflow: "ellipsis",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    marginBottom: "40px",
  },
  "& .inputHeading": {
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "18px",
    marginTop: "20px",
    fontStyle: "normal",
    marginBottom: "10px",
    color: "#375280",
    fontFamily: "Lato, sans-serif",
  },
  "& .inputFeild": {
    margin: "0px 10px 0px 10px !important"
  },
  "& .phoneInput": {
    width: "100%px",
    height: "56px",
    gap: "15px",
    display: "flex",
  },
  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    fontWeight: 200,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    fontFamily: "Lato, sans-serif",
    cursor: "pointer",
    margin: "10px",
    textOverflow: "ellipsis",
  },
  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      border: "none",
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important"
      }
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "1px solid #F0F0F0 !important"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .phoneInput": { width: "100%", height: "56px" },
    "& .inputFeild2": {
      "& .MuiTextField-root": {
        "&:hover": {
          border: "1px solid #F0F0F0 !important"
        }
      },
      "& .MuiOutlinedInput-root:hover": {
        border: "1px solid #F0F0F0 !important"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px",
      },
      "& .MuiInputBase-input": {
        color: "#375280"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiFormHelperText-contained": {
        margin: "0 !important"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      width: "300px"

    }

  }

});
const SelectedCountryList = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: '65px'
});
const DropDownHeaderMenu = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: "none",
  borderRadius: "2px",
  gap: "15px",
  background: "#F0F0F0",
  fontSize: "18px",
  color: "#375280",
  height: "36px",
  padding: "10px",
  "&.isActive": {
    border: "1px solid red",
  },
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});
// Customizable Area End
