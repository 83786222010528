import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { formatLiveChatTime, setupOneToOneWebSocket } from "../../../components/src/Chat/chat.web";
import { apiCall } from "../../../components/src/APICall";
import { getStorageData } from "../../../framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
import * as Yup from "yup";
import { FormError, FormErrorTouched } from "../../customform/src/CreateSellerStoreController";
const { baseURL } = require("../../../framework/src/config");

export type OrderRequest = {
  order_request_id: number;
  product_name: string;
  gender: string;
  size: string;
  color: string;
  product_quantity: number;
  price_per_unit: string;
  shipping_cost: string;
  product_display_image_url: string;
};

export type paymentRequest = {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      account_id: number;
      stylist_id: number;
      amount: string;
      reason: string;
      status: "pending" | "approved" | "rejected";
      created_at: string;
      updated_at: string;
      chat_message_id: number;
      chat_message: string;
    };
  }
}

export type Message1 = string | OrderRequest | paymentRequest;

export type SenderInfo = { data: { attributes: { [key: string]: string | null}}}

type ChatAttribute = {
  id: number;
  account_id: number;
  chat_id: number;
  created_at: string;
  updated_at: string;
  is_mark_read: boolean;
  message: Message1;
  role: string;
  message_type?: string
}

type ChatMessage = {
  id: string;
  type: string;
  attributes: ChatAttribute
};

type AccountsChat = {
  id: string;
  type: string;
  attributes: {
    account_id: number;
    muted: boolean;
    role: string;
    account_role: string;
    account_name: string;
    account_phone: string;
    profile_pic?: string;
  };
};

type Wishlist = {
  id: number;
  account_id: number;
  name: string;
  description: string | null;
  share_token: string | null;
  shareable_id: number;
};

type ChatData = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    accounts_chats: AccountsChat[];
    messages: ChatMessage[];
    wishlist: Wishlist;
  };
  relationships: {
    accounts: {
      data: Account[];
    };
  };
};

export type Meta = {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}

type Account = {
  id: string;
  type: string;
};

type ChatResponse = {
  data: ChatData;
  current_user_role: string;
  meta: Meta;
  errors?: {[errKey:string]: string}[];
  message?: string
};

type ReportedRes = {
  message: string,
  chat_report: { id: string}
}

interface ReportValues{
  chat_report_reason_id: string | number;
  comment: string
}

interface ReportReason {
  id: number,
  reason: string,
  created_at: string,
  updated_at: string
}

type AllResponse = ChatResponse & {data: ChatData} & SenderInfo & { message: string} & {reasons: ReportReason[]} & ReportedRes

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  receiver_id?: string | number;
  chat_id?: string | number;
  isStylistPlan?: boolean;
  submitStylistplan?: (chatId: string | number, receiver_id: string | number) => void
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchorElClientsChat: HTMLElement | null;
  chatId: string | number;
  chatMessages: ChatAttribute[];
  receiverProfile: string;
  hasMoreChats: boolean;
  chatMeta: Meta;
  message: string;
  media: File | null;
  isChatAlert: boolean;
  errorMessage: string;
  fileLoader: boolean;
  senderFullName: string;
  senderProfile: string;
  receiverFullName: string;
  blockOpen: boolean;
  severity: "error" | "warning" | "info" | "success" | undefined;
  blockMessage: string;
  role: string | undefined,
  isReportPerson: boolean,
  isReportField: boolean,
  chat_report_reason_id: string | number,
  comment: string,
  report_reasons: ReportReason[]
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OneToOneChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getChatListApiCallId: string = ""
  sendMessageApiCallId: string = ""
  createChatRoomApiCallId: string = ""
  getSenderInfoAPICallId: string = ""
  blockChatApiCallId: string = ""
  getReportReasonsApiCallId: string = ""
  reportReasonApiCallId: string = ""
  webSocket: WebSocket | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      anchorElClientsChat: null,
      chatId: 0,
      chatMessages: [],
      receiverProfile: "",
      hasMoreChats: false,
      chatMeta: {
        current_page: 1,
        next_page: 2,
        prev_page: null,
        total_pages: 0,
        total_count: 0,
      }, 
      message: "",
      media: null,
      isChatAlert: false,
      errorMessage: "",
      fileLoader: false,
      senderFullName: "",
      senderProfile: "",
      receiverFullName: "",
      blockOpen: false,
      blockMessage: "",
      severity: undefined,
      role: "",
      isReportPerson: false,
      isReportField: false,
      chat_report_reason_id: "-1",
      comment: "",
      report_reasons: []
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (!responseJson || responseJson.errors) {
      this.handleChatError(responseJson);
      return;
    }
    this.successAPICall(apiRequestCallId, responseJson)
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const { receiver_id, chat_id } = this.props;
    const role = await getStorageData("userRole", true)
    this.setState({ role: role.userType})
    this.getSenderInfo();
    this.getReportReasons();
    if (chat_id || receiver_id) {
      this.initializeChat(receiver_id, chat_id);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const { receiver_id, chat_id } = this.props;
  
    if ((receiver_id !== prevProps.receiver_id) || chat_id !== prevProps.chat_id) {
      this.initializeChat(receiver_id, chat_id);
    }
  }
  
  initializeChat = async (receiver_id?: string | number, chat_id?: string | number) => {
    this.resetChatState();
    
    if (receiver_id && !chat_id) {
      await this.createChatRoom();
    } else if (chat_id) {
      this.setState({ chatId: chat_id }, () => {
        this.webSocketConnection();
        this.getChatList();
      });
    }
  }
  
  resetChatState = () => {
    this.setState({
      chatMeta: {
        current_page: 1,
        next_page: 2,
        prev_page: null,
        total_pages: 0,
        total_count: 0,
      },
      blockOpen: false,
      blockMessage: "",
      message: "",
      media: null,
      chatMessages: [],
      errorMessage: "",
      isChatAlert: false,
      severity: undefined,
      fileLoader: false
    });
  }

  successAPICall = (apiRequestCallId: string, response: AllResponse) => {
    const apiResponseHandlers = {
      [this.getChatListApiCallId]: this.getChatListResponse.bind(this),
      [this.sendMessageApiCallId]: this.getSendMessageResponse.bind(this),
      [this.getSenderInfoAPICallId]: this.getSenderInfoResponse.bind(this),
      [this.createChatRoomApiCallId]: this.getCreateChatRoomResponse.bind(this),
      [this.blockChatApiCallId]: this.getBlockChatResponse.bind(this),
      [this.getReportReasonsApiCallId]: this.getReportReasonsResponse.bind(this),
      [this.reportReasonApiCallId]: this.getReportedReasonResponse.bind(this)
    };
    const handler = apiResponseHandlers[apiRequestCallId];
    if (handler) {
      handler(response);
    } 
  }

  handleChatError = (errResp: ChatResponse) => {
    if(errResp.errors) {
      const errorMessages = errResp.errors.map((error: { [key:string]: string}) => Object.values(error)[0]).join(", ");
      this.setState({
        isChatAlert: true,
        severity: "error",
        errorMessage: errorMessages
      })
    } else {
      this.setState({
        severity: "error",
        isChatAlert: true,
        errorMessage: "Something went wrong"
      })
    }
    this.setState({ fileLoader: false})
  }

  handleCloseChatSnack = () =>{
    this.setState({
      errorMessage: "",
      isChatAlert: false,
      severity: undefined,
    })
  }

  navigateToNewPaymentReq = (component: string) => {
    if(component==="NewOrderRequestId" || component==="NewPaymentRequestId"){
      this.props.navigation.navigate(component, {id: this.state.chatId} );
    }
  }

  handleShareWishlist = () => {

  }

  handleBlockOpen = (value: boolean) => {
    this.setState({
      blockOpen: value
    })
  }

  handleClickChatMore = (event: React.MouseEvent<HTMLImageElement>) => {
    this.setState({ anchorElClientsChat: event.currentTarget });
  };

  handleCloseChatMore = () => {
    this.setState({ anchorElClientsChat: null });
  };

  webSocketConnection = () => {
    const URL = baseURL.replace(/^https?:\/\//i, "");
    const token = localStorage.getItem("auth-token");
    this.webSocket = setupOneToOneWebSocket(
      URL,
      token,
      this.state.chatId,
      () => {}
    );
  };

  removeMediaPreview = () => {
    this.setState({
      media: null,
    })
  }

  createChatRoom = async () => {
    const sender1Token = await getStorageData("auth-token");
    const {receiver_id} = this.props;

    const createBody = {
      name: `order chat ${receiver_id}`,
      user_id: receiver_id
    }

    this.createChatRoomApiCallId = await apiCall({
      token: sender1Token,
      contentType: configJSON.apiContentType,
      endPoint: configJSON.chatEndPoint,
      method: configJSON.postApiMethod,
      body: createBody,
      isJsonStringify: true,
    });
  }

  getCreateChatRoomResponse = (response: {data: ChatData} & { message: string}) => {
    if(response.data.attributes && response.data.attributes.id) {
      this.setState({
        chatId: response.data.attributes.id
      }, () => {
        this.webSocketConnection();
        this.getChatList();
      })
    }
    if(response.message){
      this.setState({
        blockMessage: response.message
      })
    }
  }

  getSenderInfo = async () => {
    const sender_id = localStorage.getItem('user_id');
    const senderToken = await getStorageData("auth-token");
  
    this.getSenderInfoAPICallId = await apiCall({
      token: senderToken,
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.getPersonalUserApiEndPoint}?user_id=${sender_id}`,
    });
  }

  getSenderInfoResponse = (resp: SenderInfo) => {
    this.setState({
      senderFullName: resp.data.attributes.full_name || "",
      senderProfile: resp.data.attributes.profile_picture || "",
    });
  }

  getMoreApiChatList = () => {
    const { chatMeta } = this.state
    if (chatMeta.current_page <= chatMeta.total_pages) {
      this.getChatList();
    }
  }

  getChatList = async () => {
    const senderToken = await getStorageData('auth-token')
    const {chatMeta} = this.state;
    if (!chatMeta.current_page || chatMeta.current_page === null) {
      this.setState({ hasMoreChats: false })
      return
    }

    const chatListEndpoint = `${configJSON.chatEndPoint}/${this.state.chatId}?page=${chatMeta.current_page}&per_page=10`

    this.getChatListApiCallId = await apiCall({
      token: senderToken,
      endPoint: chatListEndpoint,
      method: configJSON.getApiMethod,
      contentType: configJSON.apiContentType
    });
  };

  getChatListResponse = (resp: ChatResponse) => {
    if(resp.data && resp.data.attributes){
      const messages  = resp.data.attributes.messages
      const accounts = resp.data.attributes.accounts_chats
      if(messages && Array.isArray(messages)){
        const newMessages = messages.map(attr=> {
          return  {...attr.attributes, created_at: formatLiveChatTime(attr.attributes.created_at), updated_at: formatLiveChatTime(attr.attributes.updated_at)}
         })
        this.setState((prevState)=>({
          chatMessages: [...prevState.chatMessages,...newMessages] as ChatAttribute[]
        }))
      }
      if(accounts && Array.isArray(accounts)){
        const receiverDetails = accounts.find((acc: AccountsChat) => acc.attributes.role === "receiver")?.attributes
        this.setState({
          receiverProfile: receiverDetails?.profile_pic || "",
          receiverFullName: receiverDetails?.account_name || ""
        })
      }
    }
    if(resp.meta){
      const { total_pages } = resp.meta
      this.setState((prevState: S) => ({
        chatMeta: { ...resp.meta, current_page: prevState.chatMeta.current_page + 1 },
        hasMoreChats: prevState.chatMeta.current_page < total_pages
      }));
    }
    if(resp.message){
      this.setState({
        blockMessage: resp.message
      })
    }
  }

  submitMessageOrMedia = (message: string) => {
    this.setState({ message }, () => {
      message && this.sendMessageChat();
    });
  };

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ media: file });
    }
  };

  sendMessageChat = async () => {
    const chatToken = await getStorageData('auth-token')
    const formData = new FormData();
    const { media, message } = this.state
    if(media || message.trim()){
      formData.append("message[message]", this.state.message || "N/A");
      if (this.state.media) {
        formData.append("message[attachments]", this.state.media);
        this.setState({ fileLoader: true})
      }
      this.sendMessageApiCallId = await apiCall({
        token: chatToken,
        endPoint: `${configJSON.chatEndPoint}/${this.state.chatId}/messages`,
        method: configJSON.postApiMethod,
        body: formData,
      });
    }
  };

  getSendMessageResponse = (response: {data: ChatData}) => {
    if(response.data && response.data.attributes){
      this.resetChatState();
      this.setState((prevState)=>({
        message: "",
        media: null,
        fileLoader: false
      }), () => this.getChatList())      
    }
  }

  blockChat = async () => {
    const blockToken = await getStorageData('auth-token')
    const blockEndpoint = `${configJSON.chatEndPoint}/${this.state.chatId}/block_chat`

    this.blockChatApiCallId = await apiCall({
      token: blockToken,
      endPoint: blockEndpoint,
      method: configJSON.putApiMethod,
      contentType: configJSON.apiContentType
    });
  }

  getBlockChatResponse = (resp: {message: string}) => {
    if(resp.message){
      this.resetChatState();
      this.setState({
        blockOpen: false,
        isChatAlert: true,
        severity: "success",
        anchorElClientsChat: null,
        errorMessage: resp?.message || "Successfully blocked",
      }, () => this.getChatList())
    }
  }
  
  renderTimeTextAlign = (isSender: boolean) => {
    if(isSender){
    return i18n.dir() === "rtl" ? "left" : "right"
    } else {
    return i18n.dir() === "rtl" ? "right" : "left";
    }
  }

  handleReportPersonClick = (value: boolean) => {
    this.setState({
      isReportPerson: value
    })
  }

  handleOpenReportField = (value: boolean) => {
    this.setState({
      isReportField : value,
      isReportPerson: false
    })
   
  }

  transChat = (chatkey: string) => {
    return i18n.t(chatkey, { ns: "chat"})
  }

  getErrorAndHelperTextReport = (
    name: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isErrorReport: boolean = Boolean(errors[name]) && Boolean(touched[name]);
    const helperTextReport: string = isErrorReport ? errors[name] ?? "" : "";
    const resultReport = this.transChat(helperTextReport)
    return { isError: isErrorReport, helperText:resultReport };
  };

  getReportReasons = async () => {
    const reasonToken = await getStorageData('auth-token')
    const reasonEndpoint = `${configJSON.chatEndPoint}/report_reasons`

    this.getReportReasonsApiCallId = await apiCall({
      token: reasonToken,
      endPoint: reasonEndpoint,
      method: configJSON.getApiMethod,
      contentType: configJSON.apiContentType
    });
  }

  getReportReasonsResponse = (response: { reasons: ReportReason[]}) => {
    if(response.reasons && Array.isArray(response.reasons)){
      this.setState({
        report_reasons: response.reasons
      })
    }
  }

  handleSubmitReport = async (values: ReportValues) => {
    const formToken = await getStorageData("auth-token")
    const formData = new FormData();
    formData.append("chat_report_reason_id", values.chat_report_reason_id.toString());
    formData.append("comment", values.comment.trim());

    const reportEndpoint = `${configJSON.chatEndPoint}/${this.state.chatId}/report_chat`
    
    this.reportReasonApiCallId = await apiCall({
      method: configJSON.postApiMethod,
      endPoint: reportEndpoint,
      token: formToken,
      body: formData,
      isJsonStringify: false,
    });
  }

  getReportedReasonResponse = (reportedResp: ReportedRes) => {
    if(reportedResp && reportedResp.chat_report && reportedResp.chat_report.id){
      this.setState({
        isChatAlert: true,
        severity: "success",
        errorMessage: reportedResp.message
      })
    } else if(reportedResp.message){
      this.setState({
        isChatAlert: true,
        severity: "warning",
        errorMessage: reportedResp.message
      })
    }
    this.setState({
      isReportField: false,
      isReportPerson: false,
      anchorElClientsChat: null
    })
  } 

  handleSubmitPropsPayment = () => {
    const { receiver_id, submitStylistplan } = this.props;
    const { chatId } = this.state;
    !!receiver_id && !!chatId && submitStylistplan?.(chatId, receiver_id);
  }
  // Customizable Area End
}
// Customizable Area Start

export const validationReportSchema = Yup.object({
  comment: Yup.string()
  .trim()
  .matches(/^[A-Za-z][A-Za-z0-9\s]*$/, 'Details must start with a letter and only contain letters')
  .required('Details is required. Please enter the necessary information'),
  chat_report_reason_id: Yup.string()
  .notOneOf(['-1'], 'Report reason is required, please select reason')
  .required('Report reason is required, please select reason'),
});

// Customizable Area End
