import { getStorageData, setStorageData } from "framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
const navigation = require("react-navigation");

interface APIPayloadType {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: object;
    token?: string;
    type?: string;
}

interface ErrorResponseArray {
    errors: { [key: string]: string }[];
}

interface PhoneOTPError {
    errors: [
        {
            pin: string;
        }
    ]
}

interface ErrorResponseObject {
    errors: { [key: string]: string };
}

interface EmailVarificationResponse {
    message: string;
        }

interface ResentdOTP {
    data: {
        id: string;
        type: string;
        attributes: {
            activated: boolean;
            country_code: string;
            email: string;
            first_name: string | null;
            full_phone_number: string;
            last_name: string | null;
            full_name: string;
            phone_number: string;
            type: string | null;
        }
    };

    meta: {
        token?: string;
        message?: string;
    };
}

interface MobileUserDetails {
    isType?: string;
    isToken?: string;
    isPhoneNumber: string;
    phoneType: string;
    userEmail:string;
    loginType:string
}

interface OTPBody {
    otp_type: string | undefined;
    token: string | undefined;
    pin: string;
}

interface ResendSMSOTP{
    data: {
        type: string;
        attributes: {
            full_phone_number:string;
        }
    }
}

interface ResendEmailOTP{
    data: {
        type: string;
        attributes: {
            email: string;
        }
    }
}

interface PhoneOTPPayLoad {
    token: string | undefined;
    pin: string;
}

interface AccountType {
    id: number;first_name: string | null;
    last_name: string | null;full_phone_number: string;country_code: number;
    phone_number: number;email: string;activated: boolean;
    device_id: string | null;unique_auth_id: string;password_digest: string;
    created_at: string;updated_at: string;user_name: string | null;
    platform: string | null;user_type: string | null;app_language_id: number | null;
    last_visit_at: string | null;is_blacklisted: boolean;
    suspend_until: string | null;status: string;
    gender: string | null;date_of_birth: string | null;age: number | null;
    stripe_id: string | null;stripe_subscription_id: string | null;
    stripe_subscription_date: string | null;
    role: string;full_name: string;
    is_verified: boolean | null;share_token: string | null;
    approve_status: string;seller_status: string;notification:{};
    customer_id: string | null;language:number;currency:number;
    stylist_redirect_flag?:string;
}
interface MetaType {
    token: string;
    refresh_token: string;
    id: number;
    account: AccountType;
}

interface PostLoginResponseType {
    meta: MetaType;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    otpInput: string;
    errorMassgage?:{
        errorMsg?:string;
        otpError?:boolean;
    };
    isEmail:string;
    isFullPhoneNumber:string;
    email:boolean;
    emailToken:string;
    emailResendOTP:boolean;
    isMobile:boolean;
    emailDataParam: MobileUserDetails;
    token: string;
    isType: string;
    userType: string;
    isAlert: boolean;
    alertType: 'success' | 'error' | 'warning' | 'info';
    alertMsg: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class EmailOTPVarificationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    emailVerificationApiCall: string = "";
    phoneVerificationLoginSignupAPICall: string = "";
    resetOTPAPICallId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            otpInput: "",
            errorMassgage:{
                errorMsg:"",
                otpError:true
            },
            isEmail:"",
            isFullPhoneNumber:"",
            email:true,
            emailToken:"",
            emailResendOTP:false,
            isMobile:false,
            emailDataParam: {
                isToken: "",
                isType: "",
                isPhoneNumber: "",
                phoneType: "",
                userEmail:"",
                loginType:"",
            },
            token: "",
            isType: "",
            userType: '',
            isAlert: false,
            alertType: 'success',
            alertMsg: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount(){
        // Customizable Area Start
        const userRole = await getStorageData("userRole", true);
        if(userRole){
            this.setState({
                userType: userRole?.userType
            });
        }

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog(configJSON.Message_RecivedMsg, message);
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const navigationParams = message.getData(
                getName(MessageEnum.NavigationPayLoadMessage)
            );
            this.setState({ emailDataParam: navigationParams })
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.apiSucessCall(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.apiFailureCall(apiRequestCallId, responseJson)
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    handleOTPChange = (otps: string) => {
        this.setState({ 
            otpInput: otps,
            errorMassgage:{
                errorMsg:"",
                otpError:false
            }
         });
    }

    apiCallMobileService = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
        };
        const requestmobileOTPMessageSerice = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestmobileOTPMessageSerice.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestmobileOTPMessageSerice.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestmobileOTPMessageSerice.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestmobileOTPMessageSerice.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestmobileOTPMessageSerice.id, requestmobileOTPMessageSerice);
        return requestmobileOTPMessageSerice.messageId;
    };

    apiSucessCall = async (apiRequestCallId: string, responseJson: EmailVarificationResponse & ResentdOTP & PostLoginResponseType) => {
        if (apiRequestCallId === this.emailVerificationApiCall) {
            if(responseJson?.meta?.message){
                this.setState({
                    otpInput: "",
                    isAlert: true,
                    alertMsg: responseJson?.meta?.message,
                    alertType:"error"
                });
            }else{
                this.setState({ email: false,
                    otpInput: "",
                    isAlert: true,
                    alertMsg: responseJson?.message,
                    alertType: "success",
                emailDataParam:{
                    ...this.state.emailDataParam,
                    isType: configJSON.smsType,
                }
             });            
            }
        }
        if (apiRequestCallId === this.resetOTPAPICallId) {
            this.setState({isAlert: true,alertMsg: configJSON.resentOTP,alertType:"success"})
            this.setState({ email: false,otpInput:"" })
        }
        if (apiRequestCallId === this.phoneVerificationLoginSignupAPICall) {          
            this.setState({ otpInput: "" }, () => {
                switch (this.state.userType) {
                    case configJSON.buyerMsg:
                        this.openBuyerUser(responseJson)
                        break;
                    case configJSON.sellerMsg:
                        this.openBuyerSeller(responseJson)                       
                        break;
                    case configJSON.StylishMsg:
                        this.openStylist(responseJson)
                        break;
                   }
            })
        }

        
    };
    handlStylistNavigation = (stylist_status?: string) => {
        if (stylist_status == 'stylist_document_submission_page') {
            this.getNavigationStylist('StylistUploadDocumentsweb')
        } else if (stylist_status == 'stylist_landing_page') {
            setStorageData("stylishType", "landing")
            this.getNavigationStylist('StylishDashboard')
        } else if(stylist_status == "stylist_confirmation_page"){
            setStorageData("stylishType", "confirmation")
            this.getNavigationStylist("StylishDashboard")
        } else{
            this.getNavigationStylist("StylistAccountActiveProfile")
        }
    };
    getNavigationStylist = (navigateTo: string) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(getName(MessageEnum.NavigationTargetMessage),`${navigateTo}`);
            const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
            raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    }

    handleSellerNavigation = (seller_status?: string) => {
        if (seller_status == 'Signup') {
            this.getNavigationActivateScreen()
        } else if (seller_status == 'Account_activation') {
            this.getNavigationCreateStoreScreen()
        } else {
            this.getNavigationSellerDashboardScreen()
        }
    }

    getNavigationActivateScreen = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'AccountActiveProfile'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    }

    getNavigationCreateStoreScreen = () => {

        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'CreateSellerStore'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);

    }

    getNavigationSellerDashboardScreen = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'SellerStore'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);

    }

    apiFailureCall = async (apiRequestCallId: string, responseJson: ErrorResponseArray & {
        errors: {
            pin: string
        }
    } & {
        errors: [{
            token?: string;
            pin?:string
        }]
    }) => {
        if (apiRequestCallId === this.emailVerificationApiCall) {
                if(responseJson.errors?.length > 0){
                    if(responseJson.errors[0].token){
                        this.setState({
                            isAlert: true,
                            alertMsg: responseJson.errors[0].token,
                            alertType: "error"
                        })
                    }
                }else if(responseJson.errors?.pin){
                this.setState({
                    errorMassgage:{
                        errorMsg: responseJson.errors?.pin,
                        otpError: true
                    }
                })
            }
        }
        if (apiRequestCallId === this.resetOTPAPICallId) {
            this.setState({isAlert: true,alertMsg: configJSON.failed,alertType:"error"})
        }
        if(apiRequestCallId === this.phoneVerificationLoginSignupAPICall){
            if(responseJson.errors?.length > 0 && responseJson.errors[0].pin){
                this.setState({
                 errorMassgage:{
                errorMsg: responseJson.errors[0].pin,
                otpError: true
            }
        })
}else if(responseJson.errors?.pin){
this.setState({
    errorMassgage:{
        errorMsg: responseJson.errors?.pin,
        otpError: true
    }
})
}
        }
    };

    maskEmail = () => {
        let mail = this.state.emailDataParam.userEmail;
        let atIndex = mail?.indexOf("@");
        let maskedEmail = mail?.substring(atIndex - 2);
        let starMail = `********${maskedEmail}`;
        return starMail;
    };

    maskPhone = () => {
        let phoneNumber = this.state.emailDataParam.isPhoneNumber;
        let length = phoneNumber.length;
        let maskedPart1 = phoneNumber.substring(length - 2);
        let starPhone = `*******${maskedPart1}`;
        return starPhone;
    };      

    handleSubmit = async (submitEvent: React.FormEvent) => {
        submitEvent.preventDefault();
        const { otpInput, email } = this.state;
        const errorMsg = otpInput === '' || otpInput.length !== 4 ? configJSON.enterOTP : '';
        this.setState({ errorMassgage: { errorMsg, otpError: errorMsg !== '' } });
        const isApiType = this.state.emailDataParam.isType === configJSON.smsType;
        const tokenMobile = await storage.get("sms-token");
        const otpTypeMobile = configJSON.smsType;
        const otpTypeEmail = configJSON.emailType;
        const tokenEmail = await storage.get("email-token");
        if (errorMsg === "") {           
            if (isApiType) {                              
                const apiData = {
                    token: tokenMobile,
                    pin: otpInput,
                    otp_type:otpTypeMobile
                };
                this.handleSubmitPhoneOTPForm(apiData);
            } else {               
                const apiData = {
                    otp_type:otpTypeEmail,
                    token:tokenEmail,
                    pin: otpInput,
                };
                this.setState({ isMobile: !email });
                this.handleSubmitEmailOTPForm(apiData);
            }
        }
    };
    
    handleSubmitPhoneOTPForm = async (body: PhoneOTPPayLoad) => { 
        this.phoneVerificationLoginSignupAPICall = await this.apiCallMobileService({
            contentType: configJSON.postEmailVarificationContentType,
            method: configJSON.postApimethod,
            endPoint: this.state.emailDataParam.loginType === configJSON.mobileMsg ? configJSON.postPhoneLoginVarificationEndPoint : configJSON.postPhoneVarificationEndPoint,
            body: body
        });
    }; 

    handleSubmitEmailOTPForm = async (body:OTPBody) => { 
        this.emailVerificationApiCall = await this.apiCallMobileService({
            contentType: configJSON.postEmailVarificationContentType,
            method: configJSON.postApimethod,
            endPoint: configJSON.postEmailVarificationEndPoint,
            body: body
        });
    }; 
    
    oncloseAlert = () => {
        this.setState({ isAlert: false });
      };

    handleResendSMSOTP = async (body:ResendSMSOTP | ResendEmailOTP) => {
        this.resetOTPAPICallId = await this.apiCallMobileService({
            contentType: configJSON.postResendOTPContentType,
            method: configJSON.postApimethod,
            endPoint: configJSON.postResendOTPEndPoint,
            body: body
        })
    };

    handeResentOTP = async () => {
        if (
            this.state.emailDataParam.isType === configJSON.emailType 
        ) {
            const body = {
                data: {
                    type: configJSON.email_accountMsg,
                    attributes: {
                        email: this.state.isEmail + (await storage.get("user-email")),
                    },
                },
            };
            this.handleResendSMSOTP(body);
        } else {
            const body = {
                data: {
                    type: configJSON.sms_accountMsg,
                    attributes: {
                        full_phone_number: this.state.emailDataParam.isPhoneNumber,
                    },
                },
            };
            this.handleResendSMSOTP(body);
        }
    };
    
    handleOption = () => {
         if (this.state.emailDataParam.isType === configJSON.emailType) {
          return true;
        }
      };        
      
      openBuyerUser = async(responseJson?:PostLoginResponseType) => {
        await setStorageData("auth-token", responseJson?.meta?.token);
        await setStorageData("user_id", responseJson?.meta?.account.id)
        await setStorageData("user_data", JSON.stringify(responseJson?.meta?.account))
        const msgOpen = new Message(getName(MessageEnum.NavigationMessage));
        msgOpen.addData(
          getName(MessageEnum.NavigationTargetMessage),
          this.state.emailDataParam.loginType === configJSON.mobileMsg ? configJSON.LandingPageMsg : configJSON.EmailAccountLoginBlockMsg,
        );
        msgOpen.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msgOpen);
      };  

      openBuyerSeller = async(responseJson?:PostLoginResponseType) => {
        await setStorageData("auth-token", responseJson?.meta?.token);
        await setStorageData("user_id", responseJson?.meta?.account.id)
        await setStorageData("user_data", JSON.stringify(responseJson?.meta?.account))
        const msgOpen = new Message(getName(MessageEnum.NavigationMessage));
        msgOpen.addData(
          getName(MessageEnum.NavigationTargetMessage),
          this.state.emailDataParam.loginType === configJSON.mobileMsg ?  this.handleSellerNavigation(responseJson?.meta?.account?.seller_status) : configJSON.EmailAccountLoginBlockMsg,
        );
        msgOpen.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msgOpen);
      }; 

      openStylist = async(responseJson?:PostLoginResponseType) => {
        await setStorageData("auth-token", responseJson?.meta?.token);
        await setStorageData("user_id", responseJson?.meta?.account.id)
        await setStorageData("user_data", JSON.stringify(responseJson?.meta?.account))
        const msgOpen = new Message(getName(MessageEnum.NavigationMessage));
        msgOpen.addData(
            getName(MessageEnum.NavigationTargetMessage),
            this.state.emailDataParam.loginType === configJSON.mobileMsg ?  this.handlStylistNavigation(responseJson?.meta?.account?.stylist_redirect_flag) : configJSON.EmailAccountLoginBlockMsg,
        );
        msgOpen.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msgOpen);
      }; 

      handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace' || e.key === 'Delete' || (e.key >= '0' && e.key <= '9')) {
            return;
        }
        e.preventDefault();
      }
    // Customizable Area End
}
