import React from "react";
// Customizable Area Start
import { arrow, arrowselect, crossmini, upload, deleteBttn } from "./assets";
import {storeSuccessIcon} from "../../customform/src/assets";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Box, Checkbox, TableBody, TableHead, TableRow, Paper, TableCell, TableContainer, styled, TextField, MenuItem, Select, RadioGroup, FormControlLabel, Radio, FormControl, OutlinedInput, Table, Typography, Button,Dialog } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import  { ChangeEvent } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AddProductCatalougeController, { Props } from "./AddProductCatalougeController";
import ArrowGender, {  CircleDiv, FontListed, FontWight,  LinkStatusFirst } from "../../../components/src/ArrowGender.web";
import storage from 'framework/src/StorageProvider';
import { UploadBtnBox } from "./CatalogueWeb.web";
import i18n from "../../../components/src/i18next/i18n";

// Customizable Area End


export default class EditCatalougeWeb extends AddProductCatalougeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleBackUpdateCSS = () => {
    return i18n.dir() === "ltr" ? "rotate(0deg)" : "rotate(180deg)"
  }
  
  handleCategoryEditChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCategoryEditName = event.target.value as string;
    const selectedCategoryEdit = this.state.category.find(
      (category) => category.attributes.name === selectedCategoryEditName
    );
    if (selectedCategoryEdit && selectedCategoryEdit.id) {
      this.setState({ clothValue: selectedCategoryEditName });
      this.setState({categoryIdd : selectedCategoryEdit.attributes.id})
      this.sub_category(selectedCategoryEdit.attributes.id); 
    }
  };

  handleEditChange = (event:any) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleSubCategoryEditChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCategoryEditName1 = event.target.value as string;
    const selectedCategoryEdit1 = this.state.category1.find(
      (category1) => category1.attributes.name === selectedCategoryEditName1
    );
    if (selectedCategoryEdit1 && selectedCategoryEdit1.id) {
      this.setState({ categoryValue: selectedCategoryEditName1 });
      this.setState({categoryValueId: selectedCategoryEdit1.attributes.id})
      this.subsubCategorey(selectedCategoryEdit1.attributes.id); 
    }
  };
  handleSubSubCategoryEditChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedSubSubCategoryEditName = event.target.value as string;
    const selectedSubSubCategoryEdit = this.state.subsubCategory.find(
      (subsubCategory) => subsubCategory.attributes.name === selectedSubSubCategoryEditName
    );
    if (selectedSubSubCategoryEdit && selectedSubSubCategoryEdit.id) {
      this.setState({ categoryValue1: selectedSubSubCategoryEditName });
      this.setState({subsubcategoryValueId: selectedSubSubCategoryEdit.attributes.id})
    }
  };

  handleSelectAllEditChange = () => {
    this.setState(prevState => {
      const { selectAll, storeList } = prevState;
      const newSelectEditAll = !selectAll;
      const newSelectedStoresEdit = newSelectEditAll ? storeList.map((store:any) => store.id) : [];

      return {
        selectedStores: newSelectedStoresEdit,
        selectAll: newSelectEditAll,
      };
    });
  };
  handleCheckboxEditChange = (id: number) => {
    this.setState(prevState => {
      const { selectedStores } = prevState;
      const isSelectedEdit = selectedStores.includes(id);
      const newSelectedStoresEdit = isSelectedEdit
        ? selectedStores.filter((storeId:any) => storeId !== id)
        : [...selectedStores, id];

      return {
        selectedStores: newSelectedStoresEdit,
        selectAll: newSelectedStoresEdit.length === this.state.storeList.length,
      };
    });
  };
 
  handleSizeEditChange = (event: any) => {
    const selectedSizesEdit = event.target.value;
    const selectedSizeIdsEdit = selectedSizesEdit.map((sizeName: any) => {
      const sizeObj = this.state.varient_size.find((size: { attributes: { name: any; }; }) => size.attributes.name === sizeName);
      return sizeObj ? sizeObj.id : null;
    }).filter((id: any) => id !== null);

    this.setState({ personName: selectedSizesEdit, selectedSizeIdsEdit,
      selectedSizeIds: selectedSizeIdsEdit, });
  };

  removeSelectedSizeEdit = (sizeName: any) => {
    const updatedSizesEdit = this.state.personName.filter(item => item !== sizeName);
    const updatedSizeIdsEdit = updatedSizesEdit.map(sizeName => {
      const sizeObj = this.state.varient_size.find((size: { attributes: { name: string; }; }) => size.attributes.name === sizeName);
      return sizeObj ? sizeObj.id : null;
    }).filter(id => id !== null);

    this.setState({ personName: updatedSizesEdit, selectedSizeIds: updatedSizeIdsEdit,selectedSizeIdsPrev:updatedSizesEdit });
  };
  handleColorChangeEdit = (event:any) => {
    const selectedColorsEdit = event.target.value;
    const selectedColorIdsEdit = selectedColorsEdit.map((colorName: any) => {
      const colorObj = this.state.varient_color.find((colorr:any) => colorr.attributes.name === colorName);
      return colorObj ? colorObj.id : null;
    }).filter((id: any) => id !== null);
    
    storage.set('selectedColor', JSON.stringify(selectedColorsEdit));
    this.setState({ selectedColorsEdit });
    this.setState({ colorArry: selectedColorsEdit, selectedColorIdsEdit,
      selectedColorIds: selectedColorIdsEdit,
       });
    let existingGridImagesEdit = this.state.gridImages;

    const existingGridImagesMapEdit = new Map(existingGridImagesEdit.map((image: any) => [image.color, image]));
  
    const newGridImagesEdit = selectedColorsEdit.map((color: any) => {
      if (existingGridImagesMapEdit.has(color)) {
        return existingGridImagesMapEdit.get(color);
      } else {
        return {
          color: color,
          images: [
            { name: "Front View", imgUrlLink: "", active: false },
            { name: "Back View", imgUrlLink: "", active: false },
            { name: "Side View", imgUrlLink: "", active: false },
          ],
          selectedImage: "",
          activeGridImage: "",
        };
      }
    });
  
    this.setState({ gridImages: newGridImagesEdit });

  };

  removeSelectedColorEdit = (colorName: any) => {
    const updatedColorsEdit = this.state.colorArry.filter(item => item !== colorName);
  
    const updatedColorIdsEdit = updatedColorsEdit.map(colorName => {
      const colorObjEdit = this.state.varient_color.find((colorr: any) => colorr.attributes.name === colorName);
      return colorObjEdit ? colorObjEdit.id : null;
    }).filter(id => id !== null);
  
    const updatedGridImagesEdit = this.state.gridImages.filter((image: any) => image.color !== colorName);
  
    this.setState({ 
      colorArry: updatedColorsEdit, 
      selectedColorIds: updatedColorIdsEdit,
      selectedClorIdsPrev: updatedColorIdsEdit,
      gridImages: updatedGridImagesEdit, 
    });
  };
  

handleCreateVariantEdit = async () => {
  const { create_vari } = this.state;
  let validationErrors: string[] = [];

  
 


  this.setState({ create_vari, validationErrors });
  if ( validationErrors.length === 0) {
    this.createVarient(this.state.selectedSizeIds, this.state.selectedColorIds);
    this.setState({ isVariantCreated: true });
  } else {
    console.error(this.transAddProductCatalogue("Some errors occurred. Please fix them before updating the variant."));
  }
};

handleInputChangeEdit = (index: any, field: any, value: any) => {
  const updatedVariantsEdit = [...this.state.create_vari];

  if (updatedVariantsEdit[index] && updatedVariantsEdit[index][field] !== undefined) {
    updatedVariantsEdit[index][field] = value;
  }

  if (field === 'sku') {
    updatedVariantsEdit[index].skuError = "";

    const isDuplicateSku = updatedVariantsEdit.some(
      (variant, idx) => idx !== index && variant.sku === value
    );

    if (isDuplicateSku) {
      updatedVariantsEdit[index].skuError = this.transAddProductCatalogue("This SKU is already taken");
    }
  }


  if (field === 'price') {
    updatedVariantsEdit[index].priceError = ""; // Clear previous error
    if (Number(value) <= 0) {
      updatedVariantsEdit[index].priceError = this.transAddProductCatalogue("Price must be greater than 0");
    }
  }
  this.setState({ create_vari: updatedVariantsEdit });
};


renderTableRows = () => {
  const { create_vari } = this.state;

  return create_vari.map((variant: any, index: number) => (
    <TableRow key={index} data-test-id="table-row">
      <TableCell style={webStyle.trHead} component="th" scope="row" data-test-id="table-cell-color">
        {`${variant.variant_size || this.transAddProductCatalogue('N/A')}/${variant.variant_color || this.transAddProductCatalogue('N/A')}`}
      </TableCell>
      <TableCell style={webStyle.trHead} align="left" data-test-id="table-cell-qyt">
        <CustomInputTable 
          value={variant.stock_qty || ''} 
          onChange={(e) => this.handleInputChangeEdit(index, 'stock_qty', e.target.value)}
          data-test-id="quantity1"
          variant="standard"
          InputProps={{ disableUnderline: true, style: { textAlign: 'center' } }}
        />
        {variant.quantityError && (
          <p style={{ color: 'red', marginTop: '5px' }}>{variant.quantityError}</p>
        )}
      </TableCell>
      <TableCell style={webStyle.trHead} align="left" data-test-id="table-cell-price">
        <CustomInputTable 
          value={variant.price || ''} 
          onChange={(e) => this.handleInputChangeEdit(index, 'price', e.target.value)}
          id='price1'
          variant="standard"
          InputProps={{ disableUnderline: true, style: { textAlign: 'center' } }}
        />
        {variant.priceError && (
          <p style={{ color: 'red', marginTop: '5px' }}>{variant.priceError}</p>
        )}
      </TableCell>
      <TableCell style={webStyle.trHead} align="left" data-test-id="table-cell-sku">
        <CustomInputTable 
          value={variant.sku || ''} 
          onChange={(e) => this.handleInputChangeEdit(index, 'sku', e.target.value)}
          id={`sku-${index}`} 
          variant="standard"
          
          InputProps={{ disableUnderline: true,
             style: { textAlign: 'center' } }}
        />
        {variant.skuError && (
          <p style={{ color: 'red', marginTop: '5px' }}>{variant.skuError}</p>
        )}
      </TableCell>
    </TableRow>
  ));
};


  // Customizable Area End

  render() {
    // Customizable Area Start
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

 


    const fontEditListed = (data: boolean) => {
      return (FontListed(data))
    }
    const genderEditArrow = (arrowHandle: boolean) => {
      return (ArrowGender(arrowHandle))
    }
    const getSecondEditPagevariant = (title: string, qty: string, price: string, sku: string) => {
      return (<><div style={{ borderRadius: "4px", border: "1px solid #E2E8F0", marginTop: '20px', padding: 15 }}><div>
        <h2 style={webStyle.labelH} data-test-id="variant-size">{this.transAddProductCatalogue("Variant (Size)")}*</h2>
        <FormControl style={webStyle.selectDiv}>
          <SelectCustomMul
            displayEmpty
            disableUnderline={true}
            value={this.state.personName}
            onChange={this.handleSizeEditChange}
            input={<OutlinedInput />}
            data-test-id="genderSel6"
            renderValue={() => {
              return <p style={{ paddingLeft: 15 }}>{this.transAddProductCatalogue("Select Size")}</p>;
            }}
            MenuProps={MenuProps}
            multiple
            inputProps={{ 'aria-label': 'Without label' }}
            onClose={() => this.setState({ sizeMulSelect: false })}
            IconComponent={() => <img src={arrowselect} style={{ transform: genderEditArrow(this.state.sizeMulSelect) }} />}
            onOpen={() => this.setState({ sizeMulSelect: true })}
          >
            {this.state.varient_size?.map((varient_size:any) => (
              <MenuItem
                key={varient_size.attributes.name}
                value={varient_size.attributes.name}
                data-test-id={`variant-${varient_size.attributes.name}`}
              >
                {varient_size.attributes.name}
              </MenuItem>
            ))}
          </SelectCustomMul>
        </FormControl>
        <CustomBoxDiv style={{ marginTop: 12, display: "flex", gap: 12 }}>
          {this.state.personName?.map((item) => {
            return (
              <div style={{
                display: "flex", width: "fit-content", borderRadius: 2, border: '1px solid #CCBEB1', padding: "10px", height: "18px",
                alignItems: "center", gap: 15
              }} key={item}>
                <h1 style={webStyle.selectedItem} data-test-id="genderSe">{item}</h1>
                <img src={crossmini} alt="crossmini" data-test-id="genderSel7" 
                onClick={() => this.removeSelectedSizeEdit(item)} />
              </div>
            )
          })}
        </CustomBoxDiv>
        <p data-test-id="sep-val" style={webStyle.errorMsg}>{this.transAddProductCatalogue("Separate values with commas")}</p><br />
        <h2 data-test-id="variant-val" style={webStyle.labelH}>{this.transAddProductCatalogue("Variant (Color)")}*</h2>
        <FormControl style={webStyle.selectDiv}>
          <SelectCustomMul
            multiple
            onOpen={() => this.setState({ colorSel: true })}
            onClose={() => this.setState({ colorSel: false })}
            displayEmpty
            disableUnderline={true}
            data-test-id="genderSel8"
            value={this.state.colorArry}
            onChange={this.handleColorChangeEdit}
            inputProps={{ 'aria-label': 'Without label' }}
            input={<OutlinedInput />}
            renderValue={() => {
              return <p style={{ paddingLeft: 15 }}>{this.transAddProductCatalogue("Select Color")}</p>;
            }}
            MenuProps={MenuProps}
            IconComponent={() => <img src={arrowselect} style={{ transform: genderEditArrow(this.state.colorSel) }} />}
          >
            {this.state.varient_color?.map((varient_color:any) => (
              <MenuItem
                key={varient_color.attributes.name}
                value={varient_color.attributes.name}
                data-test-id={`var-${varient_color.attributes.name}`}
              >
                {varient_color.attributes.name}
              </MenuItem>
            ))}
          </SelectCustomMul>
        </FormControl>
        <CustomBoxDiv style={{ marginTop: 12, gap: 12, display: "flex" }}>
          {this.state.colorArry?.map((item) => {
            return (
              <div style={{
                display: "flex", width: "fit-content", borderRadius: 2, border: '1px solid #CCBEB1', gap: 15, padding: "10px", height: "18px",
                alignItems: "center"
              }} key={item}>
                <h1 data-test-id="genderSel9div" style={webStyle.selectedItem}>{item}</h1>
                <img  alt="crossmini" src={crossmini} data-test-id="genderSel9" 
              onClick={() => this.removeSelectedColorEdit(item)}
              
                 />
              </div>
            )
          })}
        </CustomBoxDiv>
      
        <p style={webStyle.errorMsg}>{this.transAddProductCatalogue("Separate values with commas")}</p><br />
       
                    {this.state.create_vari.some((variant: any) => variant.skuError) && (
                        <p style={webStyle.errorMsg1}>
                            <p style={{ marginBottom: "8px", marginRight: "16px", }}><InfoOutlinedIcon /></p>
                            <p>
                                <p style={{ fontSize: "18px", color: "#DC2626",  fontWeight: 600 }}>
                                    {this.state.create_vari.find((variant: any) => variant.skuError)?.skuError}
                                </p>
                                <p style={{ marginTop: "-10px" }} id="variant-30">{this.transAddProductCatalogue("A max of 30 variants can be created")}</p>
                            </p>
                        </p>
                    )}
        <button 
        data-test-id="handleCreateVariant"
        style={webStyle.createVarBtn}
         onClick={this.handleCreateVariantEdit} 
         >
          { this.transAddProductCatalogue('Update Variant')}
          </button>
          
          <br />
        <p style={webStyle.errorMsg}>{this.transAddProductCatalogue("You can create a max of 30 variants of the product")}</p>
      </div></div>
        <div style={{ border: "1px solid #E2E8F0", marginTop: '20px', borderRadius: "4px",  padding: 15 }}><h2 style={webStyle.labelVari}>{this.transAddProductCatalogue("Variant Details")} &nbsp;[{this.state.create_vari.length}/30]</h2>
          <TableContainer component={Paper} style={{ marginTop: '10px', border: "1px solid #E2E8F0", borderRadius: "4px",  boxShadow: "none" }}>
            <Table aria-label="simple table" style={{ tableLayout: 'fixed' }} id="table-variant">
              <TableHead>
                <TableRow>
                  <TableCell id="table-head-variant" style={webStyle.thHead}>{this.transAddProductCatalogue("Variant")}</TableCell>
                  <TableCell id="table-head-Qyt" style={webStyle.thHead} align="left">{this.transAddProductCatalogue("Quantity")}</TableCell>
                  <TableCell id="table-head-price" style={webStyle.thHead} align="left">{this.transAddProductCatalogue("Price")}</TableCell>
                  <TableCell id="table-head-sku" style={webStyle.thHead} align="left">{this.transAddProductCatalogue("SKU")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
               
               

              {this.renderTableRows()}

              </TableBody>
            </Table>
          </TableContainer>
        </div></>)
    }
    const getSecondPageEdit = () => {
      switch (this.state.linkStatus) {
        case 1:
          return (
            <div style={{ border: "1px solid #E2E8F0", marginTop: '20px', padding: 15, borderRadius: "4px", }}>
              <CustomBoxDiv>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Product Name")}*</h2>
                  <CustomInput
                  name="productName"
                  data-test-id="handleChangeg"
                    id="outlined-basic"
                    placeholder={this.transAddProductCatalogue("Product Name")}
                    InputProps={{ disableUnderline: true }}
                    dir={i18n.dir()}
                    variant="standard"
                    value={this.state.productName}
                    onChange={this.handleEditChange}
                  />
                    {this.state.errors.productName && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.productName}</div>}
                </div>

                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Gender")}*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      dir={i18n.dir()}
                      id="demo-simple-select"
                      value={this.state.genderValue}
                      label="Age"
                      labelId="demo-simple-select-label"
                      disableUnderline={true}
                      data-test-id="genderSel"
                      onChange={(event) => this.setState({ genderValue: event.target.value as string })}
                      onOpen={() => this.setState({ genderSelect: true })}
                      onClose={() => this.setState({ genderSelect: false })}
                      IconComponent={() => <img src={arrowselect} style={{ transform: genderEditArrow(this.state.genderSelect) }} />}
                    >
                      <MenuItem style={webStyle.menuItem}  data-test-id="genderSelM" value={"male"}>{this.transAddProductCatalogue("Male")}</MenuItem>
                      <MenuItem style={webStyle.menuItem}  data-test-id="genderSelF" value={"female"}>{this.transAddProductCatalogue("Female")}</MenuItem>
                      <MenuItem style={webStyle.menuItem}  data-test-id="genderSelO" value={"other"}>{this.transAddProductCatalogue("Other")}</MenuItem>
                    </SelectCustom>
                    {this.state.errors.genderValue && <div data-test-id="gender-val" style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.genderValue}</div>}

                  </FormControl>
                </div>
              </CustomBoxDiv>
              <CustomBoxDiv data-test-id="select-brand">
                <div data-test-id="select-brand-div" style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Select Brand")}*</h2>
                  <CustomInput id="outlined-basic" data-test-id="select-brand-input" placeholder={this.transAddProductCatalogue("Brand")} variant="standard"
                   name="brand"
                   value={this.state.brand}
                   dir={i18n.dir()}
                   onChange={this.handleEditChange}
                   InputProps={{ disableUnderline: true }}
                  />
                              {this.state.errors.brand && <div style={{color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.brand}</div>}

                </div>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Select Category")}*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      dir={i18n.dir()}
                      id="demo-simple-select"
                      value={this.state.clothValue}
                      label="Age"
                      IconComponent={() => <img src={arrowselect} style={{ transform: genderEditArrow(this.state.clothSelect) }} />}
                      data-test-id="genderSel2"
                      disableUnderline={true}
                      onChange={this.handleCategoryEditChange}
                      onOpen={() => this.setState({ clothSelect: true })}
                      onClose={() => this.setState({ clothSelect: false })}
                    >
                     
              {this.state.category.map((category) => (
              <MenuItem
                key={category.id}
                style={webStyle.menuItem}
                value={category.attributes.name}
              >
                {category.attributes.name}
              </MenuItem>
            ))}
                    </SelectCustom>

                  </FormControl>
                </div>
              </CustomBoxDiv>
              <CustomBoxDiv>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Select Sub-category")}*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.categoryValue}
                      label="Age"
                      disableUnderline={true}
                      data-test-id="genderSel3"
                      onChange={this.handleSubCategoryEditChange}
                      onOpen={() => this.setState({ categorySelect: true })}
                      dir={i18n.dir()}
                      onClose={() => this.setState({ categorySelect: false })}
                      IconComponent={() => <img src={arrowselect} style={{ transform: genderEditArrow(this.state.categorySelect) }} />}
                    >
                      {this.state.category1?.map((sub) => (
                <MenuItem
                  key={sub.id}
                  style={webStyle.menuItem}
                  value={sub.attributes.name}
                  data-test={`sub-${sub.attributes.name}`}
                >
                  {sub.attributes.name}
                </MenuItem>
              ))}
                    </SelectCustom>

                  </FormControl>
                </div>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Select Sub-Sub-category")}*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      dir={i18n.dir()}
                      value={this.state.categoryValue1}
                      label="Age"
                      disableUnderline={true}
                      data-test-id="genderSel34"
                      onChange={this.handleSubSubCategoryEditChange}
                      onOpen={() => this.setState({ categorySelect: true })}
                      onClose={() => this.setState({ categorySelect: false })}
                      IconComponent={() => <img src={arrowselect} style={{ transform: genderEditArrow(this.state.categorySelect) }} />}
                    >
                      {this.state.subsubCategory?.map((subsub) => (
                <MenuItem
                  key={subsub.id}
                  style={webStyle.menuItem}
                  value={subsub.attributes.name}
                >
                  {subsub.attributes.name}
                </MenuItem>
              ))}
                    </SelectCustom>

                  </FormControl>
                </div>
              </CustomBoxDiv>

              <CustomBoxDiv>
              <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Material")}*</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder={this.transAddProductCatalogue("Material")}
              variant="standard"
              name="material"
              value={this.state.material}
              dir={i18n.dir()}
              onChange={this.handleEditChange}
              InputProps={{ disableUnderline: true }}
            />
                        {this.state.errors.material && <div style={{color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.material}</div>}

                </div>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Fit")}*</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder={this.transAddProductCatalogue("Fit")}
              variant="standard"
              name="fit"
              value={this.state.fit}
              onChange={this.handleEditChange}
              InputProps={{ disableUnderline: true }}
              dir={i18n.dir()}
            />
                        {this.state.errors.fit && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif",}}>{this.state.errors.fit}</div>}

                </div>
              </CustomBoxDiv>
              <CustomBoxDiv>
               
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Product Care")}*</h2>
                  <CustomInput
              id="outlined-basic"
              dir={i18n.dir()}
              placeholder={this.transAddProductCatalogue("Product Care")}
              variant="standard"
              name="productCare"
              value={this.state.productCare}
              onChange={this.handleEditChange}
              InputProps={{ disableUnderline: true }}
            />
                        {this.state.errors.productCare && <div data-test-id="error-product-care" style={{color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.productCare}</div>}

                </div>
              </CustomBoxDiv>
            

<h2 data-test-id="list-the-product" style={webStyle.labelH}>{this.transAddProductCatalogue("List the products")}*</h2>
<FormControl style={{ display: 'flex' }}>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue="listed"
    name="radio-buttons-group"
    onChange={(event) => this.setState({ listed: event.target.value })} 
    style={{ display: 'block', marginTop: 4 }}
    data-test-id="listedd"
  >
    <FormControlLabel
      value="listed"
      data-test-id="genderSel4"
      style={{
        fontFamily: "Lato , sans-serif",
        lineHeight: '26px',
        fontSize: '18px',
        fontWeight: fontEditListed(this.state.listed === "listed"), 
        color: '#375280',
        fontStyle: "normal !important",
      }}
      control={<CustomRadio />}
      label={this.transAddProductCatalogue("Listed")}
    />
    <FormControlLabel
    style={{
      fontWeight: fontEditListed(this.state.listed === "unlisted"), 
      fontStyle: "normal !important",
      fontFamily: "Lato , sans-serif",
      fontSize: '18px',
      lineHeight: '26px',
      color: '#375280'
    }}
    label={this.transAddProductCatalogue("Unlisted")}
      value="unlisted"
      data-test-id="genderSel5"
      control={<CustomRadio />}
    />
  </RadioGroup>
</FormControl>

              <h2 style={webStyle.labelH} data-test-id="product-description">{this.transAddProductCatalogue("Product Description")}*</h2>
              <div style={webStyle.detailDiv} data-test-id="product-description-div">
              <CustomInput
            id="outlined-basic"
            data-test-id="product-description-input"
            placeholder={this.transAddProductCatalogue("Product Description")}
            variant="standard"
            name="productDescription"
            dir={i18n.dir()}
            value={this.state.productDescription}
            onChange={this.handleEditChange}
            style={webStyle.detailTitle}
            InputProps={{ disableUnderline: true }}
          />
                      {this.state.errors.productDescription && <div data-test-id="product-description-error" style={{color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.state.errors.productDescription}</div>}

              </div></div>
          )
      
        case 2:
          return (getSecondEditPagevariant('Update', '20', '25', 'AS123SS'))
        case 3:
          return (
            <CustomBoxDivs style={{ display: 'flex', flexWrap: 'wrap', gap: "30px" }}>
           {this.state.gridImages.map((grid: any) => (
  <div key={grid.color} style={{ border: "1px solid #E2E8F0", borderRadius: "4px", marginTop: '70px', width: '580px' }}>
    {uploadBtnPart(
      `${grid.color.charAt(0).toUpperCase() + grid.color.slice(1)} ${this.transAddProductCatalogue("Variant")}`,
      grid.images,
      grid.selectedImage,
      grid.activeGridImage,
      grid.color,
      (viewName: string, e: ChangeEvent<HTMLInputElement>) => this.handleGridImage(grid.color, viewName, e),
      (name: string) => this.handleActiveGrid(grid.color, name),
      this.handleDeleteImage
    )}
  </div>
))}

          </CustomBoxDivs>
          )
        case 4:
          return (<CustomBoxDiv style={{ display: 'flex' }}>
          <div style={{ marginTop: '70px', width: '100%', border: "1px solid #E2E8F0", borderRadius: "4px", padding: '10px 20px 10px 20px' }}>
            <div style={{ padding: "10px 0px 10px 0px", display: 'flex', alignItems: 'center', borderBottom: '1px solid #D9D9D9', gap: "20px", justifyContent: 'space-between' }}>
            <h2 style={webStyle.headingL}>{this.transAddProductCatalogue("Select All Stores")}</h2>
             
            <GreenCheckbox
                checked={this.state.selectAll}
                data-test-id="selectAllCheckbox"
                onChange={this.handleSelectAllEditChange}                
              />
            </div>
          
            {this.state.editstoreList?.map((store: any) => (
              <div key={store.id} style={{ padding: "10px 0px 10px 0px", alignItems: 'center', display: 'flex', borderBottom: '1px solid #D9D9D9', gap: "20px", justifyContent: 'space-between' }}>
                <div style={{  gap: "20px", display: 'flex', alignItems:"center" }}>
                  <img  style={{width:"40px",borderRadius:"50px", height:"40px",}} src={store.attributes.image} alt="upload" />
                  <p style={{ fontSize: "18px", color: "#375280", fontFamily: "Lato, sans-serif" }}>{store.attributes.store_name}</p>
                </div>
                <GreenCheckbox
                  checked={this.state.selectedStores.includes(store.id)}
                  data-test-id="checkBox"
                  onChange={() => this.handleCheckboxEditChange(store.id)}
                />
              </div>
            ))}
          </div>
        </CustomBoxDiv>
          )
        default:
          break;
      }

    }
    const uploadBtnPart = (
      imgName: string,
      gridEditData: ImageData[],
      selectedImg: string,
      activeGrid: string,
      color: string,
      handleGridImage: (viewName: string, e: ChangeEvent<HTMLInputElement>) => void,
      handleActiveGrid: (name: string) => void,
      handleDeleteImage: (color: string, viewName: string) => void 
    ) => {
      if (!gridEditData) {
        return null;
      }
    
      return (
        <div style={{ margin: "22px" }} data-test-id="upload-edit-btn" key={"activeee" + activeGrid}>
          <h1 style={webStyle.titleImg} data-test-id="upload-edit-btn-title">{imgName}</h1>
    
          {selectedImg === '' ? (
            <>
            <UploadBtnBox data-test-id="upload-edit-btn-box">
              <Button
                component="label"
                style={{ paddingLeft: 50, display: "grid", textAlign: 'center',  boxShadow: 'none', width: '535px', height: '230px', backgroundColor: '#F3F3F3', borderRadius: '2px' }}
                variant="contained"
              >
                <img src={upload} data-test-id="upload-edit-btn-img" alt="upload" />
                <UploadBtn data-test-id="upload-edit-btn-title-txt">
                  {this.transAddProductCatalogue("Upload Image")}
                </UploadBtn>
                <UploadText data-test-id="upload-edit-btn-des">{this.transAddProductCatalogue("Only png or Jpg files are accepted")}</UploadText>
                <input
                  disabled={activeGrid === ''}
                  type="file"
                  onChange={(e) => handleGridImage(activeGrid, e)} 
                  hidden
                  data-test-id="upload-image-test"
                  accept=".png, .jpg, .jpeg"
                />
              </Button>
              </UploadBtnBox>
              <button
                style={webStyle.uploadBtn}
                data-test-id="genderSe11"
                onClick={() => this.fileInputRef.current.click()}
              >
                {this.transAddProductCatalogue("Upload Photo")}
              </button>
            </>
          ) : (
            <div style={{display: 'inline-block', position: 'relative',  }}>
              <img src={selectedImg} alt='testttt' style={{ objectFit: "contain", boxShadow: 'none', textAlign: 'center', display: "grid",  width: '535px', height: '230px', backgroundColor: '#F3F3F3', borderRadius: '2px' }} />
              <button 
                style={{
                  height: '36px',
                  bottom: '10px',
                  right: '17px',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  color: 'white',
                  border: 'none',         
                  borderRadius: '50%',
                  position: 'absolute',
                  width: '36px',
                }} 
                onClick={() => handleDeleteImage(color, activeGrid)}
              >
                    <img 
            src={deleteBttn}
            alt="Delete"
            style={{
            height: '30px',
            width: '30px',
          }}
        />
              </button>
            </div>
          )}
    
          <div style={{ display: "flex", marginTop: 18, gap: 22, flexDirection: i18n.dir()==="ltr" ? "row" : "row-reverse", justifyContent: i18n.dir()==="ltr" ? "flex-start" : "flex-end" }}>
            {gridEditData.map((item:any, index:number) => (
              <div 
                style={{ ...webStyle.boxesImg, border: activeGrid === item.name ? '2px solid #375280' : "" }} 
                key={`${item.name + index}`}
                onClick={() => handleActiveGrid(item.name)}
              >
                <img style={webStyle.allThreeimg} src={item.imgUrlLink} alt={this.transAddProductCatalogue(item.name)} />
                <CustomImgFont>{this.transAddProductCatalogue(item.name)}</CustomImgFont>
              </div>
            ))}
          </div>
        </div>
      );
    }
    
    


    const linkStatusFirst = (lnk: number) => {
      return (LinkStatusFirst(this.state.linkStatus, lnk))
    }
   

    const fontWeight = (weight: number) => {
      return (FontWight(this.state.linkStatus, weight))
    }

  

   
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }} >
          <img src={arrow} alt="arrow" style={{transform: this.handleBackUpdateCSS()}} onClick={() => {
    if (this.state.linkStatus === 1) {
      window.location.reload();
    } else {
      this.setState({ linkStatus: this.state.linkStatus - 1 });
    }
  }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <div style={{display:"flex",alignItems:"center",width:"100%", gap: 20, justifyContent: "space-between"}}>
          <h2 style={webStyle.addPro}>{this.transAddProductCatalogue("Edit Product")}</h2>
          <div>
            <button style={webStyle.confirmBtn} data-test-id="genderSel12" onClick={this.handleUpdateNextStep}>
            {this.transAddProductCatalogue("Save Details")}
              </button>
              </div>
          </div>
        </div>
        <CustomBox >
          <div style={{cursor:"pointer",color: linkStatusFirst(2),borderBottom: this.state.linkStatus === 1 ? '3px solid #375280' : 'none',}} 
          data-test-id="linkStatus1"
          onClick={() => this.setState({ linkStatus: 1 })}>
          
            <h2 style={{ color: "#375280", fontSize: "16px",  display: "ruby",lineHeight: "24px",padding:"14px 12px 14px 12px", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: 700 , whiteSpace: "nowrap",}}>{this.transAddProductCatalogue("Product Details")}</h2>
          </div>
        
          <div style={{color: linkStatusFirst(4),borderBottom: this.state.linkStatus === 2 ? '3px solid #375280' : 'none',cursor:"pointer"}}
          data-test-id="linkStatus3"
          onClick={() => this.setState({ linkStatus: 2 })}>
         
            <h2 style={{ color: "#375280", 
            fontSize: "16px", lineHeight: "24px", padding:"14px 12px 14px 12px", fontStyle: "normal !important",display: "ruby", fontFamily: "Lato , sans-serif", fontWeight: fontWeight(3), whiteSpace: "nowrap" }}>{this.transAddProductCatalogue("Update Variants")}</h2>
          </div>
          <div style={{color: linkStatusFirst(5),borderBottom: this.state.linkStatus === 3 ? '3px solid #375280' : 'none',cursor:"pointer"}}
          data-test-id="linkStatus4"         onClick={() => this.setState({ linkStatus: 3 })}>
           
            <h2 style={{ color: "#375280", fontSize: "16px",
             lineHeight: "24px",padding:"14px 12px 14px 12px",
              fontStyle: "normal !important", fontFamily: "Lato , sans-serif", display: "ruby", fontWeight: fontWeight(4), whiteSpace: "nowrap" }}>{this.transAddProductCatalogue("Upload Images")}</h2>
          </div>
          {this.state.userRole &&this.state.userRole?.userType=='seller' && (
          <div style={{color: linkStatusFirst(6)
            ,borderBottom: this.state.linkStatus === 4 ? '3px solid #375280' : 'none',
            cursor:"pointer"}}
            data-test-id="linkStatus51"
         onClick={() => this.setState({ linkStatus: 4 })}>
           
            <h2 style={{ color: "#375280", fontSize: "16px", lineHeight: "24px", display: "ruby",padding:"14px 12px 14px 12px", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: fontWeight(5), whiteSpace: "nowrap" }}>{this.transAddProductCatalogue("Assign Stores")}</h2>
          </div>
          )}
        </CustomBox>


        {getSecondPageEdit()}


        <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={3000}
                  data-test-id="alertTestId"
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                >
                  <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
        <EditDialogContainer data-test-id="handleRedirect1"  open={this.state.isSuccess} >
          <Box className="content-container">
            <img src={storeSuccessIcon} alt="store-success-icon" data-test-id="edit-success-img"/>
            <Typography className="text-container" data-test-id="edit-success">{this.transAddProductCatalogue("Product Edited successfully!")}</Typography>
          </Box>
        </EditDialogContainer>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const EditDialogContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 110px",
    "& .content-container": {
      flexDirection: "column",
      display: "flex",
      justifyContent: "center",
      gap: 30,
      alignItems: "center",
      "& .text-container": {
        color: "#0F172A",
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        textAlign: "center"
      },
      "@media(max-width:700px)": {
      padding: "30px 60px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
  },
});
const GreenCheckbox = styled(Checkbox)({
  color: "#CCBEB1",
  '&.Mui-checked': {
    color: "#CCBEB1",
  },

  '&.Mui-checked + .MuiIconButton-label': {
    backgroundColor: '#CCBEB1',
  }
});
const UploadText = styled(Typography)({
  textTransform: "lowercase",
  color: "#94A3B8",
  fontSize: "18px",
  fontStyle: "normal !important",
  display: "flex",
  justifyContent: "center",
  fontWeight: 500,
  fontFamily: "Lato , sans-serif",
  marginTop: "5px"
})
const UploadBtn = styled(Typography)({
  fontWeight: 700,
  boxShadow: 'none',
  textTransform: "capitalize",
  color: "#375280",
  fontSize: "18px",
  display: "flex",
  justifyContent: "center",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  marginTop: "15px"
})

const CustomRadio = styled(Radio)({
  '& MuiTypography-body1': {
    fontWeight: 'inherit !Important'
  },
  '&  .MuiIconButton-label': {
    color: '#C7B9AD',
    fontWeight: 'inherit !Important'
  },
})

const SelectCustom = styled(Select)(({ dir }: { dir: "rtl" | "ltr" }) =>({
  fontStyle: "normal !important",
  background: "#f1f1f1",
  border: "1px solid #F8F8F8",
  borderRadius: "4px",
  height: "53px",
  fontFamily: "Lato , sans-serif",
  fontSize: "18px",
  color: '#375280',
  padding: dir === "ltr" ? "0px 15px 0px 20px" : "0px 20px 0px 15px",
  fontWeight: 400,
  '& .MuiSelect-select.MuiSelect-select': {
    padding: 0,
    background: '#f1f1f1',
  }
}));

const SelectCustomMul = styled(Select)({
  padding: "0px 15px",
  background: "#f1f1f1",
  borderRadius: "4px",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "26px",
  fontStyle: "normal !important",
  color: '#94A3B8',
  boxShadow: 'none', fontFamily: "Lato , sans-serif",'& .MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-select.MuiSelect-select': {
    background: '#f1f1f1',
    height: 60,
    padding: "0px !important",
    margin: 0,
  },
})

const CustomBoxDivs = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap:"30px"
})

const CustomBoxDiv = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
})

const CustomBox = styled(Box)({
  marginTop: '12px',
  display: 'flex',
  alignItems: "center",
  width: '100%',
  marginLeft: "34px",
})

const CustomInputTable = styled(TextField)({
  border: '1px solid #E2E8F0',
  height: '40px',
  borderRadius: "4px",
  width: "100px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  '& ::placeholder': {
    fontWeight: 400,
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  '& .MuiInputBase-root': {
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    color: "#375280",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    textAlign: 'center',
  },
  '& .MuiInputBase-input': {
    paddingTop: '10px',
    textAlign: 'center',
  }
});

const CustomImgFont = styled(Typography)({
  color: '#94A3B8',
  marginTop: 42,
  background: '#E2E8F0',
  textAlign: 'center',
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "18px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
})

const CustomInput = styled(TextField)(({ dir }: { dir: "rtl" | "ltr" }) =>({
  borderRadius: "4px",
  width: "94%",
  background: "#f1f1f1",
  height: '43px',
  border: "1px solid #F8F8F8",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  padding: dir === "ltr" ? "8px 0px 0px 15px" : "8px 15px 0px 0px",
  marginBottom: 10,
  '& ::placeholder': {
    fontWeight: 400,
    fontStyle: "normal !important",
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "Lato , sans-serif",
  },
  '& .MuiInputBase-root': {
    fontSize: "18px",
    lineHeight: "24px",
    color: "#375280",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  }
}));

const webStyle = {
  titleImg: {
    fontSize: "20px",
    fontWeight: 700,
    color: '#375280',
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },

  allThreeimg: {
    marginBottom: "-40px",
    width: "100%",
    height: 80
  },
  boxesImg: { width: 100, height: 100, border: '1px solid #E2E8F0', borderRadius: 2 },
  tableInput: {
     textAlign: "center",
    width: 100,
    height: 40,
    border: '1px solid #E2E8F0',
  },
  thHead: {
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    borderBottom: "1px solid #CBD5E1",
    color: '#375280',
  },
  trHead: {
    paddingTop: 25,
    paddingBottom: 25,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: '#375280',
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    borderBottom: "1px solid #CBD5E1",
  },
  errorMsg: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    margin: 0,
    color: '#94A3B8'
  },
  errorMsg1:{
    color: 'rgb(244, 67, 54)',
    fontWeight: 100,
    padding: '1px 8px 1px 16px',
    fontSize: '16px',
    fontFamily: 'Lato, sans-serif',
    border: '1px solid #DC26264D',
    borderRadius: '4px',
    backgroundColor: '#FEE2E24D',
    display:"flex",
    alignItems:"center"
  },
  selectedItem: {
    lineHeight: "24px",
    color: "#375280",
    fontSize: "16px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  selectDiv: {
    width: '97%'
  },
  detailTitle: {
    color: "#375280",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "30px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    height: 122
  },
  detailDiv: {
    width: "103%"
  },
  labelH: {
    lineHeight: "26px",
    width: '97%',
    color: "#375280",
    fontSize: "18px",
    fontWeight: 700,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    marginBottom: "5px"
  },
  headingL: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "26px",
    fontWeight: 500,
    fontSize: "18px",
    marginBottom: "5px",
    color: "#375280",
    width: '97%',
  },
  labelVari: {
    width: '97%',
    color: "#375280",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    marginBottom: "5px",
    marginTop: '20px'
  },
  hrTagFill: {
    width: 244,
    height: 0,
    border: '1px solid #375280',
    margin: 0,
  },
  menuItem: {
    color: "#375280",
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  hrTag: {
    height: 0,
    width: 315,
    margin: 0,
    border: '1px solid #E2E8F0'
  },
  circleDivActive: {
    border: "2px solid #375280",
    height: 32,
    borderRadius: '30px',
    width: 32, 
  },
  circleDivFilled: {
    border: "2px solid #375280",
    height: 32,
    width: 32,
    borderRadius: '30px',
    background: '#375280'
  },
  circleDiv: {
    border: "2px solid rgb(89, 117, 163)",
    height: 32,
    width: 32,
    borderRadius: '30px'
  },
  addPro: {
    color: "#375280",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  uploadBtn: {
    cursor: "pointer",
    marginTop: 18,
    width: '535px',
    height: '54px',
    background: "#FFFFFF",
    color: "#375280",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    border: "1px solid #CCBEB1",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  backCsvBtn: {
    width: '200px',
    height: '54px',
    fontStyle: "normal !important",
    background: "#FFFFFF",
    border: "1px solid #CCBEB1",
    color: "#375280",
    fontSize: "18px",
    fontFamily: "Lato , sans-serif",
    fontWeight: 500,
    lineHeight: "24px",
    cursor: "pointer"
  }, createVarBtn: {
    width: '250px',
    color: "#375280",
    height: '54px',
    background: "#FFFFFF",
    border: "1px solid #CCBEB1",
    fontSize: "18px",
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontWeight: 500,
    fontFamily: "Lato , sans-serif",
    cursor: "pointer",
    marginBottom: 6
  },
  confirmBtn: {
    width: '200px',
    height: '54px',
    background: "#CCBEB1",
    fontFamily: "Lato , sans-serif",
    border: "1px solid #CCBEB1",
    color: "#FFFFFF",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    borderRadius: '2px',
    cursor: "pointer"
  },
};
// Customizable Area End
