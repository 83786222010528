import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Image, ImageListType, SelectedImage } from "./types";
import {  portfolioImage0, portfolioImage1, portfolioImage2, portfolioImage3, portfolioImage4 } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import React from "react";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

interface MultipleUpload {
  image: string,
  description: string
}
interface PortfolioType {
  id: string,
  type: string,
  attributes: {
    images: {
      portfolio_image_id: number,
      description: string,
      image_id: number,
      url: string
    }[]
  }
}
interface ImageAttributes {
    portfolio_image_id: number,
    description: string,
    image_id: number,
    url: string
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAddImageModalVisible: boolean;
  isViewImageModalVisible: boolean;
  isVisibleDeleteCheckbox: boolean;
  isShareModalVisible: boolean;
  imageData: ImageListType[];
  selectedImage: SelectedImage;
  viewSelectedImage: SelectedImage;
  selectedImageId: string | undefined;
  addImageError: boolean;
  photoLibraryId: string;
  inputAccountId: string;
  inputAccountIdError: boolean;
  activeTab: number;
  checkedAll: boolean;
  deleteDialog: boolean;
  portfolioData: {imageUrl: string;
    id: number, description: string;
  }[],
  errorsMessage: {
    descriptionErrorMsg: string;
  },
  description: string;
  particularDeleteDialog: boolean;
  deleteId: string;
  deleteData: {
    id: string;
    image: string[],
    imageDescription: string[] 
  },
  uploadDelete: boolean;
  deleteUploadData: {imageUrl: string;
    id: number, description: string;
  };
  uploadSuccess: boolean;
  userToken: string;
  portFolioDataApi: PortfolioType | undefined;
  portFolioPaginateDataApi: PortfolioType[];
  isAlert: boolean;
  alertMsg: string;
  alertType: 'success' | 'error' | 'warning' | 'info';
  inputRef: React.RefObject<HTMLInputElement>;
  imageUpload: FileList | null,
  isParticular: boolean;
  multipleUpload: MultipleUpload[];
  multipleErrorDes: string[]
  imageUploadMultiple: FileList | null,
  singleDeletePop: boolean,
  page: number;
  portfolioMeta: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
}
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PhotoLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPhotoLibraryApiCallId = "";
  addImageToPhotoLibraryApiCallId = "";
  deletePhotoLibraryApiCallId = "";
  sharePhotoLibraryApiCallId = "";
  getPortfolioApiCallID = "";
  deleteAllPortfolioApiCallID = "";
  postPortfolioApiCallID = "";
  postMultiplePortfolioApiCallID = "";
  updatedPortfolioApiCallID = "";
  deleteSingleimageApiCallID = "";
  getPortfolioPaginateApiCallID:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isAddImageModalVisible: false,
      isViewImageModalVisible: false,
      isVisibleDeleteCheckbox: false,
      isShareModalVisible: false,
      selectedImage: { uri: "" },
      viewSelectedImage: { uri: "" },
      selectedImageId: "",
      addImageError: false,
      imageData: [],
      photoLibraryId: "",
      inputAccountId: "",
      inputAccountIdError: false,
      activeTab: 0,
      checkedAll: false,
      deleteDialog: false,
      portfolioData: [{
          imageUrl: portfolioImage0,
          id: 0,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage2,
          id: 1,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage1,
          id: 2,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage3,
          id: 3,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage4,
          id: 4,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage0,
          id: 5,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage1,
          id: 6,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage2,
          id: 7,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage4,
          id: 8,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage3,
          id: 9,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage0,
          id: 10,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage2,
          id: 11,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },{
          imageUrl: portfolioImage3,
          id: 12,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        }
        ,
        {
          imageUrl: portfolioImage1,
          id: 13,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage4,
          id: 14,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        }, {
          imageUrl: portfolioImage0,
          id: 15,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage1,
          id: 16,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        },
        {
          imageUrl: portfolioImage4,
          id: 17,
          description: "Person is dressed with a Levi’s white T-Shirt and a Highlander denim jacket. Paired with a Levi’s black jeans and black belt."
        }
      ],
      errorsMessage: {
        descriptionErrorMsg: ""
      },
      description: '',
      particularDeleteDialog: false,
      deleteId: "",
      deleteData: {
        id: "",
        image: [],
        imageDescription: [] 
      },
      uploadDelete: false,
      deleteUploadData: {
        imageUrl: "",
        id: 0,
        description: ""
      },
      uploadSuccess: false,
      userToken: "",
      portFolioDataApi: undefined,
      portFolioPaginateDataApi: [],
      isAlert: false,
      alertMsg: '',
      alertType: 'success',
      inputRef: React.createRef(),
      imageUpload: null,
      isParticular: false,
      multipleUpload: [],
      multipleErrorDes: [],
      imageUploadMultiple: null,
      singleDeletePop: false,
      page: 1,
      portfolioMeta: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
    }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
     // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token });
        this.getPhotoLibrary(token);
      }
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id ){
      this.handleGetAPiCallID(apiRequestCallId, message);
      this.handleAddImageCallID(apiRequestCallId, message);
      this.handleDeletePhotLibraryCallID(apiRequestCallId, message);
      this.handleGetPortfolioCallID(apiRequestCallId, message);
      this.handleDeleteAllApiCall(apiRequestCallId, message);
      this.handleSingleUploadPostApiCall(apiRequestCallId, message);
      this.handleMultipleUploadPostApiCall(apiRequestCallId, message);
      this.handleUpdateCallID(apiRequestCallId, message);
      this.handleSingleDeleteApiCall(apiRequestCallId, message);
      this.handlePaginatePortfolioCallID(apiRequestCallId, message);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.sharePhotoLibraryApiCallId != null &&
      this.sharePhotoLibraryApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.message) {
        this.setState({ isShareModalVisible: false });
        alert(responseJson.message);
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    this.getPhotoLibrary(this.state.token);
    const userToken = await getStorageData("auth-token");
    this.setState({
      userToken: userToken
    })
    this.getPortfolioData();
    this.getPortfolioPaginateData();

    // Customizable Area End
  }

  // Customizable Area Start
  handleSingleDeleteApiCall =  (apiRequestCallId: string, message: Message) => {
    if (this.deleteSingleimageApiCallID === apiRequestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     if(responseJson?.errors?.length > 0){
      if(responseJson?.errors[0].token){
        this.setState({
          isAlert: true,
          alertType: "error",
          alertMsg: responseJson?.errors[0]?.token
        })
      }
     }else if(responseJson?.message){
      this.setState({
        isAlert: true,
        alertType: "success",
        alertMsg: responseJson?.message,
        singleDeletePop:false,
        particularDeleteDialog: false,
      });
      this.getPortfolioPaginateData()
     }
    }
  };
  handleUpdateCallID = (apiRequestCallId: string, message: Message) => {
    if (this.updatedPortfolioApiCallID === apiRequestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.message){
        this.setState({
          particularDeleteDialog: false,
          isAlert: true,
          alertMsg: responseJson?.message,
          alertType: "success"
        });
        this.getPortfolioPaginateData()
      }
    }
  };
  handleSingleUploadPostApiCall = (apiRequestCallId: string, message: Message) => {
    if (this.postPortfolioApiCallID === apiRequestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        this.setState({
          uploadDelete: false,
          deleteData: {
            id: "",
            image: [],
            imageDescription: [] 
          }, imageUpload: null
        });
        this.getPortfolioPaginateData()
      }
    }
  };
  handleMultipleUploadPostApiCall= (apiRequestCallId: string, message: Message) => {
    if (this.postMultiplePortfolioApiCallID === apiRequestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        this.setState({
          uploadSuccess: true,
          multipleUpload: [],
          imageUploadMultiple: null
        });
        this.getPortfolioPaginateData();
        setTimeout(() => {
          this.setState({
            uploadSuccess: false
          })
        }, 3000)
      }
    }
  };
  handleMultiplelistCancel = () => {
    this.setState({
      imageUploadMultiple: null,
      multipleUpload: []
    })
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    event.preventDefault();
    this.setState({ page: newPage }, () => this.getPortfolioPaginateData());
};

  handleMultipleDel = (delIndex: number) => {
  const updatedState = this.state.multipleUpload && this.state.multipleUpload.filter((element, index) => ( index !== delIndex && element));
  this.setState({
    multipleUpload: updatedState
  });
  }
  handleDeleteAllApiCall= (apiRequestCallId: string, message: Message)=> {
    if (this.deleteAllPortfolioApiCallID ===apiRequestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.message){
        this.setState({
          deleteDialog:false,
          isAlert: true,
          alertMsg: responseJson.message,
          alertType: "success",
          checkedAll: false
        });
        this.getPortfolioPaginateData()
      }
    }
  }
  handleGetAPiCallID = (apiRequestCallId: string, message: Message) => {
    if (this.getPhotoLibraryApiCallId ===apiRequestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson && responseJson.data) {
        let newPhotoArr = [];
        newPhotoArr = responseJson.data.attributes.photos.map(
          (imgData: ImageListType, index: number) => {
            return {
              ...imgData,
              id: (index + 1).toString(),
              isSelected: false,
            };
          }
        );
        this.setState({
          imageData: newPhotoArr,
          photoLibraryId: responseJson.data.id,
        });
      } else if (responseJson && responseJson.errors) {
        this.showAlert("Alert", "Something went wrong.");
      }
    }
  };
  handleAddImageCallID = (apiRequestCallId: string, message: Message) => {
    if (this.addImageToPhotoLibraryApiCallId === apiRequestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson && responseJson.data) {
        alert("Image added successfully");
        this.getPhotoLibrary(this.state.token);
      } else if (responseJson && responseJson.errors) {
        this.showAlert("Alert", "Something went wrong.");
      }
    }
  };
  handleDeletePhotLibraryCallID = (apiRequestCallId: string, message: Message) => {
    if (this.deletePhotoLibraryApiCallId === apiRequestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.message) {
        alert(responseJson.message);
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };
  handlePaginatePortfolioCallID = (apiRequestCallId: string, message: Message) => {
    if (apiRequestCallId === this.getPortfolioPaginateApiCallID){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.data) {
        if(responseJson?.data){
          this.setState({
            portFolioPaginateDataApi: responseJson?.data,
            portfolioMeta: responseJson?.meta
          });      
        }
      }}
  };

  handleGetPortfolioCallID = (apiRequestCallId: string, message: Message) => {
    if (apiRequestCallId === this.getPortfolioApiCallID){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.data) {
        if(responseJson?.data[0]?.attributes?.images?.length > 0){
          this.setState({
          portFolioDataApi: responseJson?.data[0]
          });      
        }else{
          this.setState({
            portFolioDataApi:  responseJson?.data[0]
          })
        }
      }else if (responseJson?.errors[0]?.token) {
        this.setState({
          isAlert: true,
          alertMsg: responseJson?.errors[0]?.token,
          alertType: "error"
        })
      }
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  handlebtnAddImage = () => {
    this.setState({
      selectedImage: { uri: "" },
      isAddImageModalVisible: true,
      addImageError: false
    });
  };

  handlebtnViewImage = (item: ImageListType) => {
    this.setState({
      selectedImageId: item.id,
      viewSelectedImage: { uri: item.file_url },
      isViewImageModalVisible: true
    });
  };

  handleAnputAccountID = (accountId: string) => {
    this.setState({ inputAccountId: accountId, inputAccountIdError: false });
  };

  closeViewImageModal = () => {
    this.setState({ isViewImageModalVisible: false });
  };

  closeShareModal = () => {
    this.setState({ isShareModalVisible: false });
  };

  openShareModal = () => {
    this.setState({
      isShareModalVisible: true,
      inputAccountIdError: false,
      inputAccountId: "",
    });
  };

  closeAddImageModal = () => {
    this.setState({ isAddImageModalVisible: false });
  };

  handleDeleteGallery = () => {
    this.setState({ imageData: [] });
    this.deletePhotoLibrary(this.state.photoLibraryId);
  };

  toggleDeleteMultipleImages = () => {
    this.setState({
      isVisibleDeleteCheckbox: !this.state.isVisibleDeleteCheckbox
    });
  };

  deleteSelectedImages = () => {
    this.setState({
      imageData: this.state.imageData.filter(
        (image: ImageListType) => !image.isSelected
      ),
      isVisibleDeleteCheckbox: false
    });
  };

  handleDeleteImage = () => {
    this.setState({
      imageData: this.state.imageData.filter(
        (image: ImageListType) => image.id !== this.state.selectedImageId
      ),
      isViewImageModalVisible: false
    });
  };

  handleUploadDelete = () =>{ 
    this.setState({
      uploadDelete: true,
    })

  }

  toggleImageChecked = (imageData: ImageListType) => {
    let newData = this.state.imageData.map((account: ImageListType) => {
      if (account.id === imageData.id) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ imageData: newData });
  };


  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  handleAddnewImage = async () => {
    const ImagePicker = require("react-native-image-crop-picker");
    const imageData: Image = await ImagePicker.openPicker({
      cropping: true,
      includeBase64: true,
      includeExif: true
    });

    const source = { uri: `data:${imageData.mime};base64,` + imageData.data };
    this.setState({
      selectedImage: source,
      addImageError: false
    });
  };

  handleSaveImage = () => {
    if (this.state.selectedImage.uri !== "") {
      const data = this.state.imageData;
      let imgObj = {
        id: (data.length + 3).toString(),
        isSelected: false,
        file_name: "abc",
        file_url: this.state.selectedImage.uri
      };
      data.push(imgObj);
      this.setState({
        imageData: data,
        isAddImageModalVisible: false,
        addImageError: false
      });
    } else {
      this.setState({ addImageError: true });
    }
    this.addImageToPhotoLibrary("")
  };

  getPhotoLibrary = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPhotoLibraryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.photoLibraryApiEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeleteAll = (deleteAllId: string | undefined) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAllPortfolioApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteAllPortfolioApiEndpoint}?portfolio_info_id=${deleteAllId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });};

    handleFileUpload = (files:  FileList | null) => {
      if(files){
        this.setState((prevState)=> ({
          isParticular: false,
          deleteData: {
            ...prevState.deleteData,
            image: [URL.createObjectURL(files[0])]
          },
          imageUpload: files,
        }))
      }
    };

    handleFileUploadMultiple = (files:  FileList | null) => {
    if(files){
      this.setState({
      imageUploadMultiple: files
      })
 
    for (const file of files) {
      this.setState((prevState) => ({
        multipleUpload: [
          ...prevState.multipleUpload,
          {
            image: URL.createObjectURL(file),
            description: "",
          },
        ],
        multipleErrorDes: [...prevState.multipleErrorDes, ""],
      }));
    }

  }};

    handleMultipleChange = (descr: string, index: number) => {
      this.setState({multipleUpload: this.state.multipleUpload.map((obj: MultipleUpload, index1: number) => index === index1 ? {...obj, description : descr} : obj)})
    }

    handleUpdateDelete = () => {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.userToken,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteSingleimageApiCallID = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.singleDeleteEndPoint}?portfolio_image_id=${this.state.deleteUploadData?.id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleDeleteSingleOpen = () => {
      this.setState({
        singleDeletePop: true
      })
    }

    handleDeleteParticularImage = () => {
      this.setState((prevState)=> ({
        deleteData: {
          ...prevState.deleteData,
          image: []
        },
        deleteUploadData: {
          ...prevState.deleteUploadData,
          imageUrl: ""
        },
        isParticular: false,
        imageUpload: null,
      }))
    }

    handleUpload = () => {
      return this.state.inputRef.current
        ? this.state.inputRef.current.click()
        : "";
    };

    handleSingleUpload = () => {
      const header = {
        token: this.state.userToken,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const formData = new FormData();
      if(this.state.imageUpload){        
        formData.append('images[]', this.state.imageUpload[0], this.state.imageUpload[0]?.name)
        formData.append('image_descriptions[]', this.state.deleteData.imageDescription[0])
      }

    this.postPortfolioApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPortfolioApiEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleMultipleUpload = () => {
      const header = {
        token: this.state.userToken,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const formData = new FormData();
      if(this.state.imageUploadMultiple){
        for(let iteration=0; iteration<this.state.imageUploadMultiple.length; iteration++) {
          formData.append('images[]', this.state.imageUploadMultiple[iteration])
          formData.append('image_descriptions[]', this.state.multipleUpload[iteration]?.description)
        }
      }

    this.postMultiplePortfolioApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPortfolioApiEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSingleUpdate = () => {
      const header = {
        token: this.state.userToken,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const formData = new FormData();
      if(this.state.imageUpload){        
        formData.append('images[]', this.state.imageUpload[0], this.state.imageUpload[0]?.name)
      }
      if(this.state.deleteData.imageDescription[0]){
        formData.append('description', this.state.deleteData.imageDescription[0])
      }else{
        formData.append('description', this.state.deleteUploadData.description)
      }

    this.updatedPortfolioApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.putPortfolioApiEndpoint}?portfolio_image_id=${this.state.deleteUploadData?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
handleCondition = (condition: any, trueSta: string, falseSta: string) => {
  return condition ? trueSta : falseSta
}
  getPortfolioData = async() => {
    const data  = await getStorageData("user_data", true)
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPortfolioApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPortfolioApiEndpoint}?user_id=${data?.id}&page=1&per_page=1`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPortfolioPaginateData = async() => {
    const data  = await getStorageData("user_data", true)
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPortfolioPaginateApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPortfolioPaginateApiEndpoint}?user_id=${data?.id}&page=${this.state.page}&per_page=${20}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addImageToPhotoLibrary = (token: string) => {
    if (this.state.selectedImage.uri === "") {
      this.setState({ addImageError: true });
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addImageToPhotoLibraryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.photoLibraryApiEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deletePhotoLibrary = (galleryId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePhotoLibraryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.photoLibraryApiEndpoint + "/" + `${galleryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTab = (tab: number) => {
    this.setState({ activeTab: tab})
  };
  handleSelectAll = (value: boolean) => {
    this.setState({
      checkedAll: value
    });
  }

  handleDeleteOpen = () => {
   this.setState({
    deleteDialog: true
   })
  };

  handleDeleteDialogClose = () => {
    this.setState({
      deleteDialog:false
    })
  }

  handleDeleteUpdateDelete = () => {
    this.setState({
      singleDeletePop:false
    })
  }

  sharePhotoLibrary = () => {
    if (this.isStringNullOrBlank(this.state.inputAccountId)) {
      this.setState({ inputAccountIdError: true });
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.sharePhotoLibraryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.photoLibraryApiEndpoint +
          "/" +
          `${this.state.photoLibraryId}` +
          "/share"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
  handleDescriptionChange = (event: string) => {
    const descriptionChange = event;
    this.setState((prevState) => ({
      deleteData: {
        ...prevState.deleteData,
        imageDescription: [descriptionChange]
      },
      errorsMessage: {
            descriptionErrorMsg: ""
          },
    }))
  };

  handleDescriptionchange = (event: string) => {
    const descriptionChange = event;
    this.setState((prevState) => ({
      deleteData: {
        ...prevState.deleteData,
        imageDescription: [descriptionChange]
      },
      deleteUploadData: {
        ...prevState.deleteUploadData,
        description: descriptionChange
      },
      errorsMessage: {
            descriptionErrorMsg: ""
          },
    }))
  };

  handleDeleteUpload = () => {
    this.setState({
      uploadDelete: false,
      deleteUploadData: {
        imageUrl: "",
        id: 0,
        description: ""
      }
    })
  }

  generateClassName = (
    error: string | undefined,
    baseClassName: string,
    errorClassName: string
  ) => {
    return error ? errorClassName : baseClassName;
  };

  handleParticularDelete = (image: ImageAttributes) => {
    this.setState({
      particularDeleteDialog: true,
      deleteUploadData: {
        id: image?.portfolio_image_id,
        imageUrl: image?.url,
        description: image?.description
      },
      isParticular: true
    });
  }

  handleDeleteClosePar = () => {
    this.setState({
      deleteId: "",
      particularDeleteDialog: false,
    })
  }

  transPortfolio = (keyPort: string) => {
    return i18n.t(keyPort, { ns: "stylistPortfolio"})
  }
  // Customizable Area End
}
