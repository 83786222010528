export const enCatalogue = {
    "Store has no products.": "Store has no products.",
    "results": "results",
    "of": "of",
    "Catalogues": "Catalogues",
    "Total Products": "Total Products",
    "Catalogus": "Catalogues",
    "Catalogue": "Catalogue",
    "Product Sourcing": "Product Sourcing",
    "Styling Services": "Styling Services",
    "Your Bid Request has been created Successfully.": "Your Bid Request has been created Successfully.",
    "* Please enter a quote price": "* Please enter a quote price",
    "* Please enter your product description": "* Please enter your product description",
    "* Please select at least one attachment": "* Please upload at least one attachment",
    "has an invalid file type.": "has an invalid file type.",
    "exceeds the 10MB size limit.": "exceeds the 10MB size limit.",
    "You can only upload up to 5 files": "You can only upload up to 5 files",
    "* Quote price must be less than maximum price": "* Quote price must be less than maximum price",
    "* Quote price must be greater than minimum price": "* Quote price must be greater than minimum price",
    "No requests available": "No requests available",
    "View": "View",
    "My Bids": "My Bids",
    "Product Sourcing Requests": "Product Sourcing Requests",
    "Sizes": "Sizes",
    "Quantity": "Quantity",
    "Units": "Units",
    "Colours": "Colours",
    "Gender": "Gender",
    "Bid Your Quote": "Bid Your Quote",
    "Product Description": "Product Description",
    "Write product description here": "Write product description here",
    "Quote Price": "Quote Price",
    "Attachments": "Attachments",
    "Upload png, jpg, jpeg": "Upload png, jpg, jpeg",
    "You can attach up to 5 images.": "You can attach up to 5 images.",
    "Submit": "Submit",
    "No Bids Available": "No Bids Available",
    "Quoted Price": "Quoted Price",
    "accepted": "Accepted",
    "rejected": "Rejected",
    "waiting": "Waiting",
    "Please Select Bid Request to See Data": "Please Select Bid Request to See Data",
    "Your Bid Request has been updated Successfully.": "Your Bid Request has been updated Successfully.",
    "My Bid": "My Bid",
    "Edit Bid": "Edit Bid",
    "Delete": "Delete",
    "Chat with Customer": "Chat with Customer",
    "Delete Your Bid": "Delete Your Bid",
    "Your bid will be deleted permanently.": "Your bid will be deleted permanently.",
    "Cancel": "Cancel",
    "Bid deleted successfully": "Bid deleted successfully",
    "Add Products": "Add Products",
    "Search": "Search",
    "Add New Products": "Add New Products",
    "Loading": "Loading",
    "You have seen all the records.": "You have seen all the records.",
    "Manually": "Manually",
    "CSV Excel Sheet": "CSV Excel Sheet",
    "API Integration": "API Integration",
    "Confirm": "Confirm",
    "Back": "Back",
    "Upload Products": "Upload Products",
    "Only CSV file accepted": "Only CSV file accepted",
    "Product Details": "Product Details",
    "View Analytics": "View Analytics",
    "Edit Details": "Edit Details",
    "Color": "Color",
    "Size": "Size",
    "Product Name": "Product Name",
    "View Details": "View Details",
    "Product description": "Product description",
    "Size & Fit": "Size & Fit",
    "Fit": "Fit",
    "Product care & Material": "Product care & Material",
    "Material": "Material",
    "Expected Delivery & Total Cost": "Expected Delivery & Total Cost",
    "Delivery by": "Delivery by",
    "Product Price": "Product Price",
    "Approx. Delivery Cost": "Approx. Delivery Cost",
    "Total Cost" : "Total Cost",
    "Store Information": "Store Information",
    "Stylist Information": "Stylist Information",
    "Edit Products": "Edit Products",
    "Something went wrong.": "Something went wrong.",
    "Please enter a valid Product Name.": "Please enter a valid Product Name.",
    "Product name already exists": "Product name already exists",
    "Please enter a valid Gender":"Please enter a valid Gender",
    "Please enter a valid Brand":"Please enter a valid Brand",
    "Please enter a valid Category":"Please enter a valid Category",
    "Please enter a valid Sub Category":"Please enter a valid Sub Category",
    "Please enter a valid Sub-Sub category":"Please enter a valid Sub-Sub category",
    "Please enter a valid Material":"Please enter a valid Material",
    "Please enter a valid Fit":"Please enter a valid Fit",
    "Please enter valid Product Care":"Please enter valid Product Care",
    "Please enter a valid Description":"Please enter a valid Description",
    "Please enter a valid material":"Please enter a valid material",
    "Please enter a valid fit":"Please enter a valid fit",
    "Please enter a valid productCare":"Please enter a valid productCare",
    "Please fill SKU":"Please fill SKU",
    "Please fill Quantity":"Please fill Quantity",
    "Quantity must be a number":"Quantity must be a number",
    "Please fill Price":"Please fill Price",
    "Price must be a number":"Price must be a number",
    "Please select at least one store":"Please select at least one store",
    "Coming Soon !!!":"Coming Soon !!!",
    "SKU already exists":"SKU already exists",
    "Some errors occurred. Please fix them before updating the variant.":"Some errors occurred. Please fix them before updating the variant.",
    "Price should be greater than 0":"Price should be greater than 0",
    "SKU is already taken":"SKU is already taken",
    "N/A":"N/A",
    "Variant (Size)":"Variant (Size)",
    "Select Size":"Select Size",
    "Size is required.":"Size is required.",
    "Variant (Color)":"Variant (Color)",
    "Separate values with commas":"Separate values with commas",
    "Select Color":"Select Color",
    "Color is required.":"Color is required.",
    "A max of 30 variants can be created":"A max of 30 variants can be created",
    "Update Variant":"Update Variant",
    "Create Variant":"Create Variant",
    "You can create a max of 30 variants of the product":"You can create a max of 30 variants of the product",
    "Variant Details":"Variant Details",
    "Variant":"Variant",
    "Price":"Price",
    "SKU":"SKU",
    "Male":"Male",
    "Female":"Female",
    "Other":"Other",
    "Select Brand":"Select Brand",
    "Brand":"Brand",
    "Select Category":"Select Category",
    "Select Sub-category":"Select Sub-category",
    "Select Sub-Sub-category":"Select Sub-Sub-category",
    "Product Care":"Product Care",
    "List the products":"List the products",
    "Listed":"Listed",
    "Unlisted":"Unlisted",
    "Select All Stores":"Select All Stores",
    "Upload Image":"Upload Image",
    "Only png or Jpg files are accepted":"Only png or Jpg files are accepted",
    "Upload Photo":"Upload Photo",
    "Please select an image for each variant.":"Please select an image for each variant.",
    "Side View":"Side View",
    "Back View":"Back View",
    "Front View":"Front View",
    "Create Variants": "Create Variants",
    "Update Variants": "Update Variants",
    "Upload Images": "Upload Images",
    "Assign Stores": "Assign Stores",
    "Next": "Next",
    "Product listed successfully!": "Product listed successfully!",
    "This SKU is already taken": "This SKU is already taken",
    "Price must be greater than 0": "Price must be greater than 0",
    "Save Details": "Save Details",
    "Edit Product": "Edit Product",
    "Product Edited successfully!": "Product Edited successfully!",
    "No Products added here": "No Products added here"
}

export const arCatalogue = {
    "Store has no products.": "المتجر ليس لديه منتجات.",
    "results": "نتيجة",
    "of": "من أصل",
    "Catalogues": "الكتالوجات",
    "Total Products": "إجمالي المنتجات",
    "Catalogus": "قوائم المعروضات",
    "Catalogue": "تورید المنتجات",
    "Product Sourcing": "قائمة المعروضات",
    "Styling Services": "خدمات التصميم",
    "Your Bid Request has been created Successfully.":"لقد تم إنشاء طلب العرض الخاص بك بنجاح.",
    "* Please enter a quote price":"* الرجاء إدخال سعر الاقتباس",
    "* Please enter your product description":"* الرجاء إدخال وصف المنتج الخاص بك",
    "* Please select at least one attachment":"* يرجى تحميل مرفق واحد على الأقل",
    "has an invalid file type.":"يحتوي على نوع ملف غير صالح.",
    "exceeds the 10MB size limit.":"يتجاوز الحد الأقصى للحجم وهو 10 ميغابايت.",
    "You can only upload up to 5 files":"يمكنك تحميل ما يصل إلى 5 ملفات فقط",
    "* Quote price must be less than maximum price":"* يجب أن يكون سعر العرض أقل من الحد الأقصى للسعر",
    "* Quote price must be greater than minimum price":"* يجب أن يكون سعر العرض أكبر من الحد الأدنى للسعر",
    "No requests available":"لا توجد طلبات متاحة",
    "View":"العرض",
    "My Bids":"عروضي",
    "Product Sourcing Requests":"طلبات تورید المنتجات",
    "Sizes":"المقاسات",
    "Quantity":"الكمیة",
    "Units":"الوحدات",
    "Colours":"الألوان",
    "Gender":"الجنس",
    "Bid Your Quote":"تقديم عرض الأسعار الخاص بك",
    "Product Description":"وصف المنتج",
    "Write product description here":"اكتب وصف المنتج ھنا",
    "Quote Price":"السعر المقدم",
    "Attachments":"الملفات الملحقة",
    "Upload png, jpg, jpeg":"رفع ملف png, jpg, jpeg",
    "You can attach up to 5 images.":"يمكنك إرفاق ما يصل إلى 5 صور.",
    "Submit":"إرسال",
    "No Bids Available": "لا توجد عروض متاحة",
    "Quoted Price": "السعر المقتبس",
    "accepted": "تم القبول",
    "rejected": "مرفوض",
    "waiting": "في الانتظار",
    "Please Select Bid Request to See Data": "الرجاء تحديد طلب عرض الأسعار لرؤية البيانات",
    "Your Bid Request has been updated Successfully.": "لقد تم تحديث طلب عرض الأسعار الخاص بك بنجاح.",
    "My Bid": "عرضي",
    "Edit Bid": "تعديل العرض",
    "Delete": "الحذف",
    "Chat with Customer": "الدردشة مع العمیل",
    "Delete Your Bid": "احذف عرضك",
    "Your bid will be deleted permanently.": "سيتم حذف عرضك نهائيًا.",
    "Cancel": "الإلغاء",
    "Bid deleted successfully": "تم حذف العطاء بنجاح",
    "Add Products": "إضافة منتجات",
    "Search": "البحث",
    "Add New Products": "إضافة منتجات جديدة",
    "Loading": "تحميل",
    "You have seen all the records.": "لقد رأيت كل السجلات.",
    "Manually": "بشكل يدوي",
    "CSV Excel Sheet": "ملف إكسل بصيغة CSV",
    "API Integration": "تكامل واجهة برمجة التطبيقات",
    "Confirm": "التأكيد",
    "Back": "الرجوع",
    "Upload Products": "رفع المنتجات",
    "Only CSV file accepted": "يتم قبول ملفات png وjpg فقط",
    "Product Details": "تفاصيل المنتج",
    "View Analytics": "عرض التحليلات",
    "Edit Details": "تعديل البيانات",
    "Color": "اللون",
    "Size": "المقاس",
    "Product Name":"اسم المنتج",
    "View Details": "عرض التفاصيل",
    "Product description": "وصف المنتج",
    "Size & Fit": "الحجم والملاءمة:",
    "Fit": "الملاءمة الجسدية",
    "Product care & Material": "العناية بالمنتج والمواد",
    "Material": "الخامة",
    "Expected Delivery & Total Cost": "وقت التسليم المتوقع والتكلفة الكلية",
    "Delivery by": "يسلم بحلول",
    "Product Price": "سعر المنتج",
    "Approx. Delivery Cost": "تكلفة التسليم التقريبية",
    "Total Cost" : "التكلفة الكلية",
    "Store Information": "معلومات المتجر",
    "Stylist Information": "بیانات المصمم",
    "Edit Products": "تعديل المنتجات",
    "Something went wrong.": "حدث خطأ ما.",
    "Please enter a valid Product Name.": "الرجاء إدخال اسم منتج صالح.",
    "Product name already exists": "اسم المنتج موجود بالفعل",
    "Please enter a valid Gender":"الرجاء إدخال جنس صالح",
    "Please enter a valid Brand":"الرجاء إدخال علامة تجارية صالحة",
    "Please enter a valid Category":"الرجاء إدخال فئة صالحة",
    "Please enter a valid Sub Category":"الرجاء إدخال فئة فرعية صالحة",
    "Please enter a valid Sub-Sub category":"الرجاء إدخال فئة فرعية صالحة",
    "Please enter a valid Material":"الرجاء إدخال مادة صالحة",
    "Please enter a valid Fit":"الرجاء إدخال صالح صالح",
    "Please enter valid Product Care":"الرجاء إدخال رعاية المنتج صالحة",
    "Please enter a valid Description":"الرجاء إدخال وصف صالح",
    "Please enter a valid material":"الرجاء إدخال مادة صالحة",
    "Please enter a valid fit":"الرجاء إدخال تناسب صالح",
    "Please enter a valid productCare":"الرجاء إدخال منتج صالح",
    "Please fill SKU":"يرجى ملء SKU",
    "Please fill Quantity":"يرجى ملء الكمية",
    "Quantity must be a number":"يجب أن تكون الكمية رقمًا",
    "Please fill Price":"يرجى ملء السعر",
    "Price must be a number":"يجب أن يكون السعر رقمًا",
    "Please select at least one store":"يرجى اختيار متجر واحد على الأقل",
    "Coming Soon !!!":"قريباً !!!",
    "SKU already exists":"SKU موجود بالفعل",
    "Some errors occurred. Please fix them before updating the variant.":"حدثت بعض الأخطاء. يرجى إصلاحها قبل تحديث المتغير.",
    "Price should be greater than 0":"يجب أن يكون السعر أكبر من 0",
    "SKU is already taken":"تم أخذ SKU بالفعل",
    "N/A":"لا يوجد",
    "Variant (Size)":"حجم الفصيل",
    "Select Size":"حدد الحجم",
    "Size is required.":"الحجم مطلوب.",
    "Variant (Color)":"لون الفصيل",
    "Separate values with commas": "إفصل القيم بفواصل",
    "Select Color":"حدد اللون",
    "Color is required.":"اللون مطلوب.",
    "A max of 30 variants can be created":"يمكن إنشاء 30 متغيرًا كحد أقصى",
    "Update Variant":"تحديث البديل",
    "Create Variant":"إنشاء البديل",
    "You can create a max of 30 variants of the product":"يمكنك إنشاء 30 نوعًا مختلفًا للمنتج كحد أقصى",
    "Variant Details":"تفاصيل الفصيل",
    "Variant":"الفصيل",
    "Price":"السعر",
    "SKU":"رمز التخزين التعريفي",
    "Male":"ذكر",
    "Female":"أنثى",
    "Other":"آخر",
    "Select Brand":"حدد العلامة التجارية",
    "Brand":"ماركة",
    "Select Category":"حدد الفئة",
    "Select Sub-category":"حدد الفئة الفرعية",
    "Select Sub-Sub-category":"حدد الفئة الفرعية",
    "Product Care":"العناية بالمنتج",
    "List the products":"إدراج المنتجات",
    "Listed":"أدرجها",
    "Unlisted":"لا تدرجها",
    "Select All Stores":"حدد كافة المتاجر",
    "Upload Image":"تحميل الصورة",
    "Only png or Jpg files are accepted":"يتم قبول ملفات png أو Jpg فقط",
    "Upload Photo":"تحميل الصورة",
    "Please select an image for each variant.":"الرجاء اختيار صورة لكل متغير.",
    "Side View":"المنظور الجانبي",
    "Back View":"ألمنظور الخلفي",
    "Front View":"المنظور الأمامي",
    "Create Variants": "إنشاء الفصائل",
    "Update Variants": "تحديث الفصائل",
    "Upload Images": "رفع الصور",
    "Assign Stores": "تعيين متاجر",
    "Next": "التالي",
    "Product listed successfully!": "تم إدراج المنتج بنجاح!",
    "This SKU is already taken": "تم أخذ SKU هذا بالفعل",
    "Price must be greater than 0": "يجب أن يكون السعر أكبر من 0",
    "Save Details": "حفظ التفاصيل",
    "Edit Product": "تعديل المنتجات",
    "Product Edited successfully!": "تم تعديل المنتج بنجاح!",
    "No Products added here": "لم تتم إضافة أي منتجات هنا"
}