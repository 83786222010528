Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';
exports.getClientChatsEndPoint = "bx_block_chat/chat_list"
exports.getPersonalUserApiEndPoint = "/account_block/accounts/get_personal_detail";

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.chatDataEndPoint = 'bx_block_chat/chats/';
exports.catalogues_variants_sizes = "bx_block_catalogue/catalogues_variants_sizes";
exports.catalogues_variants_colors = "bx_block_catalogue/catalogues_variants_colors";
exports.addNewOrderRequestEndPoint = "bx_block_chat/create_order_request_message";
exports.addNewPaymentRequestEndPoint = "bx_block_custom_form/stylist_payment_requests";
exports.URL = "ws://https://localhost:3000/cable"
exports.chatListEndPoint = "bx_block_chat/chats"
exports.chatEndPoint = "bx_block_chat/chats"
exports.previewTitle = "Preview"
exports.blockUserEndPoint= "bx_block_chat/chats";
exports.paymentTitile= "Payment";
exports.reportPerson = "Report Person"
  // Customizable Area End