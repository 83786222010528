import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Grid, styled, Box, Typography, Card ,CircularProgress,Snackbar} from "@material-ui/core";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Alert from '@material-ui/lab/Alert';
import StylingClientWishlistController, {
  Props,
} from "./StylingClientWishlistController";
import { ThemeProvider } from "react-native-elements";
import {
  backBtnIcon,
} from "../../assets";
import StylishSidebar from "../../../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../../../components/src/Stylish/StylishHeader";
import { noImage } from "../../../../landingpage/src/assets";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylingClientWishlist extends StylingClientWishlistController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid
            item
            xl={2}
            md={2}
            id="stylish-client-wl-sidebar-catalogue"
            lg={2}
            sm={2}
            xs={2}
          >
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            md={10}
            id="stylish-client-wl-header-catalogue"
            lg={10}
            sm={10}
            xs={10}
          >
            <StylishHeader navigation={this.props.navigation} />
            <StylishClientContainer>
              <StylishClientTitleContainer>
                <img
                  src={backBtnIcon}
                  data-test-id="back-btn-test-id"
                  alt="backIcon"
                  onClick={() =>
                    this.handleStylishClientWLRedirect("StylishMyClients")
                  }
                />
                <Typography className="main-title">Ralph’s Wishlist</Typography>
              </StylishClientTitleContainer>
              <Grid container spacing={4}>
                {this.state.isLoder ?
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                    <CircularProgress style={{ color: '#375280'}}/>
                  </Box>
                  : this.state.favouriteList?.map((cardDetail, index) => (
                    <Grid item key={index}>
                      <CardContainer data-test-id="cartDetails" onClick={() => this.navigateTo({ productId: cardDetail.attributes.favouriteable.data.id, props: this.props, screenName: "Productdescription3" })}>
                        <Box style={{ position: "relative" }}>
                          <img
                            src={
                              cardDetail.attributes.favouriteable.data.attributes.primary_image === null
                                ? noImage
                                : cardDetail.attributes.favouriteable.data.attributes.primary_image
                            }
                            alt={`Image ${index + 1}`}
                            className="img-container"
                            data-test-id="navigateToDetail"
                          />
                          <Box className="product-icon">
                            <Box className={`product-discount ${cardDetail.attributes.favouriteable.data.attributes.discount ? "product-discount-padding" : ""}`}>
                              {cardDetail.attributes.favouriteable.data.attributes.discount &&
                                `${cardDetail.attributes.favouriteable.data.attributes.discount}% off`}
                            </Box>
                            <Box
                              className="product-favorite"
                              data-test-id="handleLikeClick"
                              onClick={(event)=>this.wislistDesLick(cardDetail.attributes.favouriteable.data.id,event)}
                              style={{cursor:'pointer'}}
                            >
                              {cardDetail.attributes.favouriteable.data.attributes.is_wishlist === false ? (
                                <FavoriteIcon
                                  width="18px"
                                  height="18px"
                                  style={{ fill: "#375280" }}
                                />
                              ) : (
                                <FavoriteBorderIcon
                                  color="secondary"
                                  style={{ fill: "#375280" }}
                                />
                              )}
                            </Box>
                          </Box>
                        </Box>
                        <Box className="card-below-container">
                          <Box className="product-data">
                            <Box className="product-value">
                              <Typography className="product-name">
                                {cardDetail.attributes.favouriteable.data.attributes.brand_name}
                              </Typography>
                              <Typography className="product-description">
                                {cardDetail.attributes.favouriteable.data.attributes.description}
                              </Typography>
                            </Box>
                            <Box className="account-mrp">
                              ${cardDetail.attributes.favouriteable.data.attributes.primary_price}
                            </Box>
                          </Box>
                        </Box>
                      </CardContainer>
                    </Grid>
                  ))}
                <CardContainer onClick={()=>this.handleStylishGenderLRedirect("CategoriessubcategoriesWebPage","Men")} data-test-id="handleStylishGenderLRedirect" style={{ height: '370px', backgroundColor: '#F8F8F8', marginTop: '16px', margin: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box >
                      <Box style={{ display: 'flex', justifyContent: "center" }}>
                        <Box style={webStyles.cardIcon}>
                          <AddRoundedIcon style={{alignItems: 'center', ...webStyles.iconBox}} />
                        </Box>
                      </Box>
                      <Box>
                        <Typography style={webStyles.addProduct}>Add Products</Typography>
                      </Box>
                    </Box>
                </CardContainer>
              </Grid>
            </StylishClientContainer>
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.isAlert}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={3000}
          style={{ marginTop: '10px' }}
          data-testid="oncloseAlert"
          onClose={this.oncloseAlert}
        ><Alert variant="filled" severity={this.state.alertType}>{this.state.already}</Alert></Snackbar>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyles = {
  cardIcon:{ backgroundColor: '#ffff', boxShadow: "0px 2px 8px 0px #00000014", width: '50px', height: '50px', borderRadius: '30px' },
  iconBox:{color: "#375280", display: 'flex', justifyContent: "center", paddingTop: '10px', fontSize: "30px", width: '100%'},
  addProduct:{ color: '#375280', fontSize: '18px', fontFamily: 'Lato', fontWeight: 400, marginTop: '20px' }
}
export const StylishClientContainer = styled(Box)({
  padding: "36px 30px",
  minHeight: "calc(100vh - 85px)",
});

export const StylishClientTitleContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 20,
  paddingBottom: 24,
  "& .main-title": {
    fontFamily: "Lato, sans-serif",
    color: "#375280",
    fontWeight: 700,
    fontSize: 24,
    width: "100%",
  },
});

const CardContainer = styled(Card)({
  boxShadow: "none",
  borderRadius: "0px 0px 2px 2px",
  width: "290px",
  backgroundColor: "#F8F8F8",
  "& .img-container": {
    width: "100%",
    height: "370px",
    objectFit: "cover",
    position: "relative",
    display: "block",
    margin: "auto",
  },
  "& .product-icon": {
    top: "20px",
    left: "20px",
    display: "flex",
    justifyContent: "space-between",
    right: "0",
    position: "absolute",
    "& .product-discount": {
      color: "#375280",
      fontSize: "12px",
      backgroundColor: "#FFFFFF",
      borderRadius: "2px",
      height: "17px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Lato",
      fontWeight: 700,
    },
    "& .product-discount-padding":{
      padding: "2px 6px",
    },
    "& .product-favorite": {
      color: "#375280",
      marginRight: "30px",
      fontSize: "12px",
      backgroundColor: "#FFFFFF",
      padding: "5px",
      borderRadius: "20px",
      display: "flex",
      alignContent: "center",
    },
  },
  "& .card-below-container": {
    padding: "24px",
    backgroundColor: "#F8F8F8",
    "& .product-data": {
      width: "100%",
      display: "flex",
      margin: "0",
      padding: "0",
      height: "50px",
      whiteSpace: "nowrap",
      flexDirection: "row",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flex: "1 1 33%",
      alignItems: "center",
      "& .product-value": {
        whiteSpace: "nowrap",
        height: "50px",
        lineHeight: "50px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: "1 2 auto",
        "& .product-description": {
          whiteSpace: "nowrap",
          color: "#375280",
          fontSize: "14px",
          lineHeight: "16.8px",
          fontWeight: 500,
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "90%",
          fontFamily: "Lato",
        },
        "& .product-name": {
          whiteSpace: "nowrap",
          color: "#375280",
          fontSize: "16px",
          fontWeight: 700,
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "90%",
          fontFamily: "Lato",
        },
      },
      "& .account-mrp": {
        fontFamily: "Lato",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#375280",
        fontSize: "14px",
        fontWeight: 700,
        backgroundColor: "#FFFFFF",
        padding: "0px 10.5px",
        borderRadius: "8px",
        textAlign: "center",
        height: 45,
      },
    },
  },
});
// Customizable Area End
