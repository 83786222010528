import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { MetaResponse } from "../../Catalogues/SellerCataloguesController";
import {
  CatalogueItems,
  ExtractedInventoryItem,
  IInventoryPaginationResponse,
  InventoryState,
} from "../UpdateInventory/UpdateInventoryController";
import { ErrorMessage } from "../../../CreateSellerStoreController";
import { ErrorMessageResponse } from "../../../SellerStoreController";
import { getStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../../../../components/src/APICall";
import { logoutSellerNavigation } from "../../../../../../components/src/Seller/logOut";
import i18n from "../../../../../../components/src/i18next/i18n";

// Customizable Area End

export const configJSON = require("../../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  filterValue: {
    stock_status: string;
    listing?: boolean;
  };
  searchValue: string;
  handleUpdatePriceStore: (updatedData: CatalogueItems[]) => void;
  confirmInventoryData: () => void;
  removeInventoryData: boolean;
  handleRedirect: (redirect: string) => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tableHeadName: string[];
  responseSetInventoryData: InventoryState[];
  responseBodyData: ExtractedInventoryItem[];
  tableBodyData: ExtractedInventoryItem[];
  updatedCataloguesPrice: CatalogueItems[];
  isLoading: boolean;
  warningOpen: boolean;
  setPriceInventoryMeta: MetaResponse;
  page: number;
  isAlert: boolean;
  roleData: string;
  message: string;
  severity: "success" | "error";
  newPage: number;
  update: string;

  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerUpdateInventoryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFilterListInventoryItemAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tableHeadName: inventorySetPriceTableHead,
      roleData: "",
      responseSetInventoryData: [],
      responseBodyData: [],
      tableBodyData: [],
      severity: "success",
      warningOpen: false,
      updatedCataloguesPrice: [],
      setPriceInventoryMeta: {} as MetaResponse,
      page: 1,
      newPage: 1,
      isAlert: false,
      message: "",
      isLoading: true,
      update: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (
        responseJsonData &&
        !responseJsonData.errors &&
        !responseJsonData.error
      ) {
        this.apiUpdateSetPriceSuccess(apiRequestCallId, responseJsonData);
      } else if (
        responseJsonData &&
        (responseJsonData.error || responseJsonData.errors)
      ) {
        this.apiUpdateSetPriceFailer(responseJsonData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    if(userData.userType==="buyer") history.back();
    else this.setState((prevState)=>({...prevState, roleData: userData.userType}),()=>this.fetchInventoryPriceData(
      this.props.searchValue,
      this.props.filterValue,
      1
    )
  );
    
    // Customizable Area End
  }

  apiUpdateSetPriceSuccess = async (
    apiRequestCallId: string,
    responseJson: IInventoryPaginationResponse
  ) => {
    if (apiRequestCallId === this.getFilterListInventoryItemAPICallId) {
      if (responseJson) this.handleUpdateInventoryPriceResp(responseJson);
    }
  };

  handleUpdateInventoryPriceResp = (response: IInventoryPaginationResponse) => {
    this.setState((prevState) => ({
      ...prevState,
      responseBodyData: this.extractInventoryPriceData(response.data),
      tableBodyData: this.extractInventoryPriceData(response.data),
      responseInventoryData: response.data,
      isLoading: false,
      setPriceInventoryMeta: response.meta,
    }));
  };

  apiUpdateSetPriceFailer = (
    responseJsonError: ErrorMessage & ErrorMessageResponse
  ) => {
    if(responseJsonError.error === "You are not an authorized person, Only seller and Stylist can filter variants.")
      {
        this.handleRedirectToHome(this.tranSetPrice("You are not an authorized person, Please Login with valid User Credential"));
      } else if (responseJsonError.error) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseJsonError.error,
        responseBodyData: [],
        isLoading: false,
        tableBodyData: [],
        setPriceInventoryMeta: {} as MetaResponse,
      }));
    } else if (responseJsonError.errors[0].token) {
     this.handleRedirectToHome(responseJsonError.errors[0].token)
    } else if (responseJsonError.errors) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseJsonError.errors as string,
        isLoading: false,
        responseBodyData: [],
        tableBodyData: [],
        setPriceInventoryMeta: {} as MetaResponse,
      }));
    }
  };

  handleRedirectToHome = (message: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        isAlert: true,
        message: message,
        isLoading: false,
        severity: "error",
      }),
      () => {
        setTimeout(() => {
          this.props.handleRedirect("Home");
          logoutSellerNavigation();
        }, 2000);
      }
    );
  }

  handlePriceChange = (id: number, value: string) => {
    const price = this.handlePriceCheckNumber(value, id);
    this.setState(
      (prevState) => ({
        tableBodyData: prevState.tableBodyData.map((item) =>
          item.id === id
            ? {
                ...item,
                price: price.toString(),
                discounted_price: (+price * (1 - (+item.discounted_percentage/100))).toFixed(2),
              }
            : item
        ),
      }),
      () => {
        this.checkAndStoreDifferentInventory(id);
      }
    );
  };

  handleDiscountedPercentageChange = (id: number, value: string) => {
    const discountedPer = this.handleCheckNumber(value);
    this.setState(
      (prevState) => ({
        tableBodyData: prevState.tableBodyData.map((item) =>
          item.id === id
            ? { ...item, discounted_percentage: discountedPer.toString(), discounted_price: (+item.price * (1 - (+discountedPer/100))).toFixed(2) }
            : item
        ),
      }),
      () => {
        this.checkAndStoreDifferentInventory(id);
      }
    );
  };

  handleDiscountedPriceChange = (id: number, value: string) => {
    const discountedPrice = this.handleCheckNumber(value);
    this.setState(
      (prevState) => ({
        tableBodyData: prevState.tableBodyData.map((item) =>
          item.id === id
            ? { ...item, discounted_price: discountedPrice.toString() }
            : item
        ),
      }),
      () => {
        this.checkAndStoreDifferentInventory(id);
      }
    );
  };

  checkAndStoreDifferentInventory = (id: number) => {
    const tableInventoryItem = this.state.tableBodyData.find(
      (item) => item.id === id
    );
    const responseInventoryItem = this.state.responseBodyData.find(
      (item) => item.id === id
    );

    if (tableInventoryItem && responseInventoryItem) {
      const itemsAreDifferent =
        tableInventoryItem.stock_qty !== responseInventoryItem.stock_qty ||
        tableInventoryItem.low_stock_threshold !==
          responseInventoryItem.low_stock_threshold ||
        tableInventoryItem.is_listed !== responseInventoryItem.is_listed ||
        tableInventoryItem.price !== responseInventoryItem.price || 
        tableInventoryItem.discounted_percentage !== responseInventoryItem.discounted_percentage;

      if (itemsAreDifferent) {
        const updatedItem: CatalogueItems = {
          id: tableInventoryItem.id,
          stock_qty: tableInventoryItem.stock_qty,
          low_stock_threshold: tableInventoryItem.low_stock_threshold,
          is_listed: tableInventoryItem.is_listed,
          price: tableInventoryItem.price,
          discounted_percentage: tableInventoryItem.discounted_percentage,
          discounted_price: tableInventoryItem.discounted_price
        };

        this.setState(
          (prevState) => {
            const existingCatalogueItemIndex =
              prevState.updatedCataloguesPrice.findIndex(
                (item) => item.id === id
              );
            let updatedCataloguesPrice = [...prevState.updatedCataloguesPrice];

            if (existingCatalogueItemIndex !== -1) {
              updatedCataloguesPrice[existingCatalogueItemIndex] = updatedItem;
            } else {
              updatedCataloguesPrice.push(updatedItem);
            }

            return { updatedCataloguesPrice: updatedCataloguesPrice };
          },
          () =>
            this.props.handleUpdatePriceStore(this.state.updatedCataloguesPrice)
        );
      } else {
        this.setState(
          (prevState) => ({
            updatedCataloguesPrice: prevState.updatedCataloguesPrice.filter(
              (item) => item.id !== id
            ),
          }),
          () =>
            this.props.handleUpdatePriceStore(this.state.updatedCataloguesPrice)
        );
      }
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.removeInventoryData !== this.props.removeInventoryData) {
      this.setState((prevState) => ({
        ...prevState,
        updatedCataloguesPrice: [],
        responseBodyData: prevState.tableBodyData,
      }));
    }
    if (
      prevProps.filterValue !== this.props.filterValue ||
      prevProps.searchValue !== this.props.searchValue
    ) {
      this.state.updatedCataloguesPrice.length === 0
        ? this.fetchInventoryPriceData(
            this.props.searchValue,
            this.props.filterValue,
            1
          )
        : this.setState((prevState) => ({
            ...prevState,
            update: "search-filter",
            warningOpen: !prevState.warningOpen,
          }));
    }
  }

  handleCheckNumber = (value: string) => {
    const numericValue = value.replace(/\D/g, "");
    return numericValue ? parseInt(numericValue, 10) : 0;
  };

  handleInputBlur = (id: number) => {
    this.setState(
      (prevState) => {
        const item = prevState.tableBodyData.find((item) => item.id === id);
        const respItem = prevState.responseBodyData.find(
          (item) => item.id === id
        );

        if (item) {
          const currentPrice = item.price;
          const respPrice = respItem ? respItem.price : "";

          const validatedPrice = this.validatePrice(currentPrice, respPrice);
          const finalPrice = this.handlePriceCheckNumber(validatedPrice, id);

          this.updateTableData(id, finalPrice);
        }

        return null;
      },
      () => {
        this.checkAndStoreDifferentInventory(id);
      }
    );
  };

  validatePrice(currentPrice: string, respPrice: string): string {
    if (currentPrice.includes(".")) {
      const [beforeDecimal, afterDecimal] = currentPrice.split(".");

      if (
        beforeDecimal === "" ||
        currentPrice === `.${afterDecimal}` ||
        currentPrice === "0.00" ||
        currentPrice === "0.0"
      ) {
        return respPrice;
      }

      if (afterDecimal === "") {
        return respPrice;
      }
    }

    return currentPrice === "" ? respPrice : currentPrice;
  }

  updateTableData(id: number, finalPrice: string) {
    this.setState((prevState) => ({
      tableBodyData: prevState.tableBodyData.map((item) =>
        item.id === id
          ? {
              ...item,
              price: finalPrice,
              discounted_price: (+finalPrice * (1 - (+item.discounted_percentage/100))).toFixed(2),
            }
          : item
      ),
    }));
  }

  handlePriceCheckNumber = (value: string, id: number) => {
    let numericValue = value.replace(/[^0-9.]/g, "");

    if (numericValue.includes(".")) {
      const [integerPart, decimalPart] = numericValue.split(".");
      numericValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
    }

    if (numericValue === "0") {
      return "";
    }

    return numericValue;
  };

  handleUpdatePriceInventoryPageChange = async (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    event.preventDefault();
    if (this.state.updatedCataloguesPrice.length !== 0) {
      this.setState((prevState) => ({
        ...prevState,
        warningOpen: !prevState.warningOpen,
        update: prevState.update === "" ? "page" : prevState.update,
        newPage: newPage,
      }));
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      newPage,
      page: newPage,
      update: "",
    }));

    await this.fetchInventoryPriceData(
      this.props.searchValue,
      this.props.filterValue,
      newPage
    );
  };

  fetchInventoryPriceData = async (
    search: string,
    filter: {
      stock_status: string;
      listing?: boolean;
    },
    page: number
  ) => {
    this.setState((prevState) => ({ ...prevState, isLoading: true, page }));
    
    let endPointPrice = `${configJSON.getFilterVariantInventoryEndPoint}stock_status=${filter.stock_status}&search=${search}&per_page=10&page=${page}`;

    if (filter.listing !== undefined) {
      endPointPrice += `&listing=${filter.listing}`;
    }

    if (this.state.roleData==="seller"){const storeId = +(await this.getStoreIdPrice()); endPointPrice += `&store_id=${storeId}`; }

    this.getFilterListInventoryItemAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: endPointPrice,
      method: configJSON.httpGetMethod,
      token: await this.getTokenDataPriceStorage(),
    });
  };

  getTokenDataPriceStorage = async () => {
    return await getStorageData("auth-token");
  };

  getStoreIdPrice = async () => {
    return await getStorageData("store-id");
  };

  extractInventoryPriceData = (
    items: InventoryState[]
  ): ExtractedInventoryItem[] => {
    return items.map((item) => {
      const {
        id,
        product_name,
        is_listed,
        front_image,
        sku,
        price,
        low_stock_threshold,
        stock_qty,
        pair_it_with,
        discounted_percentage,
      } = item.attributes;
      const percentage = discounted_percentage === null ? "0" : discounted_percentage.substring(0,2);

      return {
        id,
        product_name,
        front_image,
        sku,
        is_listed,
        low_stock_threshold,
        stock_qty,
        price: price,
        pair_it_with,
        discounted_percentage: percentage.replace(".",""),
        discounted_price: (+price * (1 - (+percentage/100))).toFixed(2),
      };
    });
  };

  handleConfirm = async () => {
    await this.handleConfirmCancel();
    this.props.confirmInventoryData();
    this.setState((prevState) => ({ ...prevState, isLoading: true }));
  };

  handleCancel = async () => {
    await this.handleConfirmCancel();
    this.setState((prevState) => ({
      ...prevState,
      updatedCataloguesPrice: [],
    }));
    this.props.handleUpdatePriceStore([]);
  };

  handleConfirmCancel = async () => {
    if (this.state.update === "search-filter") {
      await this.fetchInventoryPriceData(
        this.props.searchValue,
        this.props.filterValue,
        this.state.page
      );
    } else if (this.state.update === "page") {
      await this.fetchInventoryPriceData(
        this.props.searchValue,
        this.props.filterValue,
        this.state.newPage
      );
      this.setState((prevState) => ({ ...prevState, page: prevState.newPage }));
    }
    this.setState((prevState) => ({
      ...prevState,
      update: "",
      warningOpen: !prevState.warningOpen,
    }));
  };

  onAlertSPSnackClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: !prevState.isAlert,
    }));
  };

  tranSetPrice = (transKey: string) => {
    return i18n.t(transKey, {ns: "inventoryManagement"});
  }
  // Customizable Area End
}
// Customizable Area Start
const inventorySetPriceTableHead = [
  "Product Name",
  "SKU",
  "Price",
  "Discounted Percentage",
  "Discounted Price",
];
// Customizable Area End
