import {enHeader, arHeader} from './Headerlang'
import { enSidebar, arSidebar } from "./siderbarLang";
import {enFooter, arFooter} from './footerLang'
import { enLoginSignup, arLoginSignup } from "../../../blocks/utilities/src/Language/loginSignupLang"
import { enProdDesc, arProdDesc} from "./productDescLang"
import { enCategoryFilter, arCategoryFilter } from "../../../blocks/utilities/src/Language/CategoriesFilterLang"
import { enCart, arCart } from "../../../blocks/utilities/src/Language/ShoppingCartLang"
import { enOrderManagement, arOrderManagement } from "../../../blocks/utilities/src/Language/OrderManagement"
import { enOrderDetail, arOrderDetail } from "../../../blocks/utilities/src/Language/OrderDetail"
import { enWishlist, arWishlist } from "../../../blocks/utilities/src/Language/WishList"
import { enLanguageCurrency, arLanguageCurrency } from "../../../blocks/utilities/src/Language/LanguageCurrency"
import { enPayment, arPayment} from "../../../blocks/utilities/src/Language/Payment"
import { enStylist, arStylist} from "../../../blocks/utilities/src/Language/Stylist"
import { enProfile, arProfile} from "../../../blocks/utilities/src/Language/Profile"
import { enAddress, arAddress} from "../../../blocks/utilities/src/Language/Address"
import { enCard, arCard} from "../../../blocks/utilities/src/Language/Card"
import { enNotification, arNotification} from "../../../blocks/utilities/src/Language/Notification"
import { enContactUs, arContactUs } from '../../../blocks/utilities/src/Language/ContactUs';
import { enInventoryManagement, arInventoryManagement} from "../../../blocks/utilities/src/Language/InventoryManagement"
import { enCatalogue, arCatalogue} from "../../../blocks/utilities/src/Language/Catalogue"
import { enPromoCode, arPromoCode } from "../../../blocks/utilities/src/Language/PromoCode"
import { arDashboard, enDashboard } from '../../../blocks/utilities/src/Language/Dashboard';
import { arHiredStylist, enHiredStylist } from '../../../blocks/utilities/src/Language/HiredStylist';
import { arStylistPortfolio, enStylistPortfolio } from '../../../blocks/utilities/src/Language/StylistPortfolio';
import { arStylishClient, enStylishClient } from '../../../blocks/utilities/src/Language/StylishClient';
import { arChat, enChat } from '../../../blocks/utilities/src/Language/chatLang';
import { arAnalytics, enAnalytics } from '../../../blocks/utilities/src/Language/AnalyticsLang';


export const availableResources = {
    en: {
      header: enHeader,
      sidebar: enSidebar,
      footer: enFooter,
      loginSignup: enLoginSignup,
      prodDesc: enProdDesc,
      categoryFilter: enCategoryFilter,
      shoppingCart: enCart,
      orderManagement: enOrderManagement,
      orderDetail: enOrderDetail,
      wishlist: enWishlist,
      languageCurrency: enLanguageCurrency,
      payment: enPayment,
      stylist: enStylist,
      profile: enProfile,
      address: enAddress,
      card: enCard,
      promoCode: enPromoCode,
      notification: enNotification,
      contactUs: enContactUs,
      inventoryManagement: enInventoryManagement,
      hiredStylist: enHiredStylist,
      stylishClient: enStylishClient,
      catalogue: enCatalogue,
      dashboard: enDashboard,
      stlistPortfolio: enStylistPortfolio,
      chat: enChat,
      analytics: enAnalytics
    },
    ar: {
      header: arHeader,
      sidebar: arSidebar,
      footer: arFooter,
      loginSignup: arLoginSignup,
      prodDesc: arProdDesc,
      categoryFilter: arCategoryFilter,
      shoppingCart: arCart,
      orderManagement: arOrderManagement,
      orderDetail: arOrderDetail,
      wishlist: arWishlist,
      languageCurrency: arLanguageCurrency,
      payment: arPayment,
      stylist: arStylist,
      profile: arProfile,
      address: arAddress,
      card: arCard,
      promoCode: arPromoCode,
      notification: arNotification,
      contactUs: arContactUs,
      inventoryManagement: arInventoryManagement,
      hiredStylist: arHiredStylist,
      stylishClient: arStylishClient,
      catalogue: arCatalogue,
      dashboard: arDashboard,
      stylistPortfolio: arStylistPortfolio,
      chat: arChat,
      analytics: arAnalytics
    }
}
