import React from "react";
// Customizable Area Start
import {Typography, Grid, Box, Card,CardContent ,Accordion,AccordionSummary,AccordionDetails} from "@material-ui/core";
import FaqsController, { Props } from "./FaqsController.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18n from "../../../components/src/i18next/i18n";
import { Vector } from "./assets";
// Customizable Area End
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { styled } from "@material-ui/core/styles";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";



export default class Faq extends FaqsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleImgFaqsCSS = () =>{
      return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
    }

    render() {
        const {expanded,faqListData} = this.state
        return (
          <>
          {(this.state.userType === "buyer" || this.state.userType ==="" )&& <Header navigation={this.props.navigation} />}
            <Grid container
              style={{ backgroundColor: this.handleCondition() ? "#ffffff" : "#f2efef", paddingBottom:'40px', flexWrap: "nowrap" }}
              dir={i18n.dir()}
            >
            { this.state.userType === "seller" &&  (
                <Grid item xl={2} md={2} sm={2}>
                <SellerSidebar navigation={this.props.navigation} />
                </Grid>
              )  
            }

            { this.state.userType === "stylist" &&  (
                <Grid item xl={2} md={2} sm={2}>
                <StylishSidebar navigation={this.props.navigation} />
                </Grid>
              )  
            }
            {
              (this.state.userType === "buyer" || this.state.userType ==="") && (
             <Grid item xl={2} md={2} sm={2} style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "center" }}>
              <Box style={webStyles.homeBox}>
                <Box data-testId="homeFaq" onClick={this.handleLandingPageFromFaq} style={webStyles.nextBoxhome}>{this.transFaqs("Home")}</Box><img src={Vector} style={{transform: this.handleImgFaqsCSS()}}/><Box style={webStyles.WomenBox}>{this.transFaqs('FAQs')}</Box>
              </Box>
              <Typography style={webStyles.faqText}>{this.transFaqs("FAQs")}</Typography>
              </Grid>
              )
            }

              <Grid item xl={10} md={10} sm={10} xs={8}>
              { this.state.userType === "seller" && (
                <Box style={{width: "100%"}}>
                <SellerHeader navigation={this.props.navigation} /> 
                </Box>
              )
            }
              {  this.state.userType === "stylist" && (
                <Box style={{width: "100%"}}>
                <StylishHeader navigation={this.props.navigation} /> 
                </Box>
              )
            }
            { this.state.token && this.state.userType !== "buyer" && <Typography style={webStyles.faqText2} >{this.transFaqs('FAQs')}</Typography>}
              <Box style={{ paddingTop:  this.state.userType === "seller" ? "20px" : "50px", paddingLeft:'20px'}}>
                {faqListData &&
                <Muicard style={{width:'98%'}}>
                <Card style={{border:'solid 1px #E2E8F0'}}>
                  <CardContent style={{backgroundColor:'#FFF'}}>
                    {faqListData && faqListData.map((item, index) => (
                      <Accordion
                      data-test-id={`accordion-${index}`}
                        key={item.id}
                        expanded={expanded === `panel${item.id}`}
                        onChange={this.handleChange(`panel${item.id}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon style={{color:"#375280"}} />}
                          aria-controls={`panel${item.id}a-content`}
                          id={`panel${item.id}a-header`}
                        >
                          <Typography style={webStyles.accordionTitle}>{item?.attributes?.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography style={webStyles.accordionDescribtion}>{item?.attributes?.answer}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </CardContent>
                </Card>
                </Muicard>
                }
            </Box>
              </Grid>
            </Grid>
            {(this.state.userType === "buyer" || this.state.userType === "") && <Footer navigation={this.props.navigation}/> }
          </>
        );
    }
    // Customizable Area End

    
    
}

// Customizable Area Start
const webStyles = {

  homeBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: "row" as "row",
    fontFamily: "Lato", padding: '15px',
    width: '85%',
    gap: 15
  },
  nextBoxhome: {
    color: 'rgba(71, 85, 105, 1)',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: "Lato",
    cursor: "default"
  },
  WomenBox: {
    color: 'rgba(71, 85, 105, 1)',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: "Lato"
  },
  faqText: {
    color: '#375280',
    fontSize: '28px',
    fontWeight: 800,
    fontFamily: "Lato",
    width: '85%',
    padding: '8px',
  },
  faqText2: {
    color: '#375280',
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: "Lato",
    padding: '8px',
    paddingLeft:'20px',
    paddingTop: '15px',
  },
  accordionTitle: {
    color: '#375280',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: "Lato"
  },
  accordionDescribtion: {
    color: '#375280',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Lato",
    borderBottom: '1px solid #E2E8F0',
    paddingBottom: '20px',
    width: '100%'
  },

};

const Muicard = styled(Card)({
    "& .MuiPaper-elevation1": {
      boxShadow: '0 0  0 0 !important',
      background: "transparent !important"
    },
    "& .MuiCardContent-root:last-child": {
      paddingBottom: '0px',
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: '0px 0',
    }
})

// Customizable Area End
