import React from "react";

// Customizable Area Start
import {Typography, Grid, Box} from "@material-ui/core";
import Header from "../../../components/src/Header";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Footer from "../../../components/src/Footer";
// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (<>
    <Header navigation={this.props.navigation} />
    <Grid
      container
      style={{ backgroundColor: "#f2efef", paddingBottom:'40px' }}

    >
      <Grid item xl={2} md={3} sm={4} style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "center" }}>
        <Box style={webStyles.homeBox}>
          <Box data-test-id="homeAbout" onClick={this.handleLandingPageFromAbout} style={webStyles.nextBoxhome}>Home</Box>&nbsp;&nbsp;<ChevronRightRoundedIcon style={{ color: "rgba(55, 82, 128, 1)"}} />&nbsp;&nbsp;<Box style={webStyles.WomenBox}>{'About Fash'}</Box>
        </Box>
        <Typography style={webStyles.faqText}>{configJSON.AboutFashMsg}</Typography>
      </Grid>
      <Grid item xl={10} md={9} sm={8} style={{ paddingTop: "50px", margin: "0 auto" ,paddingBottom:'50px'}}>
        <Box style={webStyles.comingSoonBox}>
          <Typography style={webStyles.comingSoonText}>
            {configJSON.ComingSoonMsg}
          </Typography>
        </Box>
      </Grid>
    </Grid>

    <Footer navigation={this.props.navigation}/>
  </>)
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {

  homeBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: "row" as "row",
    fontFamily: "Lato", padding: '15px',
    width: '85%',
  },
  nextBoxhome: {
    color: '#375280',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: "Lato",
    cursor: "default"
  },
  WomenBox: {
    color: '#375280',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: "Lato"
  },
  faqText: {
    color: '#375280',
    fontSize: '28px',
    fontWeight: 800,
    fontFamily: "Lato",
    width: '85%',
    padding: '8px'
  },
  accordionTitle: {
    color: '#375280',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: "Lato"
  },
  accordionDescribtion: {
    color: '#375280',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Lato",
    borderBottom: '1px solid #E2E8F0',
    paddingBottom: '20px',
    width: '100%'
  },
   comingSoonBox : {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height:"450px",
    backgroundColor:"white",
    width: '85%'
   },
   comingSoonText : {
    fontSize:"40px",
    fontWeight: 900,
    fontFamily: "Lato",
  color :"#375280"
   }
};
// Customizable Area End
