import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
const navigation = require("react-navigation");
interface APIPayloadType {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: object;
    token?: string;
    type?: string;
}
export interface CountrySuccess {
    numeric_code: string;
    country_full_name: string;
    country_code: string;
    country_flag: string;
}

interface ValidResponseType {
    message: object;
    data: object;
}

interface ErrorDetail {
    failed_login: string;
    is_return: boolean;
    activated: boolean;
    is_phone_verified: boolean;
    is_email_activated: boolean;
}

interface UserData {
    id: string;
    type: string;
}

interface MetaData {
    token: string;
    account: AccountData;
}

interface AccountData {
    full_phone_number: string;
    country_code: number;
    phone_number: number;
    role:string;
    language:number;
    currency:number;
}

interface NotificationData {
    push_notification: PushNotificationData;
    email_notification: EmailNotificationData;
}

interface PushNotificationData {
    order_invoices: boolean;
    order_confirmations: boolean;
    delivery_confirmation: boolean;
    reviews_and_feedback_requests: boolean;
    refund_or_payment_complete: boolean;
    marketing_emails: boolean;
    product_stock_updates: boolean;
}

interface EmailNotificationData {
    order_invoices: boolean;
    order_confirmations: boolean;
    delivery_confirmation: boolean;
    reviews_and_feedback_requests: boolean;
    refund_or_payment_complete: boolean;
    marketing_emails: boolean;
    product_stock_updates: boolean;
}

interface APIResponse {
    data: UserData;
    meta: MetaData;
}

type AlertType = 'success' | 'error' | 'warning' | 'info';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mobileNumber:string;
    mobileError:boolean;
    errorMsg:{
        errorMsgMobile?:string;
        mobileNumberError?:boolean;
    };
    countrySuccess:Array<CountrySuccess>;
    isAlert:boolean;
    alertMsg:string;
    alertType:AlertType;
    selectedCountry: CountrySuccess | null;
    dropdownOpen: boolean;
    isCountryData:string;
    token: string;
    isType: string;
    isPhoneNumber: string;
    isPhoneType: string;
    userType: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id:string;
    // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    countryCodeAPICallId: string = "";
    postMobileRegistrationAPICallId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            mobileNumber: "",
            errorMsg: {
                errorMsgMobile: "",
                mobileNumberError: false
            },
            countrySuccess:[{
                numeric_code: "",
                country_full_name: "",
                country_code: "",
                country_flag: ""
            }],
            isAlert:false,
            alertMsg:"",
            alertType:"success" as AlertType,
            selectedCountry: null ,
            dropdownOpen: false,
            isCountryData: "+965",
            mobileError:false,
            token: "",
            isType: "",
            isPhoneNumber: "",
            isPhoneType: ""
            ,userType: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    
    async componentDidMount() {
        // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        this.getCountryCodeApi();
        const userRole  = await getStorageData('userRole', true);
        this.setState({
          userType: userRole?.userType
        })
        // Customizable Area End
      }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson.status === 500) {
                this.showAlert(configJSON.errorMsg, configJSON.InternalErrorMsg)
                return;
            }
            if (responseJson && !responseJson.errors) {
                this.apiSucessCall(apiRequestCallId, responseJson);
           } else if (responseJson && responseJson.errors) {
             this.apiFailureCall()
           }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    apiSucessCall = async (apiRequestCallId: string, responseJson: CountrySuccess[] & APIResponse ) => {
        if (apiRequestCallId === this.countryCodeAPICallId) {
            this.setState({
                countrySuccess: responseJson,
            });
        }
        if (apiRequestCallId === this.postMobileRegistrationAPICallId) {
            if (responseJson && responseJson.meta && responseJson?.meta?.token) {
                const tempRole = {
                    userType: responseJson.meta?.account?.role,
                    language: responseJson.meta?.account?.language,
                    currency: responseJson.meta?.account?.currency
                };
                await setStorageData("userRole", JSON.stringify(tempRole));
                this.setState({
                    isAlert: true,
                    alertMsg: configJSON.Login_SuccessfullyMsg,
                    alertType: configJSON.SuccessMsg,
                    mobileNumber: "",
                    token: responseJson.meta.token,
                    isType: responseJson.data.type,
                    isPhoneNumber: responseJson.meta.account.full_phone_number,
                }, () => this.openOTPScreen());
            }
        }
    };
    
    apiFailureCall = async () => {
        this.setState({ isAlert: true, alertMsg: configJSON.account_not_foundMsg, alertType: configJSON.errorMsg });
    };

    apiCallMobileLogin = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
        };
        const mobileRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        mobileRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        mobileRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        body &&
        mobileRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        
        mobileRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(mobileRequestMessage.id, mobileRequestMessage);
        return mobileRequestMessage.messageId;
    };

    onCloseAlert=()=>{
        this.setState({isAlert:false})
    }

    handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const mobileNumber = event.target.value;
        const mobileNumberRegx=configJSON.neightDigRegex;
        if (mobileNumberRegx.test(mobileNumber)&& mobileNumber.length<=7) {
            this.setState({
                mobileNumber: mobileNumber,
                mobileError:true,
                errorMsg:{
                    mobileNumberError:true,
                    errorMsgMobile:""
                }
            });
          } else {
            this.setState({
              mobileNumber: mobileNumber,
                isPhoneType: configJSON.phone,
              mobileError:false,
              errorMsg:{
                mobileNumberError:false,
                errorMsgMobile:configJSON.alphnewmertic
              }
            });
          }
    };
   
    handleSubmit = (submitEvent: React.FormEvent) => {
        submitEvent.preventDefault();
        const { mobileNumber } = this.state;
        const isNumeric = /^\d+$/;
        if (mobileNumber.trim() === "") {
            this.setState({
                mobileError: true,
                errorMsg: {
                    errorMsgMobile: configJSON.phoneValidationMsg,
                    mobileNumberError: true
                }
            });
        } else if (!isNumeric.test(mobileNumber) || mobileNumber.length <= 7) {
            this.setState({
                mobileError: true,
                errorMsg: {
                    errorMsgMobile: configJSON.alphnewmertic,
                    mobileNumberError: true
                }
            });
        } else {
            this.setState({
                mobileError: false,
                errorMsg: {
                    errorMsgMobile: "",
                    mobileNumberError: false
                }
            });
            this.postMobileLoginApi();
        }
    };
    
    getCountryCodeApi = async () => {
        this.countryCodeAPICallId = await this.apiCallMobileLogin({
            contentType: configJSON.countryApiContentType,
            method: configJSON.httpMethodType,
            endPoint: configJSON.countryCodeEndPoint
        });
    };
    
    postMobileLoginApi = async () => {
        const body = {
                data : {
                    type : configJSON.sms_accountMsg,
                    attributes : {
                        full_phone_number: this.state.isCountryData + this.state.mobileNumber
                    }
                }
        };
        this.postMobileRegistrationAPICallId = await this.apiCallMobileLogin({
          contentType: configJSON.postRegistrationContentType,
          method: configJSON.postApimethod,
          endPoint: configJSON.postMobileRegistrationEndPoint,
          body: body
        });
      };
      
      handleCountrySelect = (country: CountrySuccess,code:string) => {
        this.setState({ selectedCountry: country,
             dropdownOpen: false,
             isCountryData:code
        });
      };

      toggleDropdown = () => {
        this.setState((prevState) => ({
          dropdownOpen: !prevState.dropdownOpen,
        }));
      };

      openEmailScreen = () => {
        const toEmail = new Message(getName(MessageEnum.NavigationMessage));
        toEmail.addData(getName(MessageEnum.NavigationTargetMessage),configJSON.EmailAccountLoginBlockMsg);
        toEmail.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(toEmail);
      };

      openOTPScreen = async() => {
        await storage.set("sms-token", this.state.token);
        const OTPValue = new Message(getName(MessageEnum.NavigationMessage));
        OTPValue.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        OTPValue.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.EmailOTPVarificationMsg);
        const raiseMessage: Message = new Message( getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {isToken:this.state.token,isType:this.state.isType,isPhoneNumber:this.state.isPhoneNumber,phoneType:this.state.isPhoneType,loginType:'mobile'});
        OTPValue.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        runEngine.sendMessage(OTPValue.id, OTPValue);
      };

      openRegistrationScreenMobile = () => {
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage),configJSON.EmailAccountRegistrationMsg);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(toNavigate);
      }

    // Customizable Area End
}
