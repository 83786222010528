export const enDashboard = {
    "Out of Stock": "Out of Stock",
    "Pricing Error": "Pricing Error", 
    "Order Processing Error": "Order Processing Error", 
    "Payment Processing Issue": "Payment Processing Issue", 
    "Order Limit Exceeded": "Order Limit Exceeded", 
    "Violation of Purchase Policy": "Violation of Purchase Policy",
    "AM" : "AM",
    "PM": "PM",
    "New Request": "New Request",
    "New Order": "New Order",
    "Total": "Total",
    "Pending": "Pending",
    "In Progress": "In Progress",
    "Accepted": "Accepted",
    "New Styling Requests": "New Styling Requests",
    "View All": "View All",
    "Request ID": "Request ID",
    "Request Time": "Request Time",
    "Name & Gender": "Name & Gender",
    "Budget": "Budget",
    "Status": "Status",
    "Action": "Action",
    "View Request": "View Request",
    "No New Styling Requests Available": "No New Styling Requests Available",
    "New Orders": "New Orders",
    "Order ID": "Order ID",
    "Order Time": "Order Time",
    "Products": "Products",
    "Total Price": "Total Price",
    "Reject": "Reject",
    "Accept Order": "Accept Order",
    "No Data Found": "No Data Found",
    "Reject Order": "Reject Order",
    "Are you sure you want to reject the order?": "Are you sure you want to reject the order?",
    "Reason of Rejection": "Reason of Rejection",
    "Close": "Close",
    "Are you sure you want to accept the order?": "Are you sure you want to accept the order?",
    "Set order reupload time": "Set order reupload time",
    "mins": "mins",
    "Confirm": "Confirm",
    "of": "of",
    "results": "results",
    "Gender": "Gender",
    "Colours": "Colours",
    "Accept": "Accept",
    "You have accepted the styling request of Scarlet.": "You have accepted the styling request of Scarlet.",
    "You have rejected the styling request of Scarlet.": "You have rejected the styling request of Scarlet.",
    "There are no orders":"There are no orders",
    "Out for Delivery":"Out for Delivery",
    "Delivered":"Delivered",
    "Ready for Collection":"Ready for Collection",
    "In Process":"In Process",
    "Return Request":"Return Request",
    "Rejected":"Rejected",
    "Shop":"Shop",
    "Accept Order (0:29)":"Accept Order (0:29)",
}

export const arDashboard = {
    "Out of Stock": "غير متوفر حاليًا",
    "Pricing Error": "خطأ في التسعير", 
    "Order Processing Error": "خطأ في معالجة الطلب", 
    "Payment Processing Issue": "مشكلة في معالجة الدفع", 
    "Order Limit Exceeded": "تم تجاوز حد الطلب", 
    "Violation of Purchase Policy": "انتهاك سياسة الشراء",
    "AM": "صباحًا",
    "PM": "مساءً",
    "New Request": "طلب جدید",
    "New Order": "طلب جديد",
    "Total":"الإجمالي",
    "Pending": "قيد الانتظار",
    "In Progress": "قيد الإنجاز",
    "Accepted": "تم القبول",
    "New Styling Requests": "طلبات التصمیم الجدیدة",
    "View All": "عرض الكل",
    "Request ID": "رقم الطلب",
    "Request Time": "وقت الطلب",
    "Name & Gender": "الإسم والجنس",
    "Budget": "المیزانیة",
    "Status": "الحالة",
    "Action": "الإجراء",
    "View Request": "عرض الطلب",
    "No New Styling Requests Available": "لا توجد طلبات تصميم جديدة متاحة",
    "New Orders": "الطلبات الجدیدة",
    "Order ID": "رقم الطلب",
    "Order Time": "وقت الطلب",
    "Products": "المنتجات",
    "Total Price": "السعر الإجمالي",
    "Reject": "رفض",
    "Accept Order": "قبول الطلب",
    "No Data Found": "لم يتم العثور على بيانات",
    "Reject Order": "رفض الطلب",
    "Are you sure you want to reject the order?": "هل أنت متأكد أنك تريد رفض الطلب؟",
    "Reason of Rejection": "سبب الرفض",
    "Close":"يغلق",
    "Are you sure you want to accept the order?": "هل أنت متأكد أنك تريد قبول الطلب؟",
    "Set order reupload time": "ضبط وقت إعادة تحميل الطلب",
    "mins": "دقيقة",
    "Confirm": "التأكيد",
    "results": "نتيجة",
    "of": "من أصل",
    "Gender": "جنس",
    "Colours": "الألوان",
    "Accept": "يقبل",
    "You have accepted the styling request of Scarlet.": "لقد قبلت طلب التصميم الخاص بـ Scarlet.",
    "You have rejected the styling request of Scarlet.": "لقد رفضت طلب التصميم الخاص بـ Scarlet.",
    "There are no orders": "لا توجد طلبات",
    "Out for Delivery":"خارج للتسليم",
    "Delivered":"تم التوصيل",
    "Ready for Collection":"جاهز للتجميع",
    "In Process":"قيد المعالجة",
    "Return Request":"طلب العودة",
    "Rejected":"مرفوض",
    "Shop":"التسوق",
    "Accept Order (0:29)":"قبول الطلب (0:29)",
}