Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.applicationJsonContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableuserprofiles2";
exports.labelBodyText = "customisableuserprofiles2 Body";
exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.putApiMethod = "PUT"
exports.paymentDetail = "bx_block_tappaymentsintegration/tappayment/payment_detail";
exports.paymentDetailDownload = "bx_block_tappaymentsintegration/tappayment/payment_detail";
exports.getApiMethodDelete = "bx_block_tappaymentsintegration/tappayment/payment_detail"
exports.getApiDelete = "DELETE"
exports.stylistCustomForms = "bx_block_custom_form/hire_stylist_custom_forms"
// Api

exports.faqListApi = 'bx_block_contact_us/faqs?role=Buyer'
exports.faqListalluserApi = 'bx_block_contact_us/faqs?role='
exports.getCurrencyLanguageApiEndPoint = "/account_block/accounts/get_language_currency"
exports.addLanguageCurrency = "/account_block/accounts/update_language_currency?";
exports.getPersonalUserDetailsApiEndPoint = "/account_block/accounts/get_personal_detail";
exports.HomeMsg="Home";
exports.ProfileMsg="Profile";
exports.MyAccountMsg="My Account";
exports.WelcomeAccountMsg="Welcome to your Account";
exports.MyprofileMsg="My Profile";
exports.EditProfileMsg="Edit Profile";
exports.userDetailsMsg="User Details";
exports.PlaceholderFirst="Enter Your First Name";
exports.Placeholderlast="Enter Your Last Name";
exports.PlaceholderEmail="Enter Your E-mail";
exports.PlaceholderPhone="Enter Phone Number";
exports.sentTo="Please enter the 4 digit code sent to";
exports.emailText = "your email id ";
exports.email_accountMsg="email_account";
exports.smsText = "your mobile number id ";
exports.PlaceholderOldPasss="Enter Your Old Password";
exports.PlaceholderPasss="Enter Your Password";
exports.PlaceholderNewPass="Enter Your New Password";
exports.PlaceholderReNewPass="Re-Enter Your Password";
exports.Fname="First Name";
exports.enterOTP="* Enter 4 digit OTP";
exports.resentOTP = "Resent OTP Successfully";
exports.emailType = "email_otp";
exports.smsType = "sms_otp";
exports.Lname="Last Name";
exports.postResendOTPContentType = "application/json";
exports.postResendOTPEndPoint = "account_block/accounts/resend_account_otp";
exports.sms_accountMsg="sms_account";
exports.postEmailVarificationEndPoint = "account_block/accounts/verify_account_otp";
exports.postEmailVarificationContentType = "application/json";
exports.postApimethod = "POST";
exports.postPhoneVarificationEndPoint = "account_block/accounts/verify_account_otp";
exports.Email="Email";
exports.Phone="Phone Number";
exports.OldPass="Old Password";
exports.Password="Password";
exports.NewPass="New Password";
exports.ReNewPass="Repeat New Password";
exports.SaveChangeMsg="Save Changes";
exports.AddAccountMsg="Add Account";
exports.ChangePasswordMSG="Change Password";
exports.EditProfilePicMsg="Edit Profile Picture";
exports.uploadImagePicMsg="Upload Image";
exports.languageHeader = 'Select Language'
exports.currencyHeader = 'Select Currency';
exports.placeholderFrom = "From";
exports.placeholderTo = "To";
exports.save = 'Save';
exports.clear = "Clear All";
exports.apply = "Apply";
exports.resendCode="Resend Code";
exports.verifyAccount="Verify Account";
exports.reciveCodeText="Didn't Received Code?";
exports.otpVerification="OTP Verification";
exports.header = "Language and Currency"
exports.product_cataloge = "GET";
exports.getCatalogues = "bx_block_catalogue/catalogues/catalogue_buyer";
exports.firstNameValidationMsg = "*Please enter a valid first name";
exports.lastNameValidationMsg = "*Please enter a valid last name";
exports.emailValidationMsg="*Please enter your email address";
exports.nameRegex=/^[^\s]+(\s[^\s]+)?$/;
exports.emailRegex=/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
exports.emailValidMsg="*Please enter a valid email address";
exports.phoneValidationMsg="*Please enter your phone number";
exports.phoneRegex=/^\d{10}$/;
exports.alphnewmertic="*Please enter a valid phone number";
exports.confirmPass = "* Please re-enter your password";
exports.passRegx=/^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*\d)(?=.*[a-z])[A-Za-z\d!@#$%^&*]{8,}$/;
exports.min8digitValidationMsg="*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric";
exports.validationPassMsg="*Please enter your password";
exports.reEnterValidationMsg="*Please re-enter your password";
exports.countryApiContentType = "application/json";
exports.httpMethodType = "GET";
exports.AccountNameMsg = "Account Name";
exports.countryCodeEndPoint = "accounts/country_code_and_flags";
exports.bankDetailsGetApi = "accounts/bank_details";
exports.updateProfilePicAPIEndPoint = "account_block/accounts/update_personal_detail";
exports.multipartContentType= "multipart/form-data";
exports.favouritesremoved = "bx_block_favourites/favourites/destroy_by_favouritable?favouriteable_id";
exports.favouritesLink = "bx_block_favourites/favourites";
exports.postFavouriteApi = "bx_block_favourites/favourites";
exports.exampleApiContentType = "application/json";
exports.getCataloguesToken = "bx_block_catalogue/catalogues?category_ids[]";
exports.sellerMyStoreEndPoint = "accounts/bussiness/seller_store";

exports.Wishlist = "Wishlist";
exports.FavouriteStylist = "Favourite Stylist";
exports.emptyWishlist = "Your wishlist is empty.";
exports.continueShopping = "Continue Shopping";
exports.moveToCart = "Move to cart";
exports.accountMessage = "Welcome to your Account";
exports.FavouriteStylistGETApiEnd = "account_block/buyer_favourites";
exports.removeFavTitle = "item removed from wishlist";
exports.addNewPaymentRequestEndPoint = "bx_block_custom_form/stylist_payment_requests";

exports.hiredSTylistTitle = "Hired Stylists"
// Customizable Area End