export const enLanguageCurrency = {
    "Home": "Home",
    "Language and Currency": "Language and Currency",
    "Welcome to your Account": "Welcome to your Account",
    "Select Language": "Select Language",
    "Select Currency": "Select Currency",
    "Dollar": "Dollar",
    "Dinar": "Dinar",
    "Save": "Save",
    "Setting Updated successfully": "Setting Updated successfully"
}

export const arLanguageCurrency = {
    "Home": "الصفحة الرئيسية",
    "Language and Currency": "اللغة والعملة",
    "Welcome to your Account": "مرحبا بك في حسابك",
    "Select Language": "تحديد اللغة",
    "Select Currency": "اختر العملة",
    "Dollar": "الدولار",
    "Dinar": "الدينار",
    "Save": "حفظ",
    "Setting Updated successfully": "تم تحديث الإعداد بنجاح"
}