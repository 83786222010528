import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { CountrySuccess } from "../../email-account-registration/src/EmailAccountRegistrationController.web";
import React from "react";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities"
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import storage from "../../../framework/src/StorageProvider.web";
import i18n, { i18nUpdate } from "../../../components/src/i18next/i18n"



interface ResendEmailOTP {
  data: {
    type: string;
    attributes: {
      email: string;
    }
  }
}

interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
}

interface OTPBody {
  otp_type: string | undefined;
  token: string | undefined;
  pin: string;
}

interface PhoneOTPPayLoad {
  token: string | undefined;
  pin: string;
}

interface ErrorPhone {
  errors: [{
    phone_number?: string;
    email?: string;
  }]
}

interface ResendSMSOTP {
  data: {
    type: string;
    attributes: {
      full_phone_number: string;
    }
  }
}
interface ErrorPhone2 {
  errors: {
    full_phone_number: string
  }
}

interface MetaResponse {
  meta: {
    sms_token: string;
    email_token: string;
  }
}

export interface ErrorsMessage {
  firstNameMessage?: string;
  lastNameMessage?: string;
  emailMessage?: string;
  passwordMessage?: string;
  rePasswordMessage?: string;
  phoneNumberMessage?: string;
  oldPasswordMessage?: string;
  newPasswordMessage?: string;
  errorFirstName?: boolean;
  errorLastName?: boolean;
  errorEmail?: boolean;
  errorPassword?: boolean;
  errorOldPassword?: boolean;
  errorNewPassword?: boolean;
  errorRePassword?: boolean;
  errorPhoneNumber?: boolean;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dropdownOpen: boolean;
  contryCodeResp: CountrySuccess[];
  selectedCountry: CountrySuccess | null;
  isCountryData: string;
  errorsMessage: ErrorsMessage;
  oldPasswordIcon: boolean;
  oldPassword: string;
  newPasswordIcon: boolean;
  newPassword: string;
  ReNewPasswordIcon: boolean;
  rePassword: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  firstNameError: boolean;
  uploadDialogOpen: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  imageUpload: FileList | null,
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  profileImage: string;
  imageUrl: string;
  error: string
  emailAndOtpVerify: boolean;
  otpInput: string;
  errorOtpMsg: string;
  emailType: string;

  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  saveChangesAPICallID: string = "";
  countryCodeAPICall: string = "";
  getPersonalUserAPICallID: string = "";
  changePasswordAPICallID: string = "";
  changeProfileAPICallID: string = "";
  phoneNumberOtpVerifyCallID: string = "";
  emailVerifyCallID: string = "";
  resendOTPCallID: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      dropdownOpen: false,
      contryCodeResp: [],
      selectedCountry: null,
      isCountryData: "+965",
      errorsMessage: {
        firstNameMessage: "",
        lastNameMessage: "",
        emailMessage: "",
        passwordMessage: "",
        rePasswordMessage: "",
        phoneNumberMessage: "",
        oldPasswordMessage: "",
        newPasswordMessage: "",
        errorFirstName: false,
        errorLastName: false,
        errorEmail: false,
        errorPassword: false,
        errorOldPassword: false,
        errorNewPassword: false,
        errorRePassword: false,
        errorPhoneNumber: false
      },
      oldPasswordIcon: false,
      oldPassword: '',
      newPasswordIcon: false,
      newPassword: '',
      ReNewPasswordIcon: false,
      rePassword: '',
      firstName: '',
      lastName: "",
      email: '',
      phoneNumber: '',
      firstNameError: false,
      uploadDialogOpen: false,
      inputRef: React.createRef(),
      imageUpload: null,
      isAlert: false,
      alertType: 'success',
      alertMsg: '',
      profileImage: '',
      imageUrl: '',
      error: "",
      emailAndOtpVerify: false,
      otpInput: '',
      errorOtpMsg: "",
      emailType: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    i18nUpdate()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.countryCodeAPICall) {
      this.setState({
        contryCodeResp: responseJson
      });
      const userData = await getStorageData('user_data', true);
      if (userData) {
        const countryData = responseJson.filter((country: CountrySuccess) => country.numeric_code === `+${userData.country_code}`)
        this.setState({
          selectedCountry: countryData[0],
          isCountryData: countryData[0]?.numeric_code
        })
      }
    } else if (apiRequestCallId === this.getPersonalUserAPICallID) {
      if (responseJson) {
        this.setState({
          lastName: responseJson.data?.attributes?.last_name,
          phoneNumber: responseJson.data?.attributes?.phone_number?.toString(),
          firstName: responseJson.data?.attributes?.first_name,
          email: responseJson.data?.attributes?.email,
          profileImage: responseJson.data?.attributes?.profile_picture
        });
      }
    } else if (apiRequestCallId === this.saveChangesAPICallID) {
      this.handleSaveResponse(responseJson)
    } else if (apiRequestCallId === this.changePasswordAPICallID) {
      this.handleChangePasswordResponse(responseJson.errors)

    } else if (apiRequestCallId === this.changeProfileAPICallID) {
      this.getPersonalUserAPICall();
      this.setState({
        isAlert: true,
        alertType: "success",
        alertMsg: `${this.transSeller("User Profile Updated Successfully")}`,
        uploadDialogOpen: false,
        imageUrl: '',
        imageUpload: null
      });
    } else {
      this.handleApiCall(apiRequestCallId, responseJson)
    }

    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    this.getCountryCallApiCall();
    this.getPersonalUserAPICall();
  };

  transSeller = (klog: string) => {
    return i18n.t(klog, { ns: "profile" })
  };

  handleApiCall = async (apiRequestCallId: string, responseJson: { message: string, email?: string } & {
    errors: {
      pin: string
    }
  }) => {
    if (apiRequestCallId === this.resendOTPCallID) {
      alert(configJSON.resentOTP);
    } else if (apiRequestCallId === this.phoneNumberOtpVerifyCallID) {
      this.handlePhoneNumberRespHandle(responseJson)
    } else if (apiRequestCallId === this.emailVerifyCallID) {
      this.handleVerifyApiCallres(responseJson);
    } else {
      this.setState({
        error: "SomeThing Went Wrong!"
      })
    }
  }

  handleChangePasswordResponse = (responseError: string) => {
    if (responseError) {
      this.setState({
        isAlert: true,
        alertType: "error",
        alertMsg: responseError
      });
    } else {
      this.setState({
        isAlert: true,
        alertType: "success",
        alertMsg: `${this.transSeller("Change Password Updated Successfully")}`
      });
    }
  }

  handleVerifyApiCallres = async (responseJson: { message: string, email?: string } & {
    errors: {
      pin: string
    }
  }) => {
    const smssToken = await getStorageData('sms-token');
    if (smssToken && responseJson?.message) {
      this.setState({
        emailType: "sms_otp",
        otpInput: ''
      })
    } else if (responseJson?.errors?.pin) {
      this.setState({
        errorOtpMsg: responseJson?.errors?.pin
      });
    } else if (responseJson?.email) {
      this.setState({
        errorOtpMsg: responseJson?.email
      });
    } else {
      if (responseJson?.message) {
        this.setState({
          isAlert: true,
          alertMsg: responseJson?.message,
          alertType: "success"
        });
      }
      this.setState({
        emailAndOtpVerify: false,
        otpInput: '',
      });
      await removeStorageData('user-email');
    }
  }

  handlePhoneNumberRespHandle = async (responseJson: { errors: { pin: string } } & { message: string }) => {
    if (responseJson.errors?.pin) {
      this.setState({
        errorOtpMsg: responseJson.errors?.pin
      })
    } else {
      if (responseJson?.message) {
        this.setState({
          isAlert: true, alertMsg: responseJson?.message,
          alertType: "success", emailType: "",
        })
      }
      this.setState({
        otpInput: '',
        emailAndOtpVerify: false, emailType: "",
      });
      await removeStorageData('user-email');
      await removeStorageData('sms-token');
    }
  }

  handlekeyDownHan = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete' || (e.key >= '0' && e.key <= '9')) {
      return;
    }
    e.preventDefault();
  }

  handleSaveResponse = async (responseJson: ErrorPhone & MetaResponse & ErrorPhone2) => {
    if (responseJson?.errors) {
      if (responseJson?.errors && responseJson.errors[0]?.email) {
        this.setState({
          isAlert: true, alertMsg: `Email ${responseJson.errors[0].email}`,
          alertType: 'error'
        })
      } else if (responseJson?.errors?.full_phone_number) {
        this.setState({
          alertMsg: responseJson.errors.full_phone_number,
          alertType: 'error', isAlert: true,
        })
      } else if (responseJson?.errors[0]?.phone_number) {
        this.setState({
          alertType: 'error', alertMsg: `Phone Number ${responseJson.errors[0].phone_number}`,
          isAlert: true,
        });
      }
    } else {
      if (responseJson.meta.email_token || responseJson.meta.sms_token) {
        await setStorageData("user-email", responseJson.meta.email_token);
        await setStorageData("sms-token", responseJson.meta.sms_token);
        const tokenToOpen = this.handleSmsOREmail(responseJson.meta);
        this.setState({
          emailAndOtpVerify: true,
          emailType: tokenToOpen
        });
      } else {
        this.setState({
          isAlert: true,
          alertMsg: `${this.transSeller("User Profile Updated Successfully")}`,
          alertType: "success"
        });
        this.getPersonalUserAPICall();

      }
    }
  }

  handleSmsOREmail = (metaData: {
    sms_token: string;
    email_token: string;
  }) => {
    if (metaData.sms_token && metaData.email_token) {
      return "email_otp"
    } else if (metaData.email_token) {
      return "email_otp"
    } else {
      return "sms_otp"
    }
  };


  handleOldPassword = (passVal: string) => {
    const oldPassword = passVal;
    this.setState({
      oldPassword: oldPassword,
      errorsMessage: {
        oldPasswordMessage: "",
        errorOldPassword: false
      }
    })
  };

  handleOption = () => {
    if (this.state.emailType === 'email_otp') {
      return true;
    }
  };

  maskEmail = () => {
    let mail = this.state.email;
    let atIndex = mail?.indexOf("@");
    let maskedEmail = mail?.substring(atIndex - 2);
    let starMail = `********${maskedEmail}`;
    return starMail;
  };

  maskPhone = () => {
    let phoneNumber = this.state.phoneNumber;
    let length = phoneNumber?.length;
    let maskedPart1 = phoneNumber?.substring(length - 2);
    let starPhone = `*******${maskedPart1}`;
    return starPhone;
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  handleSelectCountryCode = (country: CountrySuccess, code: string) => {
    this.setState({
      selectedCountry: country,
      dropdownOpen: false,
      isCountryData: code
    });
  };

  handleCloseUploadDialog = () => {
    this.setState({
      uploadDialogOpen: false,
      imageUrl: '',
      imageUpload: null
    });
  };

  handleUpload = () => {
    return this.state.inputRef.current
      ? this.state.inputRef.current.click()
      : "";
  };

  handleFileUpload = (files: FileList | null) => {
    if (files) {
      this.setState({
        imageUpload: files,
        imageUrl: URL.createObjectURL(files[0])
      });
    }
  };

  handleEditProfileClick = () => {
    this.setState({
      uploadDialogOpen: true
    })
  };

  handleProfilePicUpload = async () => {
    if (this.state.imageUpload) {
      const token = await getStorageData("auth-token");
      const header = {
        token: token
      };

      const formData = new FormData();
      formData.append("first_name", this.state.firstName);
      formData.append("last_name", this.state.lastName);
      formData.append("email", this.state.email);
      formData.append("full_phone_number", `${this.state.isCountryData}${this.state.phoneNumber}`);
      formData.append("profile_picture", this.state.imageUpload[0], this.state.imageUpload[0]?.name);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.changeProfileAPICallID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateProfilePicAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `PUT`
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  }

  generateHelperFirst = () => {
    return this.state.errorsMessage.firstNameMessage ? this.state.errorsMessage.firstNameMessage : "";
  }

  generateHelperLast = () => {
    return this.state.errorsMessage.lastNameMessage ? this.state.errorsMessage.lastNameMessage : "";
  }

  generateHelperEmail = () => {
    return this.state.errorsMessage.emailMessage ? this.state.errorsMessage.emailMessage : "";
  }

  validatePassword = () => {
    const { oldPassword } = this.state;

    const passRegx = configJSON.passRegx;
    if (oldPassword === "" || !passRegx.test(oldPassword)) {
      this.setState({
        errorsMessage: {
          oldPasswordMessage: oldPassword ? this.transSeller(configJSON.min8digitValidationMsg) : this.transSeller(configJSON.validationPassMsg),
          errorOldPassword: true
        }
      });
      return false;
    }
    return true;

  };

  validateRePassword = () => {
    const { newPassword, rePassword } = this.state;
    if (rePassword === "" || rePassword !== newPassword) {
      this.setState({
        errorsMessage: {
          rePasswordMessage: rePassword ? this.transSeller(configJSON.confirmPass) : this.transSeller(configJSON.reEnterValidationMsg),
          errorRePassword: true
        }
      });

      return false;
    }
    return true;

  };


  validateNewPassword = () => {
    const { newPassword } = this.state;
    const passRegx = configJSON.passRegx;
    if (newPassword === "" || !passRegx.test(newPassword)) {
      this.setState({
        errorsMessage: {
          newPasswordMessage: newPassword ? configJSON.min8digitValidationMsg : configJSON.validationPassMsg,
          errorNewPassword: true
        }
      });
      return false;
    }

    return true;
  };



  handleChangePassword = () => {
    if (!this.validatePassword()) {
      return;
    }
    if (!this.validateNewPassword()) {
      return;
    }
    if (!this.validateRePassword()) {
      return;
    }

    this.changePasswordApiCall();
  };

  changePasswordApiCall = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const body = {
      data: {
        "current_password": this.state.oldPassword,
        "new_password": this.state.newPassword,
        "confirm_password": this.state.rePassword
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/change_password`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  helperTextOldPassword = () => {
    return this.state.errorsMessage.oldPasswordMessage ? this.state.errorsMessage.oldPasswordMessage : ""
  }

  helperTextPhoneNumber = () => {
    return this.state.errorsMessage.phoneNumberMessage ? this.state.errorsMessage.phoneNumberMessage : ""
  }

  handleRePassword = (passVal: string) => {
    const rePassword = passVal;
    const { newPassword } = this.state;
    if (newPassword !== rePassword) {
      this.setState({
        rePassword: rePassword,
        errorsMessage: {
          rePasswordMessage: this.transSeller(configJSON.confirmPass),
          errorRePassword: true
        }
      });
    } else {
      this.setState({
        rePassword: rePassword,
        errorsMessage: {
          rePasswordMessage: "",
          errorRePassword: false
        }
      });
    }
  };

  handleLast = (event: string) => {
    const lastName = event;
    this.setState({
      lastName: lastName.replace(/\d/g, ''),
      errorsMessage: {
        lastNameMessage: "",
        errorLastName: false
      }
    });
  };

  handleNumber = (numberVal: string) => {
    const number = numberVal;
    this.setState({
      phoneNumber: number.replace(/\D/g, ''),
      errorsMessage: {
        phoneNumberMessage: "",
        errorPhoneNumber: false
      }
    })
  };

  getPersonalUserAPICall = async () => {
    const user_id = localStorage.getItem('user_id');
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.countryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPersonalUserAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPersonalUserDetailsApiEndPoint}?user_id=${user_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountryCallApiCall = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.countryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryCodeAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryCodeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changePasswordAPICall = async () => {
    const token = await getStorageData("auth-token");
    const header = { token: token };
    const formData = new FormData();
    formData.append("last_name", this.state.lastName);
    formData.append("email", this.state.email);
    formData.append("first_name", this.state.firstName);
    formData.append("full_phone_number", `${this.state.isCountryData}${this.state.phoneNumber}`);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.saveChangesAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/update_personal_detail`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  validateFirstName = () => {
    const { firstName } = this.state;
    const nameRegex = configJSON.nameRegex;
    if (firstName?.trim() === "" || !nameRegex.test(firstName)) {
      this.setState({
        errorsMessage: {
          errorFirstName: true, firstNameMessage: this.transSeller(configJSON.firstNameValidationMsg)
        },
        firstNameError: true
      });
      return false;
    }
    return true;
  };

  validateEmail = () => {
    const { email } = this.state;
    const emailRegex = configJSON.emailRegex;
    if (email === "" || !emailRegex.test(email)) {
      this.setState({
        errorsMessage: {
          errorEmail: true,
          emailMessage: email ? configJSON.emailValidMsg : configJSON.emailValidationMsg,
        }
      });
      return false;
    }
    return true;
  };

  validateLastName = () => {
    const { lastName } = this.state;
    const nameRegex = configJSON.nameRegex;
    if (lastName?.trim() === "" || !nameRegex.test(lastName)) {
      this.setState({
        errorsMessage: {
          lastNameMessage: configJSON.lastNameValidationMsg,
          errorLastName: true
        },
      });
      return false;
    }
    return true;
  };

  handleNewPassword = (passVal: string) => {
    const newPassword = passVal;
    this.setState({
      newPassword: newPassword,
      errorsMessage: {
        newPasswordMessage: "",
        errorNewPassword: false
      }
    })
  };

  validatePhoneNumber = () => {
    const { phoneNumber } = this.state;
    const phoneRegex = configJSON.phoneRegex;

    if (phoneNumber?.trim() === "") {
      this.setState({
        errorsMessage: {
          phoneNumberMessage: this.transSeller(configJSON.phoneValidationMsg),
          errorPhoneNumber: true
        }
      });
      return false;
    }
    else if (!phoneRegex.test(phoneNumber) && phoneNumber.length <= 7) {
      this.setState({
        errorsMessage: {
          phoneNumberMessage: this.transSeller(configJSON.alphnewmertic),
          errorPhoneNumber: true
        }
      });
      return false;
    }
    else {
      this.setState({
        errorsMessage: {
          phoneNumberMessage: "",
          errorPhoneNumber: false
        }
      });
      return true;
    }
  };

  apiCallMobileService = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestmobileOTPMessageSerice = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestmobileOTPMessageSerice.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestmobileOTPMessageSerice.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestmobileOTPMessageSerice.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestmobileOTPMessageSerice.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestmobileOTPMessageSerice.id, requestmobileOTPMessageSerice);
    return requestmobileOTPMessageSerice.messageId;
  };

  handleSubmitPhoneOTPForm = async (body: PhoneOTPPayLoad) => {
    this.phoneNumberOtpVerifyCallID = await this.apiCallMobileService({
      contentType: configJSON.postEmailVarificationContentType,
      method: configJSON.postApimethod,
      endPoint: configJSON.postPhoneVarificationEndPoint,
      body: body
    });
  };

  handleSubmitEmailOTPForm = async (body: OTPBody) => {
    this.emailVerifyCallID = await this.apiCallMobileService({
      contentType: configJSON.postEmailVarificationContentType,
      method: configJSON.postApimethod,
      endPoint: configJSON.postEmailVarificationEndPoint,
      body: body
    });
  };

  handleSubmit = async () => {
    const { otpInput } = this.state;
    const errorMsg = otpInput === '' || otpInput.length !== 4 ? configJSON.enterOTP : '';
    this.setState({ errorOtpMsg: errorMsg });
    const isApiType = this.state.emailType === configJSON.smsType;
    const tokenMobile = await storage.get("sms-token");
    const otpTypeMobile = configJSON.smsType;
    const otpTypeEmail = configJSON.emailType;
    const tokenEmail = await storage.get("user-email");
    if (errorMsg === "") {
      if (isApiType) {
        const apiData = {
          token: tokenMobile,
          pin: otpInput,
          otp_type: otpTypeMobile
        };
        this.handleSubmitPhoneOTPForm(apiData);
      } else {
        const apiData = {
          otp_type: otpTypeEmail,
          token: tokenEmail,
          pin: otpInput,
        };
        this.handleSubmitEmailOTPForm(apiData);
      }
    }
  }

  handleResendSMSOTP = async (body: ResendSMSOTP | ResendEmailOTP) => {
    this.resendOTPCallID = await this.apiCallMobileService({
      contentType: configJSON.postResendOTPContentType,
      method: configJSON.postApimethod,
      endPoint: configJSON.postResendOTPEndPoint,
      body: body
    })
  };

  handeResentOTP = () => {
    if (this.state.emailType === 'email_otp') {
      const body = {
        data: {
          type: configJSON.email_accountMsg,
          attributes: {
            email: this.state.email,
          },
        },
      };
      this.handleResendSMSOTP(body);
    } else {
      const body = {
        data: {
          type: configJSON.sms_accountMsg,
          attributes: {
            full_phone_number: `${this.state.isCountryData}${this.state.phoneNumber}`,
          },
        },
      };
      this.handleResendSMSOTP(body);
    }
  };

  handleView = (state: boolean, trueSta: string, falseSta: string) => {
    if (state) {
      return trueSta;
    } else {
      return falseSta;
    }
  }

  handleEmailAndOtpVerifyClose = () => {
    this.setState({ emailAndOtpVerify: false, otpInput: "", errorOtpMsg: "" });
  };

  handleOTPChange = (otps: string) => {
    this.setState({
      otpInput: otps,
      errorOtpMsg: "",
    });
  }


  handleUserProfile = () => {
    if (!this.validateFirstName()) {
      return;
    }
    if (!this.validateLastName()) {
      return;
    }
    if (!this.validateEmail()) {
      return;
    }

    if (!this.validatePhoneNumber()) {
      return;
    }

    this.changePasswordAPICall();
  };

  handleEmail = (emailVal: string) => {
    const email = emailVal;
    this.setState({
      email: email,
      errorsMessage: {
        emailMessage: "",
        errorEmail: false
      }
    });
  };

  generateClassName = (
    error: string | undefined,
    baseClassName: string,
    errorClassName: string
  ) => {
    return error ? errorClassName : baseClassName;
  };

  handleFirst = (event: string) => {
    const firstName = event;
    this.setState({
      firstName: firstName.replace(/\d/g, ''),
      errorsMessage: {
        firstNameMessage: "",
        errorFirstName: false
      }
    });
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End