import React from "react";
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDKfWx3FsxNitqFVJfFUjkto1icvh9sglE",
    authDomain: "stratafm-6bbf7.firebaseapp.com",
    projectId: "stratafm-6bbf7",
    storageBucket: "stratafm-6bbf7.appspot.com",
    messagingSenderId: "403281525645",
    appId: "1:403281525645:web:74db7a5ddb63612cdd1bc4",
    measurementId: "G-7YGV1PR2ZH"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const getFirebaseToken = async () => {
  try {
    const res = await getToken(messaging, {
      vapidKey:
        "BLxwqQBlEB39DiAgm4Ehz63XvE8kvOEisQm7lCT7ORbzqkGMCy3dtpamwXU1qX9cOgEET05B0wB41aRFcJlvLgo",
    });
    return res;
  } catch (e) {
    console.log(e);
    return "";
  }
};

const displayToast = (title, body) => {
  return (
    <div>
      <strong>{title}</strong>
      <div style={{ "paddingTop": "10px" }}>{body}</div>
    </div>
  )
};
export { firebaseApp, getFirebaseToken, onMessageListener, displayToast };