import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
export const configJSON = require("./config.js");
interface INavigateTo {
    productId: string | undefined;
    props: unknown;
    screenName: string;
    raiseMessage?: Message;
}
// Customizable Area End
export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    numberData: string,
    minimumPrice: string,
    maximumPrice: string,
    moreDetails: string,
    colourPreference: string,
    uploadedImages: string[],
    navigationId: number | string,
    openConfirmation: boolean,
    selectedFile: (File | null | Blob)[];
    combineImg: (string | null)[];
    errors: {
        numberData: string,
        colourPreference: string,
        minimumPrice: string,
        maximumPrice: string,
        moreDetails: string,
        selectedFile: string,
        minMaxError: string
    }
    editReqId: string;
    existingImage: any, 
    // Customizable Area End
}
interface SS {
    navigation: any;
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    hireStylistProfilePostApi: string = "";
    hireStylistCustomFormPostApi: string = "";
    showHireStylistApi: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            // Customizable Area Start
            colourPreference: '',
            numberData: '',
            minimumPrice: '',
            maximumPrice: '',
            moreDetails: '',
            uploadedImages: [],
            navigationId: '',
            token: '',
            openConfirmation: false,
            selectedFile: [],
            combineImg: [],
            errors: {
                numberData: '',
                colourPreference: '',
                minimumPrice: '',
                maximumPrice: '',
                moreDetails: '',
                selectedFile: '',
                minMaxError: ''
            }, 
            editReqId: "", 
            existingImage: [], 
            // Customizable Area End
        };
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        // Customizable Area End
    }



    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (!responseJson || responseJson.errors) {
                return;
            }
        
            const { attributes } = responseJson.data;
            const { editReqId } = this.state;
        
            if (apiRequestCallId === this.showHireStylistApi) {
                this.updateStylistAttributes(attributes);
              }
              else if (apiRequestCallId === this.hireStylistProfilePostApi && this.hireStylistProfilePostApi !== null) {
                this.handleStylistPostResponse(attributes, editReqId);
              }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const idData = this.props.navigation.getParam("navigationBarTitleText");
        const userToken = await getStorageData('auth-token');
        this.setState({ token: userToken, navigationId: idData });
        const url = window.location.href; 
        if (url.includes('editReq')) {
            const reqId = url.split('editReq')[1]; 
            this.setState({ editReqId: reqId })
            this.showHireStylist(reqId)
        } 
    }

    updateStylistAttributes = (attributes: {
        colour: string;
        gender: string;
        min_price: number;
        max_price: number;
        detail: string;
        images: { url: string }[];
      }) => {
        this.setState({
          colourPreference: attributes.colour,
          numberData: this.capitalize(attributes.gender),
          minimumPrice: attributes.min_price.toString(),
          maximumPrice: attributes.max_price.toString(),
          moreDetails: attributes.detail,
          existingImage: attributes.images[0]?.url || '',
          combineImg: attributes.images[0]?.url ? [attributes.images[0]?.url] : [],
        });
    };

    handleStylistPostResponse = (attributes: { created_at: string }, editReqId: string) => {
        if (attributes.created_at && !editReqId) {
          this.setState({ openConfirmation: true });
        } else {
          this.hiredStylistPage();
        }
    };

    capitalize = (str:string) => str.charAt(0).toUpperCase() + str.slice(1);


    hireStylistProfile = async () => {
        const { numberData, colourPreference, minimumPrice, maximumPrice, moreDetails, selectedFile, editReqId } = this.state;
        const errors = {
            numberData: '',
            colourPreference: '',
            minimumPrice: '',
            maximumPrice: '',
            moreDetails: '',
            minMaxError: '',
            selectedFile: ''
        };
        let hasErrors = false;
        if (!numberData) {
            errors.numberData = '*Gender is required.';
            hasErrors = true;
        }
        if (!colourPreference) {
            errors.colourPreference = '*Color preference is required.';
            hasErrors = true;
        }
        if (!minimumPrice) {
            errors.minimumPrice = '*Min price is required.';
            hasErrors = true;
        }
        if (!maximumPrice) {
            errors.maximumPrice = '*Max price is required.';
            hasErrors = true;
        }
        if (!moreDetails) {
            errors.moreDetails = '*Details are required.';
            hasErrors = true;
        }
        if (selectedFile.length === 0) {
            errors.selectedFile = '*Image is required.';
            hasErrors = true;
        }
        if (parseFloat(maximumPrice) < parseFloat(minimumPrice)) {
            errors.minMaxError = '*Minimum Price should be less than Maximum Price';
            hasErrors = true;
        }
        if (hasErrors) {
            this.setState({ errors });
            return;
        }
        const headers = {
            "token": this.state.token,
        };
        const formData = new FormData();
        !editReqId && formData.append("stylist_id", Number(this.state.navigationId).toString());
        formData.append("gender", this.state.numberData);
        formData.append("colour", this.state.colourPreference);
        formData.append("detail", this.state.moreDetails);
        formData.append("min_price", this.state.minimumPrice);
        formData.append("max_price", this.state.maximumPrice);
        this.state.selectedFile.forEach((itemsImg: string | Blob | null) => {
            itemsImg && formData.append("images", itemsImg);
        })
        const requestMessageList = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.hireStylistProfilePostApi = requestMessageList.messageId;
        requestMessageList.addData(getName(MessageEnum.RestAPIRequestMethodMessage), editReqId ? "PUT" : configJSON.exampleAPiMethod);
        requestMessageList.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), editReqId ? `/bx_block_custom_form/hire_stylist_custom_forms/${editReqId}` : configJSON.stylistCustomForms);
        requestMessageList.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageList.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
        return true;
    };

    handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ numberData: event.target.value as string });
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        let updatedValue: string | number = value;
        if (['maximumPrice', 'minimumPrice'].includes(name)) {
            const numberValue = parseFloat(value);
            updatedValue = !isNaN(numberValue) ? numberValue : '';
        }
        this.setState((prevState) => ({
            ...prevState,
            [name]: updatedValue,
        }));
    };

    openPictureUpload = () => {
        const inputAddPensionOne = document.createElement('input');
        inputAddPensionOne.type = 'file';
        inputAddPensionOne.accept = "image/png, image/jpeg, iamge/jpg";
        inputAddPensionOne.multiple = false
        inputAddPensionOne.click();
        inputAddPensionOne.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
            this.openPictureUploadInSideMain(event);
        }
    }

    openPictureUploadInSideMain = async (event: React.ChangeEvent<HTMLInputElement> | Event) => {
        const target = event.target as HTMLInputElement;
        const files = target.files;
        if (!files || files.length === 0) {
            return;
        }
        const file = files[0];
        const base64 = URL.createObjectURL(file);
        if (this.state.editReqId) {
            this.setState({
                selectedFile: [file], 
                combineImg: [base64]
            });
        } else {
            this.setState({
                selectedFile: [...this.state.selectedFile, file],
                combineImg: [...this.state.combineImg, base64]
            })
        }
    }

    handleRemoveMain = (index: number) => {
        const newDoument = [...this.state.selectedFile]
        const newBlob = [...this.state.combineImg]
        newDoument.splice(index, 1)
        newBlob.splice(index, 1)
        this.setState({ selectedFile: newDoument, combineImg: newBlob })
    };

    hiredStylistPage = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "HiredStylist");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
  
    hiredStylistContinueShopping = ({
        productId,
        props,
        screenName,
    }: INavigateTo) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
        runEngine.sendMessage(message.id, message);
    }

    showHireStylist = async (id: string) => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };
        const requestMessageFavorite = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.showHireStylistApi = requestMessageFavorite.messageId;
        requestMessageFavorite.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessageFavorite.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        requestMessageFavorite.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_form/hire_stylist_custom_forms/${this.state.editReqId}`);
        runEngine.sendMessage(requestMessageFavorite.id, requestMessageFavorite);

        return true;
    };
    tranHireStylist = (transKey: string) => {
        return i18n.t(transKey, {ns: "hiredStylist"});
      }
    // Customizable Area End
}