import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start;
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  enableField: boolean;
  fullName: string;
  token: string;
  // Customizable Area Start
  transactionsRecords:  {
    type: string;
    orderNumber: string,
    itemName: string,
    points: string
  }[],
  loyaltyPage: boolean;
  redeemPoints: {
    primryHeading: string,
    secondHeading: string,
    points: string
  }[];
  redeemDialogOpen: boolean;
  congratulationDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoyaltysystemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
       enableField: false,
       // Customizable Area Start
       fullName: "",
       token: "",
      transactionsRecords: [{
        type: "credited",
        orderNumber: "Order #4564-4512",
        itemName: "Sept 10,2023 | Black Leather Boots",
        points: "$120"
      },
      {
        type: "credited",
        orderNumber: "Order #4560-4512",
        itemName: "Sept 12,2023 | Black Oversized T-shirt",
        points: "$100"
      },
      {
        type: "redeemed",
        orderNumber: "Redeemed, Order #6548-8970",
        itemName: "Sept 10,2023 | Black Leather Boots",
        points: "$100"
      }, {
        type: "credited",
        orderNumber: "Order #4560-4512",
        itemName: "Sept 12,2023 | Black Oversized T-shirt",
        points: "$100"
      },
      {
        type: "redeemed",
        orderNumber: "Redeemed, Order #6548-8970",
        itemName: "Sept 10,2023 | Black Leather Boots",
        points: "$100"
      }
      ], 
      loyaltyPage: false,
      redeemPoints: [{
        primryHeading: "Free delivery on your next order",
        secondHeading: "Claim offer using",
        points: "200 points"
      },{
        primryHeading: "5% off on your next order",
        secondHeading: "Claim offer using",
        points: "500 points"
      },{
        primryHeading: "2 free deliveries",
        secondHeading: "Claim offer using",
        points: "375 points"
      }, {
        primryHeading: "10 free deliveries",
        secondHeading: "Claim offer using",
        points: "1500 points"
      }],
      redeemDialogOpen: false,
      congratulationDialog: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  async componentDidMount() {
    // Customizable Area Start
    const name = await getStorageData('user_data', true);
    const authToken = await getStorageData('auth-token');

    this.setState({
      fullName: `${name?.first_name} ${name?.last_name}`,
      token: authToken
    });
    // Customizable Area End
  }
  handleYesClick = () => {
    this.setState({
      congratulationDialog: true,redeemDialogOpen: false
    })
  }
  handleLoyalty = () => {
    this.setState((prevState) => ({
      loyaltyPage: !prevState.loyaltyPage
    }));
  };

  handleRedeemDialogClose = () => {
    this.setState({redeemDialogOpen: false})
  };

  handleCongratulationClose = () => {
    this.setState({congratulationDialog: false})
  }

  openLandingPage = () => {
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    msgs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgs);
  };
  // Customizable Area End
}
