import React from "react";

// Customizable Area Start
import {
  styled, Grid,
  Box, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, CircularProgress, Typography,Drawer,Divider
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import ReturnDetailController, {
  Props,
  SellerOrderSeller,
  OrderItemSeller
} from "./ReturnDetailController.web";
import { Noimageavailablepng, backBlueArrowImg ,message,calls} from "./assets";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import moment from "moment";
import { statusContainerReturnData, statusContainerTrackDriver } from "../../../components/src/Seller/SellerStatusPopup";
import { withScriptjs } from 'react-google-maps';
import TrackMap from "../../../components/src/TrackMap";
import i18n from "../../../components/src/i18next/i18n";
export const configJSON = require("./config");
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ReturnDetail extends ReturnDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleReturnDetailsImgCSS = () =>{
    return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  handleAnchor = () => {
    return i18n.dir() === "rtl" ? "left" : "right"
  }
  getOrderDetailData = (orders: SellerOrderSeller[]) => {
    return orders?.find(
      (order: SellerOrderSeller) =>
        order?.attributes.order_management_order?.attributes.order_number === this.state.productDetailId
    );
  };
  statusShow = (statuses: any) => {
    if (!statuses?.attributes) return null;

    const status = statuses.attributes.status;

    switch (status) {
      case 'return_request':
        return (
          <Box className="container">
            <button className="readyrequestButton">{this.tranReturnDetail("Return Request")}</button>
          </Box>
        );
      case 'return_in_process':
        return (
          <Box display="flex" alignItems="center">
            <button className="readyinProgressButton">{this.tranReturnDetail("Return In Process")}</button>
          </Box>
        );
      case 'return_under_process':
        return (
          <Box display="flex" alignItems="center">
            <button className="readyUnderPButton">{this.tranReturnDetail("Refund Under Process")}</button>
          </Box>
        );
      case 'refunded':
        return (
          <Box display="flex" alignItems="center">
            <button className="readyRefundedButton">{this.tranReturnDetail("Refunded")}</button>
          </Box>
        );
      default:
        return null;
    }
  };

  renderTableBody = (statuses: any) => {
    if (!statuses?.attributes) return null;
    const status = statuses.attributes.status;

    switch (status) {
      case 'return_request':
        return (

          <TableBody>

            {this.state.SellerOrdersProcessedView?.map((order: SellerOrderSeller, index: number) => (
              <React.Fragment key={index}>
                {order.attributes.order_items.map((orderItem: OrderItemSeller, itemIndex: number) => (
                  <TableRow key={itemIndex}>
                    <TableCell>
                      <Box style={webStyle.productDflex}>
                        <Box>
                          <img
                            src={orderItem.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : orderItem.attributes.catalogue_variant_front_image}
                            style={webStyle.productIMg}
                          />
                        </Box>
                        <Box style={{ ...webStyle.tableHaderrowReady1 }}>
                        {orderItem.attributes.catalogue_name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady1 }}>
                    <Typography className="body-typography">{orderItem.attributes.brand_name}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady1 }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_size}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady1 }}>
                      <Box style={webStyle.tableVarientColorMainBoxReady1}>
                        <Box
                          style={{ ...webStyle.tableVarientColorBoxReady, backgroundColor: orderItem.attributes.catalogue_variant_color }}
                        />
                        <Typography className="body-typography">
                        {orderItem.attributes.catalogue_variant_color}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady1 }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_sku}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady1 }}>
                      <Typography className="body-typography">{orderItem.attributes.currency_logo}{orderItem.attributes.total_price}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}


          </TableBody>
        );
      case 'return_in_process':
        return (
          <TableBody>
            {this.state.SellerOrdersProcessedViewProcess?.map((order1: SellerOrderSeller, index: number) => (
              <React.Fragment key={index}>
                {order1.attributes.order_items.map((orderItem: OrderItemSeller, itemIndex: number) => (
                  <TableRow key={itemIndex}>
                    <TableCell>
                      <Box style={webStyle.productDflex}>
                        <Box>
                          <img src={orderItem.attributes.catalogue_variant_front_image}
                            style={webStyle.productIMg}
                          />
                        </Box>
                        <Box style={{ ...webStyle.tableHaderrowReady2 }}>
                          {orderItem.attributes.catalogue_name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady2 }}>
                    <Typography className="body-typography">{orderItem.attributes.brand_name}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady2 }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_size}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady2 }}>
                      <Box style={{
                        display: "flex",
                        alignItems: "center"
                      }}>
                        <Box
                          style={{ ...webStyle.tableVarientColorBoxReady, backgroundColor: orderItem.attributes.catalogue_variant_color }}
                        />
                        <Typography className="body-typography">
                          {orderItem.attributes.catalogue_variant_color}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady2 }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_sku}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady2 }}>
                    <Typography className="body-typography">{orderItem.attributes.currency_logo}{orderItem.attributes.total_price}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        );
      case 'return_under_process':
        return (
          <TableBody>

            {this.state.SellerUnderProcess?.map((order3: SellerOrderSeller, index: number) => (
              <React.Fragment key={index}>
                {order3.attributes.order_items.map((orderItem: OrderItemSeller, itemIndex: number) => (
                  <TableRow key={itemIndex}>
                    <TableCell>
                      <Box style={webStyle.productDflex}>
                        <Box>
                          <img
                            src={orderItem.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : orderItem.attributes.catalogue_variant_front_image}
                            style={webStyle.productIMg}
                          />
                        </Box>
                        <Box style={{
                          fontWeight: 400,
                          color: "#375280",
                          fontSize: '16px',
                        }}>
                          {orderItem.attributes.catalogue_name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{
                      color: "#375280",
                      fontWeight: 400,
                      fontSize: '16px',
                    }}>
                      <Typography className="body-typography">{orderItem.attributes.brand_name}</Typography>
                    </TableCell>
                    <TableCell style={{
                      fontWeight: 400,
                      fontSize: '16px',
                      color: "#375280",
                    }}>
                      <Typography className="body-typography">{orderItem.attributes.catalogue_variant_size}</Typography>
                    </TableCell>
                    <TableCell style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: "#375280",
                    }}>
                      <Box style={webStyle.tableVarientColorMainBoxReady}>
                        <Box
                          style={{backgroundColor: orderItem.attributes.catalogue_variant_color, ...webStyle.tableVarientColorBoxReady,  }}
                        />
                        <Typography className="body-typography">
                        {orderItem.attributes.catalogue_variant_color}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_sku}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                    <Typography className="body-typography">{orderItem.attributes.currency_logo}{orderItem.attributes.total_price}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        );
      case 'refunded':
        return (

          <TableBody>
            {this.state.SellerRefunded?.map((order4: SellerOrderSeller, index: number) => (
              <React.Fragment key={index}>
                {order4.attributes.order_items.map((orderItem: OrderItemSeller, itemIndex: number) => (
                  <TableRow key={itemIndex}>
                    <TableCell>
                      <Box style={webStyle.productDflex}>
                        <Box>
                          <img
                            src={orderItem.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : orderItem.attributes.catalogue_variant_front_image}
                            style={webStyle.productIMg}
                          />
                        </Box>
                        <Box style={{ ...webStyle.tableHaderrowReady }}>
                        {orderItem.attributes.catalogue_name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                    <Typography className="body-typography">{orderItem.attributes.brand_name}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_size}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                      <Box style={{
                        alignItems: "center",
                        display: "flex",
                        gap: 8
                      }}>
                        <Box
                          style={{ ...webStyle.tableVarientColorBoxReady, backgroundColor: orderItem.attributes.catalogue_variant_color }}
                        />
                        <Typography className="body-typography">
                        {orderItem.attributes.catalogue_variant_color}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady3 }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_sku}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady3 }}>
                    <Typography className="body-typography">{orderItem.attributes.currency_logo}{orderItem.attributes.total_price}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>

        );
      default:
        return null;
    }
  };
  // Customizable Area End
  render() {
    const orderDetailData = this.getOrderDetailData(this.state.SellerOrdersProcessedView)
      || this.getOrderDetailData(this.state.SellerOrdersProcessedViewProcess)
      || this.getOrderDetailData(this.state.SellerUnderProcess)
      || this.getOrderDetailData(this.state.SellerRefunded);
      const MapLoader = withScriptjs(TrackMap);
    return (
      <>
      <ThemeProvider theme={theme}>
        <Grid container dir={i18n.dir()}>
          <Grid item xl={2} md={3} lg={2} sm={3} xs={3}>
            <SellerSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid item xl={10} md={9} lg={10} sm={9} xs={9}>
            <SellerHeader navigation={this.props.navigation} />
            {this.state.isLoadingOut ? (
              <Box style={{ ...webStyle.tabsBoxReady }}>
                <CircularProgress style={webStyle.CircularProgressColor} />
              </Box>
            ) : (
              <>
                <Box style={webStyle.readyForCollectionTableContainer}>
                  <GroupDetailBox>
                    <Box display="block" alignItems="center">
                      <Box className="GroupDetailBoxLeft">
                        <Box data-test-id="proceedToAllOrderPage" onClick={this.proceedToAllOrderPage}>
                          <img src={backBlueArrowImg} style={{transform: this.handleReturnDetailsImgCSS()}} alt="" />
                        </Box>
                        <Typography className="itemIdText">
                          #{orderDetailData?.attributes.order_management_order.attributes.order_number}
                        </Typography>

                        {this.statusShow(orderDetailData)}

                      </Box>
                    </Box>
                    <Box style={{display:'flex',gap:'10px'}}>
                    <Box display="flex" alignItems="center" style={{gap:'10px'}}>
                      <button className="readyForStatusButton" data-testid="toggleStatusDrawer" onClick={this.toggleStatusDrawer(true,orderDetailData?.attributes.id)}>{this.tranReturnDetail("Order Status")}</button>
                      <button disabled={!this.state.orderData.return_pick_at} className="readyForTrackButtonData" data-testid="toggleStatusDataOrder"onClick={this.toggleStatusData(true,orderDetailData?.attributes.id)}>{this.tranReturnDetail("Track Driver")}</button>
                    </Box>
                    </Box>
                  </GroupDetailBox>
                  <Box>
                    <Typography style={webStyle.itemIdTextDescription}>
                      {this.tranReturnDetail("Total Products")}: {orderDetailData?.attributes.order_items[0].attributes.quantity} | {this.tranReturnDetail("Purchase Date")}: {moment(orderDetailData?.attributes.order_items[0].attributes.placed_at as string).format("D MMM, YYYY")}
                    </Typography>
                  </Box>
                  <Box style={webStyle.ArrivingCell2}>
                    <Typography variant="body1" style={webStyle.ArrivingText as React.CSSProperties}>{this.tranReturnDetail("Reason of product rejection")} : <span style={webStyle.ArrivingTextValue}>{orderDetailData?.attributes.order_items[0].attributes?.reason_of_return}</span></Typography>
                  </Box>

                  <GroupOtherDetails className="container">
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                          {this.tranReturnDetail("Store Name")}
                        </Typography>
                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_items[0].attributes.store_name}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                        {this.tranReturnDetail("Customer Name")}
                        </Typography>
                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_management_order.attributes.account}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                        {this.tranReturnDetail("Delivery Date")}
                        </Typography>
                        <Typography className="detailTitleDescription">{moment(orderDetailData?.attributes.order_management_order.attributes.order_deliver_date as string).format("D MMM, YYYY")}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                        {this.tranReturnDetail("Shipping Address")}
                        </Typography>
                        <Typography className="detailTitleDescription">
                          {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.house_or_building_number}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.block} {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.area}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.street}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.city} - {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.zip_code}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                        {this.tranReturnDetail("Coupon Applied")}
                        </Typography>
                        <Typography className="detailTitleDescription">{this.tranReturnDetail("Fash")}10(-$5)</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                        {this.tranReturnDetail("Payment Method")}
                        </Typography>
                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_management_order.attributes.payment_detail?.payment_type}</Typography>
                      </Grid>
                    </Grid>
                  </GroupOtherDetails>

                  <CustomStyledTable className="container">
                    <Table stickyHeader size="small" className="custom-table">
                      <TableHead>
                        <TableRow style={webStyle.tableHeaderBg}>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Product")}</Typography></TableCell>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Brand")}</Typography></TableCell>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Size")}</Typography></TableCell>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Color")}</Typography></TableCell>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Product Code")}</Typography></TableCell>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Price")}</Typography></TableCell>
                        </TableRow>
                      </TableHead>
                      {this.renderTableBody(orderDetailData)}

                    </Table>
                  </CustomStyledTable>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
        <Drawer anchor={this.handleAnchor()} open={this.state.isDrawerStatusOpen} dir={i18n.dir()} onClose={this.toggleStatusDrawer(false)}>
          <Box style={webStyle.MapComponentContainer}>
            <Box style={webStyle.mapHeaderText}>
              <Box style={webStyle.mapTitleContainerText}>
                <Typography style={webStyle.trackDriveTitle}>{this.tranReturnDetail("Order Status")}</Typography>
                <CloseIcon role="presentation"
                  onClick={this.toggleStatusDrawerClose(this.state.orderDataId)}
                  onKeyDown={this.toggleStatusDrawerClose(this.state.orderDataId)} />
              </Box>
            </Box>
            <Box style={webStyle.trackContentStatusContainer}>
              {statusContainerReturnData(this.state.orderData)}
            </Box>
          </Box>
        </Drawer>
        <Drawer anchor={this.handleAnchor()} open={this.state.isDrawerStatus} dir={i18n.dir()} onClose={this.toggleStatusData(false)}>
          <Box style={webStyle.MapComponentContainer}>
            <Box style={webStyle.mapHeaderText}>
              <Box style={webStyle.mapTitleContainerText}>
                <Typography style={webStyle.trackDriveTitle}>{this.tranReturnDetail("Track Driver")}</Typography>
                <CloseIcon role="presentation"
                  onClick={this.toggleStatusDataClose(this.state.orderDataId)}
                  onKeyDown={this.toggleStatusDataClose(this.state.orderDataId)} />
              </Box>
            </Box>
            <Box style={{ height: '449px' }}>
              <div>
                <MapLoader
                  googleMapURL={configJSON.mapApk}
                  loadingElement={<div style={{ width: "200px", height: "400px" }} className="MapComponentHeight" />}
                  origin={this.state.latitudeData}
                  destination={this.state.longitudeData}
                />
              </div>
              <Box style={{ padding: '25px' }}>
                <Box style={webStyle.delivedTime}>
                  <Typography style={webStyle.driverName}>{this.state.sellerDriverName}</Typography>
                  {this.state.orderData.estimated_delivery_time === null ? null : <Typography style={webStyle.driverName}>{this.tranReturnDetail("Delivering in")} : {this.state.orderData.estimated_delivery_time}</Typography>}
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', margin: '24px 0px' }}>
                  <Box style={webStyle.otpData}>{this.state.orderData.otp === null ? "****" : this.state.orderData.otp} - {this.tranReturnDetail("OTP")}</Box>
                  <Box style={{ display: 'flex', justifyContent: 'space-between', gap:12 }}>
                    <Box><img src={message} /></Box>
                    <Box><img src={calls} /></Box>
                  </Box>
                </Box>
                <Divider style={{ marginBottom: '20px' }} />
                {statusContainerTrackDriver(this.state.orderData)}
              </Box>
            </Box>
          </Box>
        </Drawer>
      </>
    );
  }
}
// Customizable Area Start
const webStyle = {
  delivedTime:{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#F4F4F4', padding: '6px 16px', borderRadius: '2px' },
  treckOrder:{ backgroundColor: '#CCBEB1', padding: '15px', width: '100%', color: '#FFFFFF', fontFamily: 'Lato', fontWeight: 800, fontSize: '20px'},
  driverNameSpase:{ color: '#375280', fontFamily: 'Lato', fontWeight: 700, fontSize: '16px' },
  otpData:{backgroundColor: '#CCBEB1', padding: '13px 20px', color: "#FFFFFF", fontWeight: 700, fontSize: '14px', fontFamily: 'Lato',display:'flex',alignItems:'center'},
  driverName:{ color: '#375280', fontFamily: 'Lato', fontWeight: 700, fontSize: '14px' },
  trackDriveTitle: {
    fontSize: "24px",
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#375280",
  },
  trackContentStatusContainer: {
    padding: '10px 25px 80px 25px'
  },
  mapTitleContainerText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
mapHeaderText: {
    padding: 25
  },
  fontFamily: "Lato",
  tableHaderReady: {
    fontSize: '16px',
    color: "#375280",
    fontWeight: 700,
    letterSpacing: "1px",
    WebkitTextStrokeWidth: "0.5px",
  },
  tableHaderrowReady: {
    fontSize: '16px',
    color: "#375280",
    fontWeight: 400,
  },
  tableHaderrowReady1: {
    fontWeight: 400,
    color: "#375280",
    fontSize: '16px',
  },
  tableHaderrowReady2: {
    color: "#375280",
    fontWeight: 400,
    fontSize: '16px',
  },
  tableHaderrowReady3: {
    color: "#375280",
    fontSize: '16px',
    fontWeight: 400,
  },
  tableVarientColorBoxReady: {
    width: '14px', height: '14px', borderRadius: '10px',
  },
  tableVarientColorMainBoxReady: {
    display: "flex",
    alignItems: "center", 
    gap: 8
  },
  tableVarientColorMainBoxReady1: {
    alignItems: "center",
    display: "flex",
  },
  tableHeaderBg: {
    backgroundColor: "#f0f0f0"
  },
  productDflex: {
    display: "flex",
    gap: 10
  },

  productIMg: {
    width: '34px',
    height: '34px',
    borderRadius: '2px',
    marginBottom: "12px",
  },
  ArrivingCell: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between"
  },
  ArrivingCell2: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px"
  },
  MapComponentContainer: {
    width: 440,
    height: "100%"
  },
  ArrivingText: {
    fontWeight: 400,
    color: "#375280",
    fontSize: "14px",
  },
  ArrivingTextValue: {
    fontWeight: 700,
    color: "#375280",
    fontSize: "14px",
    marginLeft: "8px"
  },
  readyForCollectionTableContainer: {
    padding: "20px 30px"
  },
  tabsBoxReady: {
    width: '100%',
    paddingBottom: '20px',
    height: '75%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  CircularProgressColor: {
    color: "#375280"
  },
  itemIdTextDescription: {
    color: "#9A9A9A",
    fontSize: "16px",
    fontWeight: 500,
    marginLeft: "32px"
  }
}
const GroupOtherDetails = styled(Box)({
  border: '1px solid #E2E8F0',
  borderRadius: "5px 5px 0px 0px",
  padding: "24px",
  margin: "25px 0px",
  "& .detailTitle": {
    color: "#375280",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .detailTitleDescription": {
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
    marginTop: "10px"
  }
})
const CustomStyledTable = styled(TableContainer)({
  "& .radyToOrderStatusBtn": {
    width: "147px",
    color: "rgb(55, 82, 128)",
    border: "1px solid rgb(204, 190, 177)",
    fontWeight: 700,
    height: "34px",
    fontSize: "12px",
    backgroundColor: "#ffffff",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .MuiTableCell-sizeSmall': {
    padding: "15px 30px 15px 30px"
  },
  "& .table-header": {
    fontSize: '16px',
    color: "#375280",
    fontWeight: 700,
    letterSpacing: "1px",
    fontFamily: "Lato",
    WebkitTextStrokeWidth: "0.5px",
    display: 'flex'
  },
  "& .body-typography": {
    display: 'flex',
    fontWeight: 400,
    color: "#375280",
    fontSize: '16px',
    font: "Lato"
  },
  '& .custom-table': {
    borderRadius: "5px 5px 0px 0px",
    border: '1px solid #E2E8F0',
  },
  "& .radyToCollectionStatusBtn": {
    width: "147px",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#FEF3C7",
    textTransform: "none",
    borderRadius: "2px",
  },
  "& .radyToArrivingBox": {
    width: "100%",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#F4F4F4",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .cell': {
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: '#ffffff',
  },
})
const GroupDetailBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0px",
  fontFamily: "Lato",
  "& .GroupDetailBoxLeft": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20
  },
  "& .itemIdText": {
    color: "#375280",
    fontSize: "24px",
    fontWeight: 700,
  },
  "& .readyForStatusButton": {
    color: "rgb(55, 82, 128)",
    width: "150px",
    border: "1px solid rgb(204, 190, 177)",
    height: "44px",
    fontWeight: 500,
    fontSize: "16px",
    borderRadius: "2px",
    backgroundColor: "#ffffff",
    textTransform: "none",
  },
  "& .readyForTrackButton": {
    color: "rgb(55, 82, 128)",
    width: "150px",
    border: "1px solid rgb(204, 190, 177)",
    height: "44px",
    fontWeight: 500,
    fontSize: "16px",
    borderRadius: "2px",
    backgroundColor: "#ffffff",
    textTransform: "none",
  },
  "& .readyForStatusButton1": {
    width: "150px",
    height: "44px",
    border: "1px solid rgb(204, 190, 177)",
    fontWeight: 700,
    color: "#FFFFFF",
    fontSize: "16px",
    borderRadius: "2px",
    textTransform: "none",
    backgroundColor: "#CCBEB1",
    fontFamily: "Lato",
  },
  '& .readyrequestButton': {
    textTransform: 'none',
    borderRadius: '2px',
    fontWeight: 500,
    fontSize: '16px',
    border: '1px solid white',
    height: '44px',
    backgroundColor: '#0A84FF1A',
    marginLeft: '16px',
    padding: '6px 10px 6px 10px',
    width: '150px',
    color: '#0A84FF',
  },
  '& .readyUnderPButton': {
    padding: '6px 10px 6px 10px',
    border: '1px solid white',
    fontSize: '16px',
    borderRadius: '2px',
    color: '#F545C4',
    textTransform: 'none',
    backgroundColor: '#F545C41A',
    fontWeight: 500,
    marginLeft: '16px',
    height: '44px',
    width: '150px',
  },
  '& .readyinProgressButton': {
    backgroundColor: '#B55B521A',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '16px',
    borderRadius: '2px',
    height: '44px',
    border: '1px solid white',
    padding: '6px 10px 6px 10px',
    color: '#B55B52',
  },
 
  '& .readyRefundedButton': {
    borderRadius: '2px',
    color: '#039000',
    backgroundColor: '#E1EFE1',
    padding: '6px 10px 6px 10px',
    width: '150px',
    textTransform: 'none',
    border: '1px solid white',
    marginLeft: '16px',
    fontWeight: 500,
    height: '44px',
    fontSize: '16px',
  },
  "& .readyForTrackButtonData": {
    color: "#FFFFFF",
    width: "150px",
    border: "none",
    height: "44px",
    fontWeight: 500,
    fontSize: "16px",
    borderRadius: "2px",
    backgroundColor: "#CCBEB1",
    textTransform: "none",
    marginLeft: "10px",
    padding:"10px 16px"
  },
  "& .outForDeliveryStatusTopBtn": {
    color: "#6200EA",
    width: "147px",
    border: "none",
    height: "30px",
    fontWeight: 500,
    textAlign: "center",
    marginLeft: "15px",
    fontSize: "12px",
    borderRadius: "2px",
    backgroundColor: "#F0E5FF",
    textTransform: "none",
  }
})
// Customizable Area End
