export const enStylist = {
    "Home": "Home",
    "Stylist": "Stylist",
    "Welcome to your Account": "Welcome to your Account",
    "Favorite Stylist": "Favourite Stylist",
    "Your Stylist wishlist is empty.": "Your Stylist wishlist is empty.",
    "Continue": "Continue",
    "Empty Stylist Message": "You don't have any stylist in the favourite yet. You will find a lot of stylish on our Stylish page.",
    "Create Profile": "Create Profile",
    "Profile Setup": "Profile Setup",
    "Contact & Payment": "Contact & Payment",
    "Portfolio": "Portfolio",
    "Upload Profile Image": "Upload Profile Image",
    "Replace Photo": "Replace Photo",
    "Profile Bio": "Profile Bio",
    "Area of Expertise": "Area of Expertise",
    "Years of Experience": "Years of Experience",
    "0 Years": "0 Years",
    "Social Media Links": "Social Media Links",
    "Preferred Language": "Preferred Language",
    "Select Language": "Select Language",
    "Arabic": "Arabic",
    "English": "English",
    "Please choose a language": "Please choose a language",
    "Back": "Back",
    "Next": "Next",
    "Please enter your profile bio": "Please enter your profile bio",
    "Please enter Area of Expertise": "Please enter Area of Expertise",
    "Please enter Your Years of Experience": "Please enter Your Years of Experience",
    "Your protfolio has been updated": "Your protfolio has been updated",
    "Address": "Address",
    "Area": "Area",
    "Block": "Block",
    "Mall Name (Optional)": "Mall Name (Optional)",
    "Floor  (Optional)": "Floor  (Optional)",
    "Unit Number  (Optional)": "Unit Number  (Optional)",
    "City": "City",
    "Zip Code": "Zip Code",
    "Instructions for driver to reach store": "Instructions for driver to reach store",
    "Phone Number": "Phone Number",
    "Bank Account": "Bank Account",
    "Account Name": "Account Name",
    "IBAN Number": "IBAN Number",
    "Account Number": "Account Number",
    "Google Pay": "Google Pay",
    "Google pay address": "Google pay address",
    "Verify": "Verify",
    "Apple Pay": "Apple Pay",
    "Apple Pay ID": "Apple Pay ID",
    "Please enter your Address": "Please enter your Address",
    "Please enter Account holder": "Please enter Account holder",
    "Please enter your IBAN Number": "Please enter your IBAN Number",
    "Please enter Account Number": "Please enter Account Number",
    "Please enter Your Contact Number": "Please enter Your Contact Number",
    "Please enter a valid contact number": "Please enter a valid contact number",
    "Upload Portfolio": "Upload Portfolio",
    "Skip": "Skip",
    "Upload Multiple Photos": "Upload Multiple Photos",
    "Only png or Jpg files are accepted": "Only png or Jpg files are accepted",
    "Photos": "Photos",
    "Description": "Description",
    "Create & Verify": "Create & Verify",
    "Civil ID/Passport": "Civil ID/Passport",
    "Upload png, jpg, jpeg or pdf": "Upload png, jpg, jpeg or pdf",
    'Passport is accepted for only "Kuwaitis". Required for all shareholders with 25% shares or more plus all authorized signatories as well': 'Passport is accepted for only "Kuwaitis". Required for all shareholders with 25% shares or more plus all authorized signatories as well',
    "Commercial License": "Commercial License",
    "Upload png, jpg, jpeg": "Upload png, jpg, jpeg",
    "Upload pdf": "Upload pdf",
    "Authorized Signatures": "Authorized Signatures",
    "MOA or AOA": "MOA or AOA",
    "Copy of the Memorandum of Association or Articles of association of shareholding entities with 25% shares or more is required.": "Copy of the Memorandum of Association or Articles of association of shareholding entities with 25% shares or more is required.",
    "Business Bank Account": "Business Bank Account",
    "The above info must be provided with a Stamped official Bank Certificate. It cannot be a personal bank account": "The above info must be provided with a Stamped official Bank Certificate. It cannot be a personal bank account",
    "Great!": "Great!",
    "Your Request to register as a Stylist has been submitted  successfully. You will get a confirmation in your email inbox soon.": "Your Request to register as a Stylist has been submitted  successfully. You will get a confirmation in your email inbox soon.",
    "is not a valid phone number": "is not a valid phone number",
    "IBAN Number is not a valid.": "IBAN Number is not a valid.",
    "exceeds the 10MB size limit.": "exceeds the 10MB size limit.",
    "has an invalid file type.": "has an invalid file type.",
    "You can only upload up to 10 files.": "You can only upload up to 10 files."
}

export const arStylist = {
    "Home": "الصفحة الرئيسية",
    "Welcome to your Account": "مرحبا بك في حسابك",
    "Stylist": "المصممون",
    "Favorite Stylist": "المصمم المفضل",
    "Your Stylist wishlist is empty.": "قائمة أمنيات المصمم الخاص بك فارغة.",
    "Continue": "يكمل",
    "Empty Stylist Message": "ليس لديك أي مصفف شعر في المفضلة حتى الآن. ستجد الكثير من الأناقة على صفحتنا الأنيقة.",
    "Create Profile": "إنشاء حساب",
    "Profile Setup": "إعدادات الحساب",
    "Contact & Payment": "التواصل والدفع",
    "Portfolio": "المعرض",
    "Upload Profile Image": "رفع صورة الحساب",
    "Replace Photo": "استبدال الصورة",
    "Profile Bio": "نبذة الحساب",
    "Area of Expertise": "مجالات الخبرة",
    "Years of Experience": "سنوات الخبرة",
    "0 Years": "عدد السنوات",
    "Social Media Links": "روابط مواقع التواصل الاجتماعي",
    "Preferred Language": "اللغة المفضلة",
    "Select Language": "تحدید اللغة",
    "Arabic": "عربي",
    "English": "إنجليزي",
    "Please choose a language": "الرجاء اختيار اللغة",
    "Back": "الرجوع",
    "Next": "التالي",
    "Please enter your proMOA or AOAen updated": "لقد تم تحديث محفظتك",
    "Address": "العنوان",
    "Area": "المنطقة*",
    "Block": "العمارة*",
    "Mall Name (Optional)": "اسم مركز التسوق (اختياري)",
    "Floor  (Optional)": "الطابق (اختياري)",
    "Unit Number  (Optional)": "رقم القطعة (اختياري)",
    "City": "المدینة",
    "Zip Code": "الرمز البریدي",
    "Instructions for driver to reach store": "توجیھات السائق لأجل الوصول للمتجر",
    "Phone Number": "رقم الھاتف",
    "Bank Account": "الحساب البنكي",
    "Account Name": "اسم صاحب الحساب",
    "IBAN Number": "رقم الآیبان",
    "Account Number": "رقم الحساب",
    "Google Pay": "جوجل باي",
    "Google pay address": "عنوان الجوجل باي",
    "Verify": "التأكید",
    "Apple Pay": "أبل باي",
    "Apple Pay ID": "معرف الأبل باي",
    "Please enter your Address": "الرجاء إدخال عنوانك",
    "Please enter Account holder": "الرجاء إدخال صاحب الحساب",
    "Please enter your IBAN Number": "الرجاء إدخال رقم IBAN الخاص بك",
    "Please enter Account Number": "الرجاء إدخال رقم الحساب",
    "Please enter Your Contact Number": "الرجاء إدخال رقم الاتصال الخاص بك",
    "Please enter a valid contact number": "الرجاء إدخال رقم اتصال صالح",
    "Upload Portfolio": "رفع المعرض",
    "Skip": "يتخطى",
    "Upload Multiple Photos": "رفع صور متعددة",
    "Only png or Jpg files are accepted": "یتم قبول ملفات png وjpg فقط",
    "Photos": "الصور",
    "Description": "وصف",
    "Create & Verify": "الإنشاء والتأكید",
    "Civil ID/Passport": "البطاقة المدنية / جواز السفر",
    "Upload png, jpg, jpeg or pdf": "رفع ملف png أو jpg أو jpeg أو pdf",
    'Passport is accepted for only "Kuwaitis". Required for all shareholders with 25% shares or more plus all authorized signatories as well': 'یقبل جواز السفر من الكویتیین فقط إلزامي لجمیع المساھمین الحائزین',
    "Commercial License": "رخصة تجارية",
    "Upload png, jpg, jpeg": "رفع ملف png أو jpg أو jpeg أو pdf",
    "Upload pdf": "رفع ملف PDF",
    "Authorized Signatures": "التوقيعات المعتمدة",
    "MOA or AOA": "MOA أو AOA",
    "Copy of the Memorandum of Association or Articles of association of shareholding entities with 25% shares or more is required.": "یلزم أصحاب الأسھم التي تبلغ 25% وأكثر بإلحاق نسخة من مذكرة الت",
    "Business Bank Account": "حساب بنكي تجاري",
    "The above info must be provided with a Stamped official Bank Certificate. It cannot be a personal bank account": "یشترط أن تلحق البیانات المذكورة أعلاه مع شھادة مختومة من البنك. ی",
    "Great!": "عظيم!",
    "Your Request to register as a Stylist has been submitted  successfully. You will get a confirmation in your email inbox soon.": "لقد تم تقديم طلبك للتسجيل كمصمم أزياء بنجاح. سوف تتلقى تأكيدًا في صندوق بريدك الإلكتروني قريبًا.",
    "is not a valid phone number": "ليس رقم هاتف صالح",
    "IBAN Number is not a valid.": "رقم IBAN غير صالح.",
    "exceeds the 10MB size limit.": "يتجاوز الحد الأقصى للحجم وهو 10 ميغابايت.",
    "has an invalid file type.": "يحتوي على نوع ملف غير صالح.",
    "You can only upload up to 10 files.": "يمكنك تحميل ما يصل إلى 10 ملفات فقط."
}