import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
import { setStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/APICall";
type country = string | CountrySuccess | null;

interface CreateStoreResponse{
  data: object;
}
interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
}
export interface ActiveBread {
  activeBread: "1" | "2" | "3";
}
export interface CountrySuccess {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}

export type DocumentType = 'authorized_signature' | 'moa' | 'business_bank_account' | 'passport' | 'license';

interface Account {
  id: number;
  first_name: string | null;
  last_name: string | null;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: number | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  stripe_id: string | null;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  role: string;
  full_name: string;
  is_verified: boolean | null;
  share_token: string | null;
  approve_status: string;
  seller_status: string;
  notification: {};
  customer_id: string | null;
  language: number;
  currency: number;
}

interface Meta {
  token: string;
  refresh_token: string;
  id: number;
  account: Account;
}

interface PostLoginResponse {
  meta: Meta;
}

interface ValidResponseType {
  message?: object;
  data: object;
  meta: object;
}

interface InvalidResponseType {
  errors: Array<string>;
}

type AlertType = 'success' | 'error' | 'warning' | 'info';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEmail: string;
  showPasswordValue: boolean;
  passwords: string;
  errorsMessage: {
    emailMessage?: string;
    passwordMessage?: string;
    errorEmail?: boolean;
    errorPassword?: boolean;
  };
  errors:any;
  isAlert: boolean;
  isCountryData: string;
  isCountryData1: string;
  alertMsg: string;
  alertType: AlertType;
  emailError: boolean;
  passError: boolean;
  dropdownOpen: boolean;
  dropdownOpen1: boolean;
  countrySuccess: Array<CountrySuccess>;
  selectedCountry: country;
  selectedCountry1: country;
  numericCode:country;
  numericCodeTwo:country;
  isSuccess: boolean;
  selectedFiles: {
    authorized_signature: File | null;
    moa: File | null;
    license: File | null;
    passport: File | null;
    business_bank_account: File | null;
  };

  formData: {
    full_name: string;
    email: string;
    account_holder_name: string;
    owner_zip_code: string;
    iban: string;
    owner_full_address: string;
    owner_contact_number: string;
    company_full_address: string;
    company_zip_code: string;
    company_contact_number: string;
    authorized_signature: string;
    passport: string;
    moa: string;
    business_bank_account: string;
    account_number: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountActiveProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailLoginFormAPICallId: string = "";
  countryCodeAPIEditCallId: string = '';
  AccountActiveApiCallId: string = '';
  sellerValidIBANAPICallId: string = "";
  sellerValidPhoneNumberAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    this.receive = this.receive.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),

    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      isEmail: "",
      showPasswordValue: false,
      passwords: "",
      errorsMessage: {
        emailMessage: "",
        passwordMessage: "",
        errorEmail: false,
        errorPassword: false,
      },
      isAlert: false,
      alertMsg: '',
      alertType: 'success' as AlertType,
      selectedCountry: null,
      selectedCountry1: null,
      numericCode:null,
      numericCodeTwo:null,
      emailError: false,
      passError: false,
      isCountryData: "+965",
      isCountryData1: "+965",
      dropdownOpen: false,
      dropdownOpen1: false,
      selectedFiles: {
        authorized_signature: null,
        moa: null,
        license: null,
        passport: null,
        business_bank_account: null,
      },
      errors:{},
      isSuccess: false,
      countrySuccess: [{
        numeric_code: "",
        country_full_name: "",
        country_code: "",
        country_flag: ""
      }],
      formData: {
        full_name: "",
        email: "",
        account_holder_name: "",
        iban: "",
        owner_full_address: "",
        owner_contact_number: "",
        company_full_address: "",
        authorized_signature: "",
        business_bank_account: "",
        company_zip_code: "",
        company_contact_number: "",
        passport: "",
        moa: "",
        account_number: "",
        owner_zip_code: "",
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson && !responseJson.errors) {
        this.apiSucessCall(apiRequestCallId, responseJson);
      }
      else if(responseJson && responseJson.errors){
        this.handleSellerInvalidToken(responseJson.errors[0].token)
    }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getCountryCodeEditApi();
  let owner_full_address=await this.fetchSellerDataFromStorage('owner_full_address');
  let owner_contact_number=await this.fetchSellerDataFromStorage('owner_contact_number');

  let company_full_address=await this.fetchSellerDataFromStorage('company_full_address');

  let company_zip_code=await this.fetchSellerDataFromStorage('company_zip_code');
  let company_contact_number=await this.fetchSellerDataFromStorage('company_contact_number');
  let owner_zip_code=await this.fetchSellerDataFromStorage('owner_zip_code');

  let full_name=await this.fetchSellerDataFromStorage('full_name');
  let email=await this.fetchSellerDataFromStorage('email');
  let account_holder_name=await this.fetchSellerDataFromStorage('account_holder_name');
  let iban=await this.fetchSellerDataFromStorage('iban');
  let account_number=await this.fetchSellerDataFromStorage('account_number');
  let isCountryNumber = await this.fetchCountryDataFromStorage('isCountryData')
  let countryFlge = await this.fetchSellerDataFromStorage('countryFlge')
  let numeri_code = await this.fetchCountryDataFromStorage('numericCode')

  let isCountryDataTwo = await this.fetchCountryDataFromStorage('isCountryData1')
  let countryFlgeTwo = await this.fetchSellerDataFromStorage('countryFlgeTwo')
  let numeri_code1Two = await this.fetchCountryDataFromStorage('numericCodeTwo')
  this.setState({formData:{...this.state.formData,owner_full_address,owner_contact_number,company_full_address,company_zip_code,owner_zip_code,company_contact_number,
    full_name,email,account_holder_name,
    iban,account_number
  },isCountryData:isCountryNumber,selectedCountry:countryFlge,numericCode:numeri_code,isCountryData1:isCountryDataTwo,selectedCountry1:countryFlgeTwo,numericCodeTwo:numeri_code1Two})

    // this.AccountActivation1();
    // Customizable Area End
  }

  // Customizable Area Start

  fetchSellerDataFromStorage = async (field: string) => {
    const formValue = await storage.get(field) || "";
    return formValue;
  };

  fetchCountryDataFromStorage = async (field: string) => {
    const countryCode = await storage.get(field) || "+965";
    return countryCode;
  }

  handleSellerInvalidToken = (messageText: string) =>{
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true, alertMsg: messageText, alertType: 'error' 
    }));
  }

  handleCountrySelect = (country: CountrySuccess, code: string) => {
    setStorageData("countryFlge",country.country_code)
    setStorageData("numericCode",country.numeric_code)
    this.setState({

      selectedCountry: country.country_code,
      numericCode:country.numeric_code,
      dropdownOpen: false,
      isCountryData: code
    });
  };
  handleCountrySelect1 = (country: CountrySuccess, code: string) => {
    setStorageData("countryFlgeTwo",country.country_code)
    setStorageData("numericCodeTwo",country.numeric_code)
    this.setState({
      selectedCountry1: country.country_code,
      numericCodeTwo:country.numeric_code,
      dropdownOpen1: false,
      isCountryData1: code
    });
  };


  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  toggleDropdown1 = () => {
    this.setState((prevState) => ({
      dropdownOpen1: !prevState.dropdownOpen1,
    }));
  };

  sellerScrollToSectionID = (sectionIdName:string) => {
    const section = document.getElementById(sectionIdName);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.countryCodeAPIEditCallId) {
        this.setState({
            countrySuccess: responseJson as CountrySuccess[],
        });
    }

    else if (apiRequestCallId === this.AccountActiveApiCallId) {
        if ('error' in responseJson) {
          this.setState({ isAlert: true, alertMsg: responseJson.error, alertType: 'error'});
        } else {
            this.setState((prevState) => ({
                ...prevState,
                isSuccess: true,
            }), () => {
                setTimeout(() => {
                    this.handleRedirect("SellerStore");
                }, 4000);
            });
            storage.remove('full_name')
            storage.remove('email')
            storage.remove('account_holder_name')
            storage.remove('iban')
            storage.remove('owner_full_address')
            storage.remove('owner_contact_number')
            storage.remove('isCountryData')
            storage.remove('company_full_address')
            storage.remove('isCountryData1')
            storage.remove('company_contact_number')
            storage.remove('account_number')
            storage.remove('countryFlge')
            storage.remove('numericCode')
            storage.remove('countryFlgeTwo')
            storage.remove('numericCodeTwo')

        }
    } else if(apiRequestCallId === this.sellerValidIBANAPICallId){
      if (!responseJson.owner_iban_valid) {
        this.setState({ errors: { iban: 'IBAN Number is not a valid.' } })
        this.sellerScrollToSectionID("IBANNumber");
      } else {
        this.getNavigationActiveAddress();
      }
    } else if (apiRequestCallId === this.sellerValidPhoneNumberAPICallId){
      await this.handleValidPhoneNoResp(responseJson);
    }
};

  handleValidPhoneNoResp = async (responseJson: {owner_contact_valid: boolean, company_contact_valid: boolean}) =>{
    const { owner_contact_valid, company_contact_valid } = responseJson;

    let owner_contact_number = "";
    let company_contact_number = "";
    let validateNumber = true;

    if (!owner_contact_valid) {
      owner_contact_number = `${this.state.selectedCountry === "" ? "+965" : this.state.numericCode} ${this.state.formData.owner_contact_number} is not a valid phone number`;
      validateNumber = false;
    }

    if (!company_contact_valid) {
      company_contact_number = `${this.state.selectedCountry1 === "" ? "+965" : this.state.numericCodeTwo} ${this.state.formData.company_contact_number} is not a valid phone number`;
      validateNumber = false;
    }

    if (!validateNumber) {
      this.setState({ errors: {owner_contact_number, company_contact_number} });
      owner_contact_number !== "" ? this.sellerScrollToSectionID("phoneNumber"): this.sellerScrollToSectionID("companyPhoneNumber");
    } else {
      await this.getNavigationDocument();
    }
  }
oncloseAlert = () => {
  this.setState({ isAlert: false });
};

  getCountryCodeEditApi = async () => {
    this.countryCodeAPIEditCallId = await this.apiCallEditForgotPass({
      contentType: configJSON.countryApiContentType,
      method: configJSON.httpMethodType,
      endPoint: configJSON.countryCodeEndPoint
    });
  };

  apiCallEditForgotPass = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const forgotRequestMessage1 = new Message(getName(MessageEnum.RestAPIRequestMessage));
    forgotRequestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    forgotRequestMessage1.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    body &&
      forgotRequestMessage1.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    forgotRequestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(forgotRequestMessage1.id, forgotRequestMessage1);
    return forgotRequestMessage1.messageId;
  };
  setValue = (value: string) => {
    return value ? value : ""
  }
  setValueMedia = (value: string[]) => {
    return value ? value : ""
  }
  getNavigationActiveAddress = () => {

    storage.set('full_name', this.setValue(this.state.formData.full_name))
    storage.set('email', this.setValue(this.state.formData.email))
    storage.set('account_holder_name', this.setValue(this.state.formData.account_holder_name))
    storage.set('iban', this.setValue(this.state.formData.iban))
    storage.set('account_number', this.setValue(this.state.formData.account_number))

    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ActiveAddress'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);

    this.AccountActivation1();
  }
  getNavigationProfile = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AccountActiveProfile'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  getNavigationDocument = async () => {

    storage.set('owner_full_address', this.setValue(this.state.formData.owner_full_address))
    storage.set('owner_contact_number', this.setValue(this.state.formData.owner_contact_number))
    storage.set('isCountryData', this.setValue(this.state.isCountryData === null ? "+965" : this.state.isCountryData))
    storage.set('company_full_address', this.setValue(this.state.formData.company_full_address))
    storage.set('company_zip_code', this.setValue(this.state.formData.company_zip_code))
    storage.set('company_contact_number', this.setValue(this.state.formData.company_contact_number))
    storage.set('owner_zip_code', this.setValue(this.state.formData.company_zip_code))
    storage.set('isCountryData1', this.setValue(this.setValue(this.state.isCountryData1 === null ? "+965" : this.state.isCountryData1)))
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'DocumentAcitve'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);


  }

  handleFileChange(event: React.ChangeEvent<HTMLInputElement>, docType: DocumentType) {
    const fileVariables = [
      { key: "authorized_signature", allowedTypes: ["png", "jpg", "jpeg"] },
      { key: "moa", allowedTypes: ["png", "jpg", "jpeg"] },
      { key: "commercial_license", allowedTypes: ["png", "jpg", "jpeg"] },
      { key: "passport", allowedTypes: ["png", "jpg", "jpeg", "pdf"] },
      { key: "business_bank_account", allowedTypes: ["png", "jpg", "jpeg"] },
    ];
    const file = event.target.files ? event.target.files[0] : null;
    const { selectedFiles, errors } = this.state;
    const fileTypeRule = fileVariables.find((variable) => variable.key === docType);
    if (fileTypeRule && file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (!fileTypeRule.allowedTypes.includes(fileExtension || "")) {
        this.setState({
          errors: {
            ...errors,
            [docType]: `Invalid file type. Please upload ${fileTypeRule.allowedTypes.join(", ")} files.`,
          },
        });
        return;
      }
      const maxSizeMB = 5;
      if (file.size > maxSizeMB * 1024 * 1024) {
        this.setState({
          errors: {
            ...errors,
            [docType]: `File size should not exceed ${maxSizeMB} MB.`,
          },
        });
        return;
      }
    }

    this.setState({
      selectedFiles: { ...selectedFiles, [docType]: file },
      errors: { ...errors, [docType]: "" },
    });
  }


  AccountActivation1 = async () => {
    const token = await getStorageData("auth-token");
    const header = {
        token: token,
    };

    const fileVariables = [
      { key: "authorized_signature", value: this.state.selectedFiles.authorized_signature },
      { key: "moa", value: this.state.selectedFiles.moa },
      { key: "commercial_license", value: this.state.selectedFiles.license },
      { key: "passport", value: this.state.selectedFiles.passport },
      { key: "business_bank_account", value: this.state.selectedFiles.business_bank_account },

    ];

    const formData = new FormData();
    formData.append('data[attributes][full_name]', await getStorageData('full_name'));
    formData.append("data[attributes][email]", await getStorageData('email'));
    
    formData.append("data[attributes][account_holder_name]", await getStorageData('account_holder_name'));
    formData.append("data[attributes][iban]", await getStorageData('iban'));
    formData.append("data[attributes][owner_full_address]", await getStorageData('owner_full_address'));
    formData.append("data[attributes][owner_contact_number]", await getStorageData('isCountryData') + await getStorageData('owner_contact_number'));
    formData.append("data[attributes][company_full_address]", await getStorageData('company_full_address'));
    formData.append("data[attributes][company_contact_number]", await getStorageData('isCountryData1') + await getStorageData('company_contact_number'));
    formData.append("data[attributes][account_number]", await getStorageData('account_number'));
    
      formData.append(`data[attributes][social_media_account][]`, `[]` )
    fileVariables.forEach(fileVar => {
      if (fileVar.value && fileVar.value instanceof File) {
        formData.append(`data[attributes][${fileVar.key}]`, fileVar.value);
      }
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AccountActiveApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/accounts/seller_owner_account`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

   
  }
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { formData, errors } = this.state;
    const { name, value } = e.target;
    const updatedErrors = { ...errors };
    if (updatedErrors[name]) {
      delete updatedErrors[name];
    }
    this.setState({
      formData: {
        ...formData,
        [name]: value,
      },
      errors: updatedErrors
    });
  };
  

handleRedirect = (redirect:string) =>{
  const userNavMsg: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
  userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(userNavMsg);
}

handleSubmit = () => {
  const isValid = this.validateForm();
  if (isValid) {
    this.sellerValidateIBAN();
  }
};

sellerValidateIBAN = async () => {
  const {formData} =this.state;
    const formDataCode = new FormData();
    formDataCode.append("data[attributes][iban]", formData.iban.toString());
    this.sellerValidIBANAPICallId = await apiCall({
      token: await getStorageData("auth-token"),
      body: formDataCode,
      endPoint: configJSON.validIBANEndPoint,
      isJsonStringify: false,
      method: configJSON.httpPostMethod,
    });
}

sellerValidatePhoneNumber = async () => {
    const {formData, selectedCountry, numericCode, numericCodeTwo, selectedCountry1} =this.state;
    const formDataCode = new FormData();
    formDataCode.append("data[attributes][owner_contact_number]", `${selectedCountry === "" ? "+965": numericCode}${formData.owner_contact_number}`)
    formDataCode.append("data[attributes][company_contact_number]", `${selectedCountry1 === "" ? "+965": numericCodeTwo}${formData.company_contact_number}`);
    this.sellerValidPhoneNumberAPICallId = await apiCall({
      token: await getStorageData("auth-token"),
      body: formDataCode,
      endPoint: configJSON.validPhoneNumEndPoint,
      isJsonStringify: false,
      method: configJSON.httpPostMethod,
    });
}

validateForm = () => {
  const { formData } = this.state;
  const errors: { [key: string]: string } = {};
  const requiredFields = [
    { name: "full_name", message: "*Please enter a valid full name" },
    { name: "email", message: "*email is required" },
    { name: "iban", message: "*Please enter a valid Iban" },
    { name: "account_holder_name", message: "*Please enter a valid Account holder name" },
    { name: "account_number", message: "*Please enter a valid Account Number" },
 
  ];

  requiredFields.forEach(field => {
    const value = formData[field.name as keyof typeof formData];
    if (typeof value === "string" && value.trim() === "") {
      errors[field.name] = field.message;
    }
  });
  const accountNumberValue = formData.account_number;
  if (typeof accountNumberValue === "string" && !/^\d+$/.test(accountNumberValue)) {
    errors.account_number = "*Account Number must be numeric";
  }
  const email = formData.email;
  if(typeof email === "string" && !configJSON.emailRegex.test(email))
  {
    errors.email = "*Please enter a valid email";
  }
  const fullNameValue = formData.full_name;
  if (typeof fullNameValue === "string") {
    if (fullNameValue.trim() === "") {
      errors.full_name = "*Please enter a valid full name";
    } else if (!/^[a-zA-Z\s]+$/.test(fullNameValue)) {
      errors.full_name = "*Only characters are allowed";
    }
  }

  const accountHolderNameValue = formData.account_holder_name;
  if (typeof accountHolderNameValue === "string") {
    if (accountHolderNameValue.trim() === "") {
      errors.account_holder_name = "*Please enter a valid Account holder name";
    } else if (!/^[a-zA-Z\s]+$/.test(accountHolderNameValue)) {
      errors.account_holder_name = "*Only characters are allowed";
    }
  }
  this.setState({ errors });
  
  return Object.keys(errors).length === 0;
};

handleSubmit1 = () => {

  const isValid = this.validateForm1();
  if (isValid) {
    this.sellerValidatePhoneNumber();
  }
};
validateForm1 = () => {
  const { formData } = this.state;
  const errors: { [key: string]: string } = {};

  const requiredFields = [
    { name: "owner_full_address", message: "*Please enter a valid Owner Address" },
    { name: "owner_contact_number", message: "*Please enter a valid Contact Number" },
    { name: "company_full_address", message: "*Please enter a valid Company Address" },
    { name: "company_contact_number", message: "*Please enter a valid Contact number" },
  
 
  ];

  requiredFields.forEach(field => {
    const value = formData[field.name as keyof typeof formData];
    if (typeof value === "string" && value.trim() === "") {
      errors[field.name] = field.message;
    }
  });
  const zipcodeValue = formData.owner_zip_code;
  if (zipcodeValue && typeof zipcodeValue === "string" && !/^\d+$/.test(zipcodeValue)) {
    errors.owner_zip_code = "*Zipcode must be numeric";
  }

  const zipcodecompanyValue = formData.company_zip_code;
  if (zipcodecompanyValue && typeof zipcodecompanyValue === "string" && !/^\d+$/.test(zipcodecompanyValue)) {
    errors.company_zip_code = "*Zipcode must be numeric";
  }
  const ownerContactNumberValue = formData.owner_contact_number;
  if (typeof ownerContactNumberValue === "string" && !/^\d+$/.test(ownerContactNumberValue)) {
    errors.owner_contact_number = "*Owner Contact Number must be numeric";
  }

  const companyContactNumberValue = formData.company_contact_number;
  if (typeof companyContactNumberValue === "string" && !/^\d+$/.test(companyContactNumberValue)) {
    errors.company_contact_number = "*Company Contact Number must be numeric";
  }

  this.setState({ errors });
  return Object.keys(errors).length === 0;
};

validatedocumentForm() {
  const { selectedFiles } = this.state;
  const errors = {
    passport: selectedFiles.passport ? "" : "*please upload passport",
    license: selectedFiles.license ? "" : "*please upload license",
    authorized_signature: selectedFiles.authorized_signature ? "" : "*please upload authorized signature",
    moa: selectedFiles.moa ? "" : "*please upload moa",
    business_bank_account: selectedFiles.business_bank_account ? "" : "*please upload business bank account"
  };
  
  this.setState({ errors });
  
  return Object.values(errors).every(error => error === "");
}
handledocumentSubmit() {

  if (this.validatedocumentForm()) {
    this.AccountActivation1();
  }
}
  // Customizable Area End
}