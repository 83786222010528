export const enCart = {
    "Id": "Id",
    "Total Fees": "Total Fees",
    "Total Items": "Total Items",
    "Total Tax": "Total Tax",
    "Customer": "Customer",
    "Action": "Action",
    "View": "View",
    "Add": "Add",
    "Price": "Price",
    "Quantity": "Quantity",
    "Taxable Value": "Taxable Value",
    "Taxable": "Taxable",
    "Catalogue": "Catalogue",
    "Delete": "Delete",
    "Close": "Close",
    "All fields are mandatory.": "All fields are mandatory.",
    "View all orders": "View all orders",
    "Quantity: ": "Quantity: ",
    "Taxable: ": "Taxable: ",
    "Taxable Value: ": "Taxable Value: ",
    "Add Order Item": "Add Order Item",

    "Your Cart": "Your Cart",
    "Save Address": "Save Address",
    "Address": "Address",
    "Payment": "Payment",
    "Payments": "Payments",
    "PRODUCT DETAILS ": "PRODUCT DETAILS ",
    "PRICE": "PRICE",
    "QUANTITY": "QUANTITY",
    "SHIPPING COST": "SHIPPING COST",
    "SUBTOTAL": "SUBTOTAL",
    "ACTION": "ACTION",
    "Color: ": "Color: ",
    "Size: ": "Size: ",
    "Item Total ": "Item Total ",
    "Shipping fee": "Shipping fee",
    "Voucher": "Voucher",
    "Total Cost": "Total Cost",
    "Proceed to Checkout": "Proceed to Checkout",
    "Continue Shopping": "Continue Shopping",
    "Your cart is empty": "Your cart is empty",
    "Add something to make it happy!": "Add something to make it happy!",
    "Are you sure you want to delete this item?": "Are you sure you want to delete this item?",
    "This action cannot be undone.": "This action cannot be undone.",
    "No": "No",
    "Yes": "Yes",
    "Same as Billing address": "Same as Billing address",
    "Use a different shipping address": "Use a different shipping address",
    "Standard Delivery": "Standard Delivery",
    "Express Delivery": "Express Delivery",
    "Shipping Information": "Shipping Information",
    "Name on card": "Name on card",
    "Card number": "Card number",
    "Expire Date": "Expire Date",
    "CVV": "CVV",
    "Save this card for future payment": "Save this card for future payment",
    "Add Card": "Add Card",
    "Credit Card": "Credit Card",
    "Add credit card": "Add credit card",
    "We accept all major Debit Cards.": "We accept all major Debit Cards.",
    "Add debit card": "Add debit card",
    "Google Pay": "Google Pay",
    "Apple Pay": "Apple Pay",
    "Edit": "Edit",
    "Shipping Method": "Shipping Method",
    "Estimated Delivery time": "Estimated Delivery time",
    "Delivery Charges": "Delivery Charges",
    "30 min": "30 min",
    "Order Summary": "Order Summary",
    "Place Order": "Place Order",
    "Enter your coupon code if you have one": "Enter your coupon code if you have one",
    "Search": "Search",
    "Apply Coupon": "Apply Coupon",
    "Remove Coupon": "Remove Coupon",
    "Your order has been placed successfully.": "Your order has been placed successfully.",
    "80 Loyalty points on the way.": "80 Loyalty points on the way.",
    "All Orders": "All Orders",
    "Promo Codes": "Promo Codes",
    "Great": "Great",
    "items": "items",
    "Color :": "Color :",
    "Color": "Color",
    "Subtotal": "Subtotal",
    "Savings": "Savings",
    "Total": "Total"
}

export const arCart = {
    "Id": "بطاقة تعريف",
    "Total Fees": "إجمالي الرسوم",
    "Total Items": "إجمالي العناصر",
    "Total Tax": "إجمالي الضريبة",
    "View": "منظر",
    "Add": "يضيف",
    "Price": "السعر",
    "Quantity": "الكمية",
    "Taxable Value": "القيمة الخاضعة للضريبة",
    "Taxable": "خاضع للضريبة",
    "Catalogue": "كتالوج",
    "Delete": "يمسح",
    "Close": "يغلق",
    "All fields are mandatory.": "جميع الحقول إلزامية.",
    "View all orders": "عرض جميع الطلبات",
    "Quantity: ": "الكمية: ",
    "Taxable: ": "الخاضع للضريبة: ",
    "Taxable Value: ": "القيمة الخاضعة للضريبة: ",
    "Add Order Item": "إضافة عنصر الطلب",
    "Your Cart": "سلة تسوقك",
    "Save Address": "حفظ العنوان",
    "Address": "عنوان",
    "Payment": "قسط",
    "Payments": "المدفوعات",
    "PRODUCT DETAILS ": "تفاصيل المنتج ",
    "PRICE": "السعر",
    "QUANTITY": "كمية",
    "SHIPPING COST": "تكاليف الشحن",
    "SUBTOTAL": "الإجمالي الفرعي",
    "ACTION": "الإجراءات",
    "Color: ": "لون: ",
    "Size: ": "مقاس: ",
    "Item Total ": "الإجمالي الفرعي",
    "Shipping fee": "رسوم الشحن",
    "Voucher": "القسيمة",
    "Total Cost": "التكلفة الإجمالية",
    "Proceed to Checkout": "التقدم إلى الدفع",
    "Continue Shopping": "متابعة التسوق",
    "Your cart is empty": "سلة التسوق الخاصة بك فارغة",
    "Add something to make it happy!": "أضف شيئا لتملأها بالسعادة",
    "Are you sure you want to delete this item?": "هل أنت متأكد أنك تريد حذف هذا العنصر؟",
    "This action cannot be undone.": "لا يمكن التراجع عن هذا الإجراء.",
    "No": "لا",
    "Yes": "نعم",
    "Same as Billing address": "نفس عنوان إرسال الفواتير",
    "Use a different shipping address": "استخدم عنوان شحن مختلفًا",
    "Standard Delivery": "التسليم القياسي",
    "Express Delivery": "التسليم السريع",
    "Shipping Information": "معلومات الشحن",
    "Name on card": "الاسم على البطاقة",
    "Card number": "رقم البطاقة",
    "Expire Date": "تاريخ انتهاء الصلاحية",
    "CVV": "CVV",
    "Save this card for future payment": "احفظ هذه البطاقة للدفع المستقبلي",
    "Add Card": "أضف بطاقة",
    "Credit Card": "بطاقة إئتمان",
    "Add credit card": "إضافة بطاقة الائتمان",
    "We accept all major Debit Cards.": "نحن نقبل جميع بطاقات الخصم الرئيسية.",
    "Add debit card": "إضافة بطاقة الخصم",
    "Google Pay": "جوجل الدفع",
    "Apple Pay": "أبل الدفع",
    "Edit": "يحرر",
    "Shipping Method": "طريقة الشحن",
    "Estimated Delivery time": "وقت التسليم المقدر",
    "Delivery Charges": "رسوم التوصيل",
    "30 min": "30 دقيقة",
    "Order Summary": "ملخص الطلب",
    "Place Order": "تقديم الطلب",
    "Enter your coupon code if you have one": "أدخل رمز القسيمة الخاص بك إذا كان لديك واحد",
    "Search": "بحث",
    "Apply Coupon": "تطبيق القسيمة",
    "Remove Coupon": "إزالة القسيمة",
    "Your order has been placed successfully.": "لقد تم تقديم طلبك بنجاح.",
    "80 Loyalty points on the way.": "80 نقطة ولاء في الطريق.",
    "All Orders": "جميع الطلبات",
    "Promo Codes": "الرموز التروجية",
    "Great": "عظيم",
    "items": "أغراض",
    "Color :": "لون :",
    "Color": "لون",
    "Subtotal": "تكاليف الشحن",
    "Savings": "ما تم توفيره",
    "Total": "الإجمالي"
}
