export const enStylishClient = {
    "Clients": "Clients",
    "My Clients": "My Clients",
    "Styling Requests": "Styling Requests",
    "Call Back Requests": "Call Back Requests",
    "Create Client Specific Wishlist": "Create Client Specific Wishlist",
    "Latest Requests": "Latest Requests",
    "Request ID": "Request ID",
    "Request Time": "Request Time",
    "Name & Gender": "Name & Gender",
    "Budget": "Budget",
    "Status": "Status",
    "Action": "Action",
    "View Request": "View Request",
    "No Styling Requests Available": "No Styling Requests Available",
    "Chat with Customer": "Chat with Customer",
    "Gender": "Gender",
    "Colours": "Colours",

}

export const arStylishClient = {
    "Clients": "العملاء",
    "My Clients": "عملائي",
    "Styling Requests": "طلبات التصمیم",
    "Call Back Requests": "طلبات معاودة الاتصال",
    "Create Client Specific Wishlist": "إنشاء قائمة رغبات للعمیل",
    "Latest Requests": "أحدث الطلبات",
    "Request ID": "رقم الطلب",
    "Request Time": "وقت الطلب",
    "Name & Gender": "الإسم والجنس",
    "Budget": "المیزانیة",
    "Status": "الحالة",
    "Action": "الإجراء",
    "View Request": "عرض الطلب",
    "No Styling Requests Available": "لا تتوفر طلبات التصميم",
    "Chat with Customer": "قائمة رغبات رالف",
    "Gender": "الجنس",
    "Colours": "الألوان",
}