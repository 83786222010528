import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAdminUser: boolean;
  accountId: number;
  termsConds: ITermsConds | null;
  termsCondsList: ITermsConds[];
  isTermsCondsAccepted: boolean;
  isLoading: boolean;
  tabData:string[]
  activeTab:number;
  offerBack:string;
  termsConditionPolicy:string;
  policyData:{
    0:string;
    1:string;
    2:string;
    3:string;
  },
  userToken: string;
  userRole: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  getTermsCondsListCallId: string = "";
  getAccountGroupsCallId: string = "";
  getTermsAndConditionCallId: string = "";
  setAcceptanceOfTermsCondsId: string = "";
  getShipingCallId: string = "";
  getReturnCallId: string = "";
  getPrivacyCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      offerBack:"",
      termsCondsList: [],
      isAdminUser: true,
      termsConds: null,
      isTermsCondsAccepted: false,
      isLoading: false,
      tabData:[
        "Terms & Conditions","Privacy Policy","Shipping Policy","Return Policy"
      ],
      activeTab:0,
      termsConditionPolicy:"",
      policyData:{
        0:"",
        1:"",
        2:"",
        3:"",
      }, userToken: "",
      userRole: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTermsandCondtions()
    this.getReturnPolicy()
    this.getPrivacyPolicy()
    this.getShippingPolicy();
    const userToken = await getStorageData('auth-token');
    const userRole = await getStorageData('userRole', true);
    const policyTab = await getStorageData('policyTab', true);
    const termsConditionsoffer = await getStorageData('termsConditionsoffer')
    if(policyTab){
     this.setState({
      activeTab: policyTab
     })
    }else{
      this.setState({
        activeTab: 0
       })
    }
    this.setState({
      userToken: userToken,
      userRole: userRole.userType,
      offerBack:termsConditionsoffer
    });
  }

  handleLandingPageFromPolicy = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),configJSON.LandingPageMsg);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  };

  handleChangeTab = (index:number) => {
     this.setState({activeTab:index})
  }

  navigateToTermsCondsDetail = (termsCondsId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.TermsConditionsDetailMsg 
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      termsCondsId: termsCondsId,
    });

    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToTermsCondsEdit = () => {
    const messageID = new Message(getName(MessageEnum.NavigationMessage));
    messageID.addData(getName(MessageEnum.NavigationTargetMessage),configJSON.TermsConditionsEditMsg);
    messageID.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), null);
    messageID.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(messageID);
  };

  getAccountGroups = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountGroupsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };

  getTermsConds = async (token: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckBoxChange = (value: boolean) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const body = {
      id: this.state.termsConds?.id,
      is_accepted: value,
    };
    this.setAcceptanceOfTermsCondsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setTermsCondsAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTermsandCondtions = async () => {  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    this.getTermsAndConditionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditionEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };

  getReturnPolicy = async () => {
    let token = localStorage.getItem('auth-token');
    const header = {
      token: token,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReturnCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReturnPolicyEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };

  getPrivacyPolicy = async () => {
    let token = localStorage.getItem('auth-token');
    const header = {
      token: token,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicyEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };

  getShippingPolicy = async () => {
    let token = localStorage.getItem('auth-token');
    const header = {
      token: token,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getShipingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShippingPolicyEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };
  handleOfferPage = async () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),"SellerOffersStoreFormWebPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
    removeStorageData("termsConditionsoffer")
  };
  handleBuyerHeader = () => {
    if(this.state.userRole === "buyer" || this.state.userRole === "" || this.state.userRole === "0"  || this.state.userRole === "1"  || this.state.userRole === "3"){
      return true;
    }else{
      return false;
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const navigationParams = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    if(typeof navigationParams?.tabIndex === configJSON.numberMsg){
      this.setState({activeTab:navigationParams.tabIndex})
    }
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      if (token) {
        runEngine.debugLog("TOKEN", token);
        const messageData = JSON.parse(
          message.getData(getName(MessageEnum.SessionResponseData))
        );
        const accountId: number = messageData?.meta?.id;
        this.setState({ accountId });

        this.setState({ token, accountId }, () => this.getAccountGroups(token));
      } else {
        this.props.navigation.goBack();
        return alert(configJSON.PleaseLoginMsg);
      }
    }

    if (apiRequestCallId === this.getTermsAndConditionCallId && responseJson?.description) {
      this.setState({ termsConditionPolicy: responseJson.description, policyData: { ...this.state.policyData, 0: responseJson.description } })
    }
    if (this.getReturnCallId === apiRequestCallId && responseJson?.id) {
      this.setState({ policyData: { ...this.state.policyData, 3: responseJson.description } })
    }
    if (this.getShipingCallId === apiRequestCallId && responseJson?.id) {
      this.setState({ policyData: { ...this.state.policyData, 2: responseJson.description } })
    }
    if (this.getPrivacyCallId === apiRequestCallId && responseJson?.id) {
      this.setState({ policyData: { ...this.state.policyData, 1: responseJson.description } })
    }
    // Customizable Area End
  }
}
